import {
  ApiFeedSortMode,
  ApiGetFeedItems200Response,
  FarcasterApiClient,
  TypedGetNextPageParamFunction,
  TypedGetPreviousPageParamFunction,
} from 'farcaster-client-data';

import { InternalEventingContextValue } from '../../../../providers';
import { BatchMergeIntoGloballyCachedCasts } from '../../../../types';

export interface FeedItemsPageParam {
  olderThan: number | undefined;
  latestMainCastTimestamp: number | undefined;
  excludeItemIdPrefixes: string[] | undefined;
}

export const buildFeedItemsFetcher =
  ({
    apiClient,
    feedKey,
    feedType,
    updateState,
    batchUpdateGloballyCachedCast,
    internalEventsTracker,
    includeUserSuggestions,
    onNullFeedItemsResponse,
    sortMode,
  }: {
    apiClient: FarcasterApiClient;
    feedKey: string;
    feedType: string;
    updateState: boolean;
    batchUpdateGloballyCachedCast: BatchMergeIntoGloballyCachedCasts;
    internalEventsTracker: InternalEventingContextValue;
    onNullFeedItemsResponse: () => void;
    includeUserSuggestions?: boolean;
    sortMode?: ApiFeedSortMode;
  }) =>
  async ({ pageParam }: { pageParam?: FeedItemsPageParam }) => {
    const castViewEvents = internalEventsTracker.getAndRemoveCastViewEvents();

    try {
      const response = await apiClient.getFeedItems({
        feedKey,
        feedType,
        olderThan: pageParam?.olderThan,
        latestMainCastTimestamp: pageParam?.latestMainCastTimestamp,
        excludeItemIdPrefixes: pageParam?.excludeItemIdPrefixes,
        castViewEvents,
        updateState,
        includeUserSuggestions,
        sortMode,
      });

      if (response.data === null) {
        onNullFeedItemsResponse();
        // eslint-disable-next-line no-console
        console.warn('data was null: buildFeedItemsFetcher');
      }

      batchUpdateGloballyCachedCast({
        batchUpdates: response.data.result.items.flatMap(({ cast }) => cast),
      });

      return response.data;
    } catch (e) {
      internalEventsTracker.addBackCastViewEvents(castViewEvents);
      throw e;
    }
  };

export const feedItemsDetermineNextPageParams: TypedGetNextPageParamFunction<
  ApiGetFeedItems200Response,
  FeedItemsPageParam
> = (lastPage, allPages) => {
  if (lastPage === null) {
    // eslint-disable-next-line no-console
    console.warn('lastPage was null: feedItemsDetermineNextPageParams');
  }

  if (lastPage.result.items.length === 0) {
    return undefined;
  }

  const firstPage = allPages[0];

  const latestMainCastTimestamp = firstPage?.result.latestMainCastTimestamp;
  const allItemIdPrefixes = allPages.flatMap((page) => {
    if (page === null) {
      // eslint-disable-next-line no-console
      console.warn('page was null: feedItemsDetermineNextPageParams');
    }

    return page.result.items.map((item) => item.id.slice(2, 10).toLowerCase());
  });

  return {
    olderThan: latestMainCastTimestamp,
    latestMainCastTimestamp,
    excludeItemIdPrefixes: allItemIdPrefixes,
  } satisfies FeedItemsPageParam;
};

export const feedItemsDeterminePrevPageParams: TypedGetPreviousPageParamFunction<
  ApiGetFeedItems200Response,
  FeedItemsPageParam
> = (firstPage, allPages) => {
  const latestMainCastTimestamp = firstPage?.result.latestMainCastTimestamp;

  const allItemIdPrefixes = allPages.flatMap((page) => {
    if (page === null) {
      // eslint-disable-next-line no-console
      console.warn('page was null: feedItemsDeterminePrevPageParams');
    }

    return page.result.items.map((item) => item.id.slice(2, 10).toLowerCase());
  });

  return {
    olderThan: undefined,
    latestMainCastTimestamp,
    excludeItemIdPrefixes: allItemIdPrefixes,
  } satisfies FeedItemsPageParam;
};
