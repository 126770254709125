import classNames from 'classnames';
import { FC, memo, useMemo } from 'react';

import { LinkToHomeFeed } from '~/components/links/LinkToHomeFeed';
import { LinkToSignup } from '~/components/links/LinkToSignUp';
import { Logo } from '~/components/Logo';
import { useStandaloneMode } from '~/contexts/StandaloneModeProvider';
import { useIsRelatedToCurrentRoute } from '~/hooks/navigation/useIsRelatedToRoute';

const MobileHeader: FC = memo(() => {
  const { inStandaloneMode } = useStandaloneMode();

  const relatedToSettingsConnectedAccounts = useIsRelatedToCurrentRoute({
    path: '/~/settings/connected-accounts',
    family: 'settings',
    params: {},
  });

  const mobileHeaderIsHidden = useMemo(
    () => inStandaloneMode || relatedToSettingsConnectedAccounts,
    [inStandaloneMode, relatedToSettingsConnectedAccounts],
  );

  return (
    <nav
      className={classNames(
        'sticky top-0 z-50 items-center justify-between border-b px-4 py-2 bg-app border-default',
        mobileHeaderIsHidden ? 'hidden' : 'flex flex-row sm:hidden',
      )}
    >
      <LinkToHomeFeed title="Home feed">
        <Logo />
      </LinkToHomeFeed>
      <LinkToSignup title="Sign up" className="text-xs text-link">
        Join the conversation
      </LinkToSignup>
    </nav>
  );
});

MobileHeader.displayName = 'MobileHeader';

export { MobileHeader };
