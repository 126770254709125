import {
  ApiAbandonVideoUpload200Response,
  ApiAbandonVideoUploadRequestBody,
  ApiAddCastAction200Response,
  ApiAddCastActionRequestBody,
  ApiAddCastToTrendingFeed200Response,
  ApiAddCastToTrendingFeedRequestBody,
  ApiAddComposerAction200Response,
  ApiAddComposerActionRequestBody,
  ApiAddDirectCastKeysByAccount200Response,
  ApiAddDirectCastKeysByAccountRequestBody,
  ApiAddDirectCastUserKey200Response,
  ApiAddDirectCastUserKeyRequestBody,
  ApiAddDiscoveryApp200Response,
  ApiAddDiscoveryAppRequestBody,
  ApiAddDiscoveryFrame200Response,
  ApiAddDiscoveryFrameRequestBody,
  ApiAddFavoriteFeed200Response,
  ApiAddFavoriteFeedRequestBody,
  ApiAddMuteKeyword200Response,
  ApiAddMuteKeywordRequestBody,
  ApiAddUserUsername200Response,
  ApiAddUserUsernameRequestBody,
  ApiAdminAction200Response,
  ApiAdminActionRequestBody,
  ApiAdminAddChannelModerator200Response,
  ApiAdminAddChannelModeratorRequestBody,
  ApiAdminChangeChannelOwner200Response,
  ApiAdminChangeChannelOwnerRequestBody,
  ApiAdminUpdateChannelsAllThreads200Response,
  ApiAdminUpdateChannelsAllThreadsRequestBody,
  ApiAdminUpdateChannelThreads200Response,
  ApiAdminUpdateChannelThreadsRequestBody,
  ApiAllocateInvites200Response,
  ApiAllocateInvitesRequestBody,
  ApiAllowSponsoredRegistration200Response,
  ApiAllowSponsoredRegistrationRequestBody,
  ApiApproveRecoveryTransaction200Response,
  ApiApproveRecoveryTransactionRequestBody,
  ApiApproveSignedKeyRequest200Response,
  ApiApproveSignedKeyRequestRequestBody,
  ApiBanUserFromChannel200Response,
  ApiBanUserFromChannelRequestBody,
  ApiBookmarkCast200Response,
  ApiBookmarkCastRequestBody,
  ApiBoostCast200Response,
  ApiBoostCastRequestBody,
  ApiBuyWarpsCoinbaseCommerce200Response,
  ApiBuyWarpsCoinbaseCommerceInfo200Response,
  ApiBuyWarpsCoinbaseCommerceRequestBody,
  ApiCanGiftWarps200Response,
  ApiCanGiftWarpsQueryParams,
  ApiClaimInvite200Response,
  ApiClaimInviteRequestBody,
  ApiClearJobQueue200Response,
  ApiClearJobQueueRequestBody,
  ApiCompleteMagicLink200Response,
  ApiCompleteMagicLinkRequestBody,
  ApiCompletePeerToPeerPayment200Response,
  ApiCompletePeerToPeerPaymentRequestBody,
  ApiCompletePhoneVerification200Response,
  ApiCompletePhoneVerificationRequestBody,
  ApiCompleteRegistration200Response,
  ApiCompleteRegistrationRequestBody,
  ApiCompleteSignedUpAttestations200Response,
  ApiConfirmAccountDeleteQueryParams,
  ApiCreateApiKey200Response,
  ApiCreateApiKeyRequestBody,
  ApiCreateAuthToken200Response,
  ApiCreateAuthTokenRequestBody,
  ApiCreateCast201Response,
  ApiCreateCastLike200Response,
  ApiCreateCastLikeRequestBody,
  ApiCreateCastRequestBody,
  ApiCreateChannel200Response,
  ApiCreateChannelRequestBody,
  ApiCreateFeedFollow200Response,
  ApiCreateFeedFollowRequestBody,
  ApiCreateFollow200Response,
  ApiCreateFollowRequestBody,
  ApiCreateInvite200Response,
  ApiCreateInviteRequestBody,
  ApiCreateNftReaction200Response,
  ApiCreateNftReactionRequestBody,
  ApiCreateOnboarding200Response,
  ApiCreateOnboardingRequestBody,
  ApiCreatePoll200Response,
  ApiCreatePollRequestBody,
  ApiCreateRecast200Response,
  ApiCreateRecastRequestBody,
  ApiCreateRecovery200Response,
  ApiCreateRecoveryRequestBody,
  ApiCreateSignedKeyRequest200Response,
  ApiCreateSignedKeyRequestRequestBody,
  ApiCreateWarpcastSignedKeyRequest200Response,
  ApiDeleteApiKey200Response,
  ApiDeleteApiKeyRequestBody,
  ApiDeleteAuthToken200Response,
  ApiDeleteCast200Response,
  ApiDeleteCastLike200Response,
  ApiDeleteCastLikeRequestBody,
  ApiDeleteCastRequestBody,
  ApiDeleteComposerAction200Response,
  ApiDeleteComposerActionRequestBody,
  ApiDeleteContacts200Response,
  ApiDeleteDirectCastConversationReactionsV3RequestBody,
  ApiDeleteDirectCastConversationReactionsV3200Response,
  ApiDeleteDirectCastKeysByInbox200Response,
  ApiDeleteDirectCastKeysByInboxQueryParams,
  ApiDeleteDirectCastMessage200Response,
  ApiDeleteDirectCastMessageRequestBody,
  ApiDeleteDiscoveryApp200Response,
  ApiDeleteDiscoveryAppRequestBody,
  ApiDeleteDiscoveryFrame200Response,
  ApiDeleteDiscoveryFrameRequestBody,
  ApiDeleteFeedFollow200Response,
  ApiDeleteFeedFollowRequestBody,
  ApiDeleteFollow200Response,
  ApiDeleteFollowRequestBody,
  ApiDeleteNftReaction200Response,
  ApiDeleteNftReactionRequestBody,
  ApiDeleteRecast200Response,
  ApiDeleteRecastRequestBody,
  ApiDeleteSyncChannel200Response,
  ApiDeleteSyncChannelQueryParams,
  ApiDeleteUserCastAction200Response,
  ApiDeleteUserCastActionRequestBody,
  ApiDeleteVerification200Response,
  ApiDeleteVerificationRequestBody,
  ApiDenyRecoveryTransaction200Response,
  ApiDenyRecoveryTransactionRequestBody,
  ApiDisableChannelNotifications200Response,
  ApiDisableChannelNotificationsRequestBody,
  ApiDisableLinkNotifications200Response,
  ApiDisableLinkNotificationsRequestBody,
  ApiDiscardDraftCast200Response,
  ApiDiscardDraftCastRequestBody,
  ApiDiscoverActions200Response,
  ApiDiscoverActionsQueryParams,
  ApiDiscoverApps200Response,
  ApiDiscoverAppsQueryParams,
  ApiDiscoverChannels200Response,
  ApiDiscoverChannelsQueryParams,
  ApiDiscoverComposerActions200Response,
  ApiDiscoverComposerActionsQueryParams,
  ApiDiscoverFrames200Response,
  ApiDiscoverFramesQueryParams,
  ApiDismissNewUserFollowInstructions200Response,
  ApiDismissNewUserFollowsBanner200Response,
  ApiDismissSuggestedUsers200Response,
  ApiDismissSuggestedUsersRequestBody,
  ApiDownvoteCast200Response,
  ApiDownvoteCastRequestBody,
  ApiEnableCastAction200Response,
  ApiEnableCastActionRequestBody,
  ApiEnableChannelNotifications200Response,
  ApiEnableChannelNotificationsRequestBody,
  ApiEnableLinkNotifications200Response,
  ApiEnableLinkNotificationsRequestBody,
  ApiFcBanUserFromChannel200Response,
  ApiFcBanUserFromChannelRequestBody,
  ApiFcBlockUser200Response,
  ApiFcBlockUserRequestBody,
  ApiFcDeleteGroupMembers200Response,
  ApiFcDeleteGroupMembersRequestBody,
  ApiFcDeleteMessage200Response,
  ApiFcDeleteMessageRequestBody,
  ApiFcFollowChannel200Response,
  ApiFcFollowChannelRequestBody,
  ApiFcGetAccountVerifications200Response,
  ApiFcGetAccountVerificationsQueryParams,
  ApiFcGetBlockedUsers200Response,
  ApiFcGetBlockedUsersQueryParams,
  ApiFcGetChannelBannedUsers200Response,
  ApiFcGetChannelBannedUsersQueryParams,
  ApiFcGetChannelInvites200Response,
  ApiFcGetChannelInvitesQueryParams,
  ApiFcGetChannelMembers200Response,
  ApiFcGetChannelMembersQueryParams,
  ApiFcGetChannelRestrictedUsers200Response,
  ApiFcGetChannelRestrictedUsersObsolete200Response,
  ApiFcGetChannelRestrictedUsersObsoleteQueryParams,
  ApiFcGetChannelRestrictedUsersQueryParams,
  ApiFcGetConversation200Response,
  ApiFcGetConversationList200Response,
  ApiFcGetConversationListQueryParams,
  ApiFcGetConversationQueryParams,
  ApiFcGetGroup200Response,
  ApiFcGetGroupInvites200Response,
  ApiFcGetGroupInvitesQueryParams,
  ApiFcGetGroupList200Response,
  ApiFcGetGroupListQueryParams,
  ApiFcGetGroupMembers200Response,
  ApiFcGetGroupMembersQueryParams,
  ApiFcGetGroupQueryParams,
  ApiFcGetMessage200Response,
  ApiFcGetMessageList200Response,
  ApiFcGetMessageListQueryParams,
  ApiFcGetMessageQueryParams,
  ApiFcGetModeratedCasts200Response,
  ApiFcGetModeratedCastsQueryParams,
  ApiFcInviteUserToChannelRole200Response,
  ApiFcInviteUserToChannelRoleRequestBody,
  ApiFcModerateCast200Response,
  ApiFcModerateCastRequestBody,
  ApiFcPinCast200Response,
  ApiFcPinCastRequestBody,
  ApiFcPostConversation200Response,
  ApiFcPostConversationRequestBody,
  ApiFcPostGroup200Response,
  ApiFcPostGroupMembers200Response,
  ApiFcPostGroupMembersRequestBody,
  ApiFcPostGroupRequestBody,
  ApiFcPutConversation200Response,
  ApiFcPutConversationRequestBody,
  ApiFcPutGroup200Response,
  ApiFcPutGroupInvites200Response,
  ApiFcPutGroupInvitesRequestBody,
  ApiFcPutGroupRequestBody,
  ApiFcPutMessage200Response,
  ApiFcPutMessageRequestBody,
  ApiFcRemoveUserFromChannelRole200Response,
  ApiFcRemoveUserFromChannelRoleRequestBody,
  ApiFcRespondToChannelRoleInvite200Response,
  ApiFcRespondToChannelRoleInviteRequestBody,
  ApiFcUnbanUserFromChannel200Response,
  ApiFcUnbanUserFromChannelRequestBody,
  ApiFcUnblockUser200Response,
  ApiFcUnblockUserRequestBody,
  ApiFcUnfollowChannel200Response,
  ApiFcUnfollowChannelRequestBody,
  ApiFcUnpinCast200Response,
  ApiFcUnpinCastRequestBody,
  ApiFindLocation200Response,
  ApiFindLocationQueryParams,
  ApiFinishInAppPurchase200Response,
  ApiFinishInAppPurchaseRequestBody,
  ApiFinishInAppPurchaseWithCustody200Response,
  ApiFinishInAppPurchaseWithCustodyRequestBody,
  ApiGenerateCastShareableImageQueryParams,
  ApiGenerateChannelImageQueryParams,
  ApiGenerateImageUploadUrl201Response,
  ApiGenerateInviteOpenGraphImageQueryParams,
  ApiGenerateOpenGraphImageQueryParams,
  ApiGenerateRegistrationHashes200Response,
  ApiGenerateRegistrationHashesRequestBody,
  ApiGetActiveChannelStreak200Response,
  ApiGetActiveChannelStreakQueryParams,
  ApiGetAdminFeed200Response,
  ApiGetAdminFeedQueryParams,
  ApiGetAllChannelsPublic200Response,
  ApiGetAllRecentCasts200Response,
  ApiGetAllRecentCastsQueryParams,
  ApiGetAMA200Response,
  ApiGetAMAQueryParams,
  ApiGetApiKeys200Response,
  ApiGetAppBlockedUsers200Response,
  ApiGetAsset200Response,
  ApiGetAssetEvents200Response,
  ApiGetAssetEventsFeed200Response,
  ApiGetAssetEventsFeedQueryParams,
  ApiGetAssetEventsQueryParams,
  ApiGetAssetQueryParams,
  ApiGetAuthenticatedUser200Response,
  ApiGetAvailableWalletsToMintTo200Response,
  ApiGetBlockedUsersPublic200Response,
  ApiGetBlockedUsersPublicQueryParams,
  ApiGetBookmarkedCasts200Response,
  ApiGetBookmarkedCastsQueryParams,
  ApiGetCastLikes200Response,
  ApiGetCastLikesQueryParams,
  ApiGetCastQuotes200Response,
  ApiGetCastQuotesQueryParams,
  ApiGetCastReactions200Response,
  ApiGetCastReactionsQueryParams,
  ApiGetCastRecasters200Response,
  ApiGetCastRecastersQueryParams,
  ApiGetChannel200Response,
  ApiGetChannelBannedUsers200Response,
  ApiGetChannelBannedUsersQueryParams,
  ApiGetChannelCreationInfo200Response,
  ApiGetChannelDetails200Response,
  ApiGetChannelDetailsQueryParams,
  ApiGetChannelFollowers200Response,
  ApiGetChannelFollowersPublic200Response,
  ApiGetChannelFollowersPublicQueryParams,
  ApiGetChannelFollowersQueryParams,
  ApiGetChannelFollowersYouKnow200Response,
  ApiGetChannelFollowersYouKnowQueryParams,
  ApiGetChannelHosts200Response,
  ApiGetChannelHostsQueryParams,
  ApiGetChannelPublic200Response,
  ApiGetChannelPublicQueryParams,
  ApiGetChannelQueryParams,
  ApiGetChannelRecsForCast200Response,
  ApiGetChannelRecsForCastQueryParams,
  ApiGetChannelSettings200Response,
  ApiGetChannelSettingsQueryParams,
  ApiGetChannelTopCasters200Response,
  ApiGetChannelTopCastersQueryParams,
  ApiGetChannelUsers200Response,
  ApiGetChannelUsersForInvite200Response,
  ApiGetChannelUsersForInviteQueryParams,
  ApiGetChannelUsersForManagement200Response,
  ApiGetChannelUsersForManagementQueryParams,
  ApiGetChannelUsersQueryParams,
  ApiGetClientConfig200Response,
  ApiGetCollection200Response,
  ApiGetCollectionActivity200Response,
  ApiGetCollectionActivityQueryParams,
  ApiGetCollectionAssetsOwnedByUser200Response,
  ApiGetCollectionAssetsOwnedByUserQueryParams,
  ApiGetCollectionOwners200Response,
  ApiGetCollectionOwnersQueryParams,
  ApiGetCollectionQueryParams,
  ApiGetCollectionsOwnedByUser200Response,
  ApiGetCollectionsOwnedByUserQueryParams,
  ApiGetComposerAction200Response,
  ApiGetComposerActionQueryParams,
  ApiGetComposerChannelSuggestions200Response,
  ApiGetComposerChannelSuggestionsQueryParams,
  ApiGetConnectedAccounts200Response,
  ApiGetConnectedAccountsQueryParams,
  ApiGetContactsUsers200Response,
  ApiGetContactsUsersQueryParams,
  ApiGetConversationCastReplies200Response,
  ApiGetConversationCastRepliesObsolete200Response,
  ApiGetConversationCastRepliesObsoleteQueryParams,
  ApiGetConversationCastRepliesQueryParams,
  ApiGetCustodyAddress200Response,
  ApiGetCustodyAddressQueryParams,
  ApiGetDelegateSigner200Response,
  ApiGetDirectCastConversation200Response,
  ApiGetDirectCastConversationMessages200Response,
  ApiGetDirectCastConversationMessagesQueryParams,
  ApiGetDirectCastConversationQueryParams,
  ApiGetDirectCastConversationReactionsV3QueryParams,
  ApiGetDirectCastConversationReactionsV3200Response,
  ApiGetDirectCastConversationRecentMessages200Response,
  ApiGetDirectCastConversationRecentMessagesQueryParams,
  ApiGetDirectCastConversationsV3QueryParams,
  ApiGetDirectCastConversationsV3200Response,
  ApiGetDirectCastConversationV3QueryParams,
  ApiGetDirectCastConversationV3200Response,
  ApiGetDirectCastGroupInvites200Response,
  ApiGetDirectCastGroupInvitesQueryParams,
  ApiGetDirectCastGroupInviteV3QueryParams,
  ApiGetDirectCastGroupInviteV3200Response,
  ApiGetDirectCastInbox200Response,
  ApiGetDirectCastInboxQueryParams,
  ApiGetDirectCastKeys200Response,
  ApiGetDirectCastKeysByAccount200Response,
  ApiGetDirectCastKeysByAccountQueryParams,
  ApiGetDirectCastKeysQueryParams,
  ApiGetDirectCastTTLs200Response,
  ApiGetDirectCastUsers200Response,
  ApiGetDirectCastUsersQueryParams,
  ApiGetDiscoveryApp200Response,
  ApiGetDiscoveryAppQueryParams,
  ApiGetDiscoveryFrame200Response,
  ApiGetDiscoveryFrameQueryParams,
  ApiGetDraftCasts200Response,
  ApiGetDraftCastsQueryParams,
  ApiGetDynamicConfigs200Response,
  ApiGetFarcasterAction200Response,
  ApiGetFarcasterActionQueryParams,
  ApiGetFeedItems200Response,
  ApiGetFeedItemsRequestBody,
  ApiGetFeedPageViews200Response,
  ApiGetFeedPageViewsQueryParams,
  ApiGetFeedSummaries200Response,
  ApiGetFname200Response,
  ApiGetFnameQueryParams,
  ApiGetFollowedUsersAffinityScore200Response,
  ApiGetFollowedUsersAffinityScoreQueryParams,
  ApiGetFollowers200Response,
  ApiGetFollowersQueryParams,
  ApiGetFollowersYouKnow200Response,
  ApiGetFollowersYouKnowQueryParams,
  ApiGetFollowing200Response,
  ApiGetFollowingQueryParams,
  ApiGetFrameBlocklist200Response,
  ApiGetGeneralizedInAppMintsWarpsOffering200Response,
  ApiGetGeneralizedInAppMintsWarpsOfferingQueryParams,
  ApiGetGlobalFrameAnalytics200Response,
  ApiGetGlobalFrameAnalyticsQueryParams,
  ApiGetGroupedAssetsOwnedByUser200Response,
  ApiGetGroupedAssetsOwnedByUserQueryParams,
  ApiGetHealth200Response,
  ApiGetHighlightedChannels200Response,
  ApiGetHomeFeedGenerationHistory200Response,
  ApiGetHomeFeedGenerationHistoryQueryParams,
  ApiGetInAppMintsWarpsOffering200Response,
  ApiGetInAppMintsWarpsOfferingQueryParams,
  ApiGetInvite200Response,
  ApiGetInviteQueryParams,
  ApiGetInvitersLeaderboard200Response,
  ApiGetInvitesAvailable200Response,
  ApiGetInvitesViewed200Response,
  ApiGetInviteWithWarpsOffering200Response,
  ApiGetIsUserInvited200Response,
  ApiGetIsUserInvitedQueryParams,
  ApiGetKeyTransaction200Response,
  ApiGetKeyTransactionQueryParams,
  ApiGetLeastInteractedWithFollowing200Response,
  ApiGetLeastInteractedWithFollowingQueryParams,
  ApiGetMintStatus200Response,
  ApiGetMintStatusQueryParams,
  ApiGetMintWithWarpsOffering200Response,
  ApiGetMintWithWarpsOfferingQueryParams,
  ApiGetMostActiveChannels200Response,
  ApiGetMostActiveChannelsQueryParams,
  ApiGetMutedKeywords200Response,
  ApiGetNotificationActorsInGroup200Response,
  ApiGetNotificationActorsInGroupQueryParams,
  ApiGetNotificationGroupsAdmin200Response,
  ApiGetNotificationGroupsAdminQueryParams,
  ApiGetNotificationsForTab200Response,
  ApiGetNotificationsForTabQueryParams,
  ApiGetNotificationsInGroup200Response,
  ApiGetNotificationsInGroupQueryParams,
  ApiGetOffering200Response,
  ApiGetOfferingQueryParams,
  ApiGetOnboardingInterests200Response,
  ApiGetOnboardingInterestsQueryParams,
  ApiGetOnboardingState200Response,
  ApiGetOnboardingStateAndAuthToken200Response,
  ApiGetOnboardingStateAndAuthTokenRequestBody,
  ApiGetOnchainAction200Response,
  ApiGetOnchainActionQueryParams,
  ApiGetPendingAdminReviews200Response,
  ApiGetPendingAdminReviewsQueryParams,
  ApiGetPollResults200Response,
  ApiGetPollResultsQueryParams,
  ApiGetPrimaryAddress200Response,
  ApiGetPrimaryAddressQueryParams,
  ApiGetProductCatalog200Response,
  ApiGetRecoverHash200Response,
  ApiGetRecoverHashQueryParams,
  ApiGetRecovery200Response,
  ApiGetRecoveryAddress200Response,
  ApiGetRecoveryAddressChange200Response,
  ApiGetRecoveryAddressChangeHash200Response,
  ApiGetRecoveryAddressChangeHashQueryParams,
  ApiGetRecoveryAddressChangeQueryParams,
  ApiGetRecoveryQueryParams,
  ApiGetRentStorageOfferings200Response,
  ApiGetSignedKeyRequest200Response,
  ApiGetSignedKeyRequestQueryParams,
  ApiGetSigner200Response,
  ApiGetSignerQueryParams,
  ApiGetSignerRemoveHash200Response,
  ApiGetSignerRemoveHashQueryParams,
  ApiGetSigners200Response,
  ApiGetSignersMetadata200Response,
  ApiGetSignersQueryParams,
  ApiGetSiweNonce200Response,
  ApiGetStorageUtilization200Response,
  ApiGetSuggestedUsers200Response,
  ApiGetSuggestedUsersQueryParams,
  ApiGetSyncChannel200Response,
  ApiGetSyncChannelQueryParams,
  ApiGetThread200Response,
  ApiGetThreadQueryParams,
  ApiGetUnseen200Response,
  ApiGetUser200Response,
  ApiGetUserAppContext200Response,
  ApiGetUserByFID200Response,
  ApiGetUserByFIDQueryParams,
  ApiGetUserByUsername200Response,
  ApiGetUserByUsernameQueryParams,
  ApiGetUserByVerification200Response,
  ApiGetUserByVerificationQueryParams,
  ApiGetUserCast200Response,
  ApiGetUserCastableChannels200Response,
  ApiGetUserCastableChannelsQueryParams,
  ApiGetUserCastQueryParams,
  ApiGetUserCasts200Response,
  ApiGetUserCastsAndReplies200Response,
  ApiGetUserCastsAndRepliesQueryParams,
  ApiGetUserCastsQueryParams,
  ApiGetUserChannelPublic200Response,
  ApiGetUserChannelPublicQueryParams,
  ApiGetUserChannels200Response,
  ApiGetUserChannelsQueryParams,
  ApiGetUserFollowingChannelsPublic200Response,
  ApiGetUserFollowingChannelsPublicQueryParams,
  ApiGetUserLikedCasts200Response,
  ApiGetUserLikedCastsQueryParams,
  ApiGetUserPreferences200Response,
  ApiGetUserQueryParams,
  ApiGetUsersByLocation200Response,
  ApiGetUsersByLocationQueryParams,
  ApiGetUserThreadCasts200Response,
  ApiGetUserThreadCastsQueryParams,
  ApiGetUserThreadHiddenReplies200Response,
  ApiGetUserThreadHiddenRepliesQueryParams,
  ApiGetUserUsernames200Response,
  ApiGetUserViewedCasts200Response,
  ApiGetUserViewedCastsQueryParams,
  ApiGetVerifications200Response,
  ApiGetVerificationsQueryParams,
  ApiGetVideoState200Response,
  ApiGetVideoStateQueryParams,
  ApiGetWarpsBalance200Response,
  ApiGetWarpsBalanceQueryParams,
  ApiGetWarpsOffering200Response,
  ApiGetWarpsOfferingQueryParams,
  ApiGetWarpTransactions200Response,
  ApiGetWarpTransactionsQueryParams,
  ApiGetWatchedCasts200Response,
  ApiGetWatchedCastsQueryParams,
  ApiGetXAuthLink200Response,
  ApiGiftWarps200Response,
  ApiGiftWarpsRequestBody,
  ApiInitiateMagicLink200Response,
  ApiInitiateMagicLinkRequestBody,
  ApiInitiateRecovery200Response,
  ApiInitiateRecoveryRequestBody,
  ApiInviteChannelUserToRole200Response,
  ApiInviteChannelUserToRoleRequestBody,
  ApiInviteUser200Response,
  ApiInviteUserRequestBody,
  ApiJoinChannelViaCode200Response,
  ApiJoinChannelViaCodeRequestBody,
  ApiLimitVisibility200Response,
  ApiLimitVisibilityRequestBody,
  ApiLookupOnboardingState200Response,
  ApiLookupOnboardingStateQueryParams,
  ApiManuallyMarkConversationUnread200Response,
  ApiManuallyMarkConversationUnreadRequestBody,
  ApiMarkAllNotificationsRead200Response,
  ApiMarkAllTabNotificationsSeen200Response,
  ApiMarkAllTabNotificationsSeenRequestBody,
  ApiMarkAllWarpTransactionsRead200Response,
  ApiMarkDirectCastKeyAsDead200Response,
  ApiMarkDirectCastKeyAsDeadRequestBody,
  ApiMarkNudgedForContacts200Response,
  ApiMarkNudgedForInterests200Response,
  ApiMarkNudgedForPushNotifications200Response,
  ApiMarkPromptedFor200Response,
  ApiMarkPromptedForRequestBody,
  ApiMarkSyncChannelMessageRead200Response,
  ApiMarkSyncChannelMessageReadRequestBody,
  ApiMarkVerificationsStart200Response,
  ApiMint200Response,
  ApiMintRequestBody,
  ApiPayWarpsAndConnectApp200Response,
  ApiPayWarpsAndConnectAppRequestBody,
  ApiPayWarpsAndDisconnectApp200Response,
  ApiPayWarpsAndDisconnectAppRequestBody,
  ApiPinCastOnUserProfile200Response,
  ApiPinCastOnUserProfileRequestBody,
  ApiPinDirectCastConversation200Response,
  ApiPinDirectCastConversationRequestBody,
  ApiPinDirectCastMessage200Response,
  ApiPinDirectCastMessageRequestBody,
  ApiPostCastAction200Response,
  ApiPostCastActionRequestBody,
  ApiPostComposerAction200Response,
  ApiPostComposerActionRequestBody,
  ApiPostDirectCastAcceptGroupInviteV3RequestBody,
  ApiPostDirectCastAcceptGroupInviteV3200Response,
  ApiPostDirectCastConversationCategorizationBulkV3RequestBody,
  ApiPostDirectCastConversationCategorizationBulkV3200Response,
  ApiPostDirectCastConversationCategorizationV3RequestBody,
  ApiPostDirectCastConversationCategorizationV3200Response,
  ApiPostDirectCastConversationMessageTTL200Response,
  ApiPostDirectCastConversationMessageTTLRequestBody,
  ApiPostDirectCastConversationNotificationsV3RequestBody,
  ApiPostDirectCastConversationNotificationsV3200Response,
  ApiPostDirectCastDeclineGroupInviteV3RequestBody,
  ApiPostDirectCastDeclineGroupInviteV3200Response,
  ApiPostDirectCastGroupMembershipV3RequestBody,
  ApiPostDirectCastGroupMembershipV3200Response,
  ApiPostDirectCastGroupNameV3RequestBody,
  ApiPostDirectCastGroupNameV3200Response,
  ApiPostDirectCastGroupPhotoUrlV3RequestBody,
  ApiPostDirectCastGroupPhotoUrlV3200Response,
  ApiPostDirectCastReadV3RequestBody,
  ApiPostDirectCastReadV3200Response,
  ApiPostFrameAction200Response,
  ApiPostFrameActionRequestBody,
  ApiPostFrameJSON200Response,
  ApiPostFrameJSONRequestBody,
  ApiPostFrameRedirect200Response,
  ApiPostFrameRedirectRequestBody,
  ApiPostFrameTransaction200Response,
  ApiPostFrameTransactionRequestBody,
  ApiPostMiniAppTransaction200Response,
  ApiPostMiniAppTransactionRequestBody,
  ApiPrepareVideoUpload200Response,
  ApiPrepareVideoUploadRequestBody,
  ApiProcessCastAttachments200Response,
  ApiProcessCastAttachmentsRequestBody,
  ApiProcessDirectCastMessageMetadata200Response,
  ApiProcessDirectCastMessageMetadataRequestBody,
  ApiPutDirectCastConversationReactionsV3RequestBody,
  ApiPutDirectCastConversationReactionsV3200Response,
  ApiPutDirectCastGroupInviteV3RequestBody,
  ApiPutDirectCastGroupInviteV3200Response,
  ApiPutDirectCastGroupV3RequestBody,
  ApiPutDirectCastGroupV3200Response,
  ApiPutDirectCastV3RequestBody,
  ApiPutDirectCastV3200Response,
  ApiPutExtSendDirectCast200Response,
  ApiPutExtSendDirectCastRequestBody,
  ApiPutVerification200Response,
  ApiPutVerificationRequestBody,
  ApiReceiveAppStoreServerNotification200Response,
  ApiReceiveAppStoreServerNotificationRequestBody,
  ApiReceiveAppStoreServerNotificationSandbox200Response,
  ApiReceiveAppStoreServerNotificationSandboxRequestBody,
  ApiReceiveCoinbaseCommerceWebhookEvent200Response,
  ApiRecommendedChannels200Response,
  ApiRecommendedChannelsQueryParams,
  ApiRecordAnalyticsEvents200Response,
  ApiRecordAnalyticsEventsRequestBody,
  ApiRecordCastFeedback200Response,
  ApiRecordCastFeedbackRequestBody,
  ApiRecordPollVote200Response,
  ApiRecordPollVoteRequestBody,
  ApiRedirectToLinkQueryParams,
  ApiRedirectToLinkV2QueryParams,
  ApiRegisterDevice200Response,
  ApiRegisterDeviceRequestBody,
  ApiRegisterFid200Response,
  ApiRegisterFidRequestBody,
  ApiRemoveCastBookmark200Response,
  ApiRemoveCastBookmarkRequestBody,
  ApiRemoveCastBoost200Response,
  ApiRemoveCastBoostRequestBody,
  ApiRemoveChannelUserFromRole200Response,
  ApiRemoveChannelUserFromRoleRequestBody,
  ApiRemoveConnectedAccount200Response,
  ApiRemoveConnectedAccountRequestBody,
  ApiRemoveFavoriteFeed200Response,
  ApiRemoveFavoriteFeedRequestBody,
  ApiRemoveMuteKeyword200Response,
  ApiRemoveMuteKeywordRequestBody,
  ApiRemoveSigner200Response,
  ApiRemoveSignerRequestBody,
  ApiRemoveVisibilityRestrictions200Response,
  ApiRemoveVisibilityRestrictionsRequestBody,
  ApiRentStorage201Response,
  ApiRentStorageRequestBody,
  ApiRentTransactionDataQueryParams,
  ApiReportCast200Response,
  ApiReportCastRequestBody,
  ApiReportFrameTransaction200Response,
  ApiReportFrameTransactionRequestBody,
  ApiReportUser200Response,
  ApiReportUserRequestBody,
  ApiRequestAccountDelete200Response,
  ApiResetChannelInviteCode200Response,
  ApiResetChannelInviteCodeRequestBody,
  ApiResetDynamicConfig200Response,
  ApiResetDynamicConfigRequestBody,
  ApiResetOnboardingState200Response,
  ApiResetOnboardingStateRequestBody,
  ApiResetToNewUserExperience200Response,
  ApiRespondToChannelInvite200Response,
  ApiRespondToChannelInviteRequestBody,
  ApiRevokeApiKey200Response,
  ApiRevokeApiKeyRequestBody,
  ApiScrapeEmbed200Response,
  ApiScrapeEmbedRequestBody,
  ApiSearchActions200Response,
  ApiSearchActionsQueryParams,
  ApiSearchCasts200Response,
  ApiSearchCastsQueryParams,
  ApiSearchChannels200Response,
  ApiSearchChannelsQueryParams,
  ApiSearchComposerActions200Response,
  ApiSearchComposerActionsQueryParams,
  ApiSearchDirectCastInbox200Response,
  ApiSearchDirectCastInboxQueryParams,
  ApiSearchDirectCasts200Response,
  ApiSearchDirectCastsQueryParams,
  ApiSearchSummary200Response,
  ApiSearchSummaryQueryParams,
  ApiSearchUsers200Response,
  ApiSearchUsersQueryParams,
  ApiSendBuyWarpsInfoEmail200Response,
  ApiSendConnectAddressLinkEmail200Response,
  ApiSendPushNotification200Response,
  ApiSendPushNotificationRequestBody,
  ApiSendVerificationEmail200Response,
  ApiSendVerificationEmailRequestBody,
  ApiSendWarps200Response,
  ApiSendWarpsRequestBody,
  ApiSetAppBlockedUsers200Response,
  ApiSetAppBlockedUsersRequestBody,
  ApiSetBulkAutoUserQuality200Response,
  ApiSetBulkAutoUserQualityRequestBody,
  ApiSetBulkUserQuality200Response,
  ApiSetBulkUserQualityRequestBody,
  ApiSetChannelDistribution200Response,
  ApiSetChannelDistributionRequestBody,
  ApiSetContactsDeviceState200Response,
  ApiSetContactsDeviceStateRequestBody,
  ApiSetDynamicConfig200Response,
  ApiSetDynamicConfigRequestBody,
  ApiSetFavoriteFeedPosition200Response,
  ApiSetFavoriteFeedPositionRequestBody,
  ApiSetFeedSeen200Response,
  ApiSetFeedSeenRequestBody,
  ApiSetInvitesViewed200Response,
  ApiSetLowQualityUserBadness200Response,
  ApiSetLowQualityUserBadnessRequestBody,
  ApiSetSuggestedUsersAsSeen200Response,
  ApiSetSuggestedUsersAsSeenRequestBody,
  ApiSetUserCastActionPosition200Response,
  ApiSetUserCastActionPositionRequestBody,
  ApiSetUserPreferences200Response,
  ApiSetUserPreferencesRequestBody,
  ApiSetUserQuality200Response,
  ApiSetUserQualityRequestBody,
  ApiSetUserReferrer200Response,
  ApiSetUserReferrerRequestBody,
  ApiSetUserUsername200Response,
  ApiSetUserUsernameRequestBody,
  ApiShareCast200Response,
  ApiShareCastQueryParams,
  ApiSignInWithFarcaster200Response,
  ApiSignInWithFarcasterRequestBody,
  ApiSignRecoveryTransactionHashQueryParams,
  ApiSignupForInvite200Response,
  ApiSignupForInviteRequestBody,
  ApiSimulateCreateSignedKeyRequest200Response,
  ApiSimulateCreateSignedKeyRequestRequestBody,
  ApiSimulateRegisterFid200Response,
  ApiSimulateRegisterFidRequestBody,
  ApiSimulateRemoveSignedKeyRequest200Response,
  ApiSimulateRemoveSignedKeyRequestRequestBody,
  ApiSimulateRentStorage200Response,
  ApiSimulateRentStorageQueryParams,
  ApiSkipConnectAddress200Response,
  ApiSkipPhone200Response,
  ApiSkipPhoneRequestBody,
  ApiStartChannelStreak200Response,
  ApiStartChannelStreakRequestBody,
  ApiStartInAppPurchase200Response,
  ApiStartInAppPurchaseRequestBody,
  ApiStartInAppPurchaseWithCustody200Response,
  ApiStartInAppPurchaseWithCustodyRequestBody,
  ApiStartPhoneVerification200Response,
  ApiStartPhoneVerificationRequestBody,
  ApiStartSignedUpAttestations200Response,
  ApiStartSignedUpAttestationsRequestBody,
  ApiStartVerification200Response,
  ApiStopActiveChannelStreaks200Response,
  ApiStoreDraftCast200Response,
  ApiStoreDraftCastRequestBody,
  ApiSubmitSelectedOnboardingInterests200Response,
  ApiSubmitSelectedOnboardingInterestsRequestBody,
  ApiSubmitSignedMessageData200Response,
  ApiSubmitSignedMessageDataRequestBody,
  ApiTip10WarpsActionRequestBody,
  ApiTipCast200Response,
  ApiTipCastRequestBody,
  ApiUnbanUserFromChannel200Response,
  ApiUnbanUserFromChannelRequestBody,
  ApiUnfollowLeastInteractedWithFollowing200Response,
  ApiUnpinCastOnUserProfile200Response,
  ApiUnpinCastOnUserProfileRequestBody,
  ApiUnpinDirectCastConversation200Response,
  ApiUnpinDirectCastConversationRequestBody,
  ApiUnpinDirectCastMessage200Response,
  ApiUnpinDirectCastMessageRequestBody,
  ApiUnregisterDevice200Response,
  ApiUnregisterDeviceRequestBody,
  ApiUnsubscribeEmailOneClickQueryParams,
  ApiUnsubscribeEmailQueryParams,
  ApiUnsubscribeFidOneClickQueryParams,
  ApiUnsubscribeFidQueryParams,
  ApiUnwatchCast200Response,
  ApiUnwatchCastRequestBody,
  ApiUpdateChannel200Response,
  ApiUpdateChannelRequestBody,
  ApiUpdateDirectCastGroupPreferences200Response,
  ApiUpdateDirectCastGroupPreferencesRequestBody,
  ApiUpdateEmail200Response,
  ApiUpdateEmailRequestBody,
  ApiUpdateJobQueue200Response,
  ApiUpdateJobQueueRequestBody,
  ApiUpdatePaidInvite200Response,
  ApiUpdatePaidInviteRequestBody,
  ApiUpdatePendingAdminReview200Response,
  ApiUpdatePendingAdminReviewRequestBody,
  ApiUpdateRecoveryAddress200Response,
  ApiUpdateRecoveryAddressRequestBody,
  ApiUpdateSyncChannel200Response,
  ApiUpdateSyncChannelRequestBody,
  ApiUpdateUser200Response,
  ApiUpdateUserLocation200Response,
  ApiUpdateUserLocationRequestBody,
  ApiUpdateUserRequestBody,
  ApiUploadContacts200Response,
  ApiUploadContactsRequestBody,
  ApiUploadVideoWithTusQueryParams,
  ApiValidateApiKey200Response,
  ApiValidateComposerAction200Response,
  ApiValidateComposerActionRequestBody,
  ApiValidateFrameEmbed200Response,
  ApiValidateFrameEmbedRequestBody,
  ApiValidateNewChannelKey200Response,
  ApiValidateNewChannelKeyQueryParams,
  ApiVerifyEmailQueryParams,
  ApiWatchCast200Response,
  ApiWatchCastRequestBody,
  RequestHeaders,
} from '../types';
import { AbstractFarcasterApiClient } from './AbstractFarcasterApiClient';

export class FarcasterApiClient extends AbstractFarcasterApiClient {
  /**
   * Accept or decline an outstanding channel member or moderator invite
   */
  fcRespondToChannelRoleInvite(
    body: ApiFcRespondToChannelRoleInviteRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiFcRespondToChannelRoleInvite200Response>(
      '/fc/channel-invites',
      {
        headers,
        endpointName: 'fcRespondToChannelRoleInvite',
        body,
      },
    );
  }

  /**
   * Accept or decline an outstanding channel member or moderator invite
   */
  respondToChannelInvite(
    body: ApiRespondToChannelInviteRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiRespondToChannelInvite200Response>(
      '/v1/manage-channel-users',
      {
        headers,
        endpointName: 'respondToChannelInvite',
        body,
      },
    );
  }

  /**
   * Accepts an invite to a direct cast group.
   */
  postDirectCastAcceptGroupInviteV3(
    body: ApiPostDirectCastAcceptGroupInviteV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastAcceptGroupInviteV3200Response>(
      '/v2/direct-cast-accept-group-invite',
      {
        headers,
        endpointName: 'postDirectCastAcceptGroupInviteV3',
        body,
      },
    );
  }

  /**
   * Add a cast to the trending feed
   */
  addCastToTrendingFeed(
    body: ApiAddCastToTrendingFeedRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAddCastToTrendingFeed200Response>(
      '/v2/add-cast-to-trending-feed',
      {
        headers,
        endpointName: 'addCastToTrendingFeed',
        body,
      },
    );
  }

  /**
   * Add a keyword to a user's mute list
   */
  addMuteKeyword(
    body: ApiAddMuteKeywordRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAddMuteKeyword200Response>('/v2/mute-keyword', {
      headers,
      endpointName: 'addMuteKeyword',
      body,
    });
  }

  /**
   * Add a public key for direct cast conversations (deprecated).
   */
  addDirectCastUserKey(
    body: ApiAddDirectCastUserKeyRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiAddDirectCastUserKey200Response>(
      '/v2/direct-cast-keys',
      {
        headers,
        endpointName: 'addDirectCastUserKey',
        body,
      },
    );
  }

  /**
   * Add a public key for direct cast conversations. (deprecated)
   */
  addDirectCastKeysByAccount(
    body: ApiAddDirectCastKeysByAccountRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiAddDirectCastKeysByAccount200Response>(
      '/v2/direct-cast-device-keys',
      {
        headers,
        endpointName: 'addDirectCastKeysByAccount',
        body,
      },
    );
  }

  /**
   * Add a usename to the authenticated user
   */
  addUserUsername(
    body: ApiAddUserUsernameRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAddUserUsername200Response>('/v2/user-usernames', {
      headers,
      endpointName: 'addUserUsername',
      body,
    });
  }

  /**
   * Adds an action to a users actions
   */
  addCastAction(
    body: ApiAddCastActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAddCastAction200Response>('/v2/add-cast-action', {
      headers,
      endpointName: 'addCastAction',
      body,
    });
  }

  /**
   * Allocate invite to user.
   */
  allocateInvites(
    body: ApiAllocateInvitesRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiAllocateInvites200Response>('/v2/invites-available', {
      headers,
      endpointName: 'allocateInvites',
      body,
    });
  }

  /**
   * Allowlist an email for sponsored registration
   */
  allowSponsoredRegistration(
    body: ApiAllowSponsoredRegistrationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiAllowSponsoredRegistration200Response>(
      '/v2/allow-sponsored-registration',
      {
        headers,
        endpointName: 'allowSponsoredRegistration',
        body,
      },
    );
  }

  /**
   * Allows admins to fetch all or a single notification for a user, used for,  * troubleshooting issues
   */
  getNotificationGroupsAdmin(
    params: ApiGetNotificationGroupsAdminQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetNotificationGroupsAdmin200Response>(
      '/v2/notification-groups-admin',
      {
        headers,
        endpointName: 'getNotificationGroupsAdmin',
        params,
      },
    );
  }

  /**
   * Allows admins to pin a message in conversation.
   */
  pinDirectCastMessage(
    body: ApiPinDirectCastMessageRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPinDirectCastMessage200Response>(
      '/v2/direct-cast-pin-message',
      {
        headers,
        endpointName: 'pinDirectCastMessage',
        body,
      },
    );
  }

  /**
   * Allows admins to unpint a message in conversation.
   */
  unpinDirectCastMessage(
    body: ApiUnpinDirectCastMessageRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiUnpinDirectCastMessage200Response>(
      '/v2/direct-cast-pin-message',
      {
        headers,
        endpointName: 'unpinDirectCastMessage',
        body,
      },
    );
  }

  /**
   * Allows user pay in warps and connect app (create a signer).
   */
  payWarpsAndConnectApp(
    body: ApiPayWarpsAndConnectAppRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiPayWarpsAndConnectApp200Response>(
      '/v2/pay-warps-and-connect-app',
      {
        headers,
        endpointName: 'payWarpsAndConnectApp',
        body,
      },
    );
  }

  /**
   * Allows user pay in warps and disconnect app (create a signer).
   */
  payWarpsAndDisconnectApp(
    body: ApiPayWarpsAndDisconnectAppRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiPayWarpsAndDisconnectApp200Response>(
      '/v2/pay-warps-and-disconnect-app',
      {
        headers,
        endpointName: 'payWarpsAndDisconnectApp',
        body,
      },
    );
  }

  /**
   * Allows user pay in warps and mint an asset.
   */
  mint(
    body: ApiMintRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiMint200Response>('/v2/mint', {
      headers,
      endpointName: 'mint',
      body,
    });
  }

  /**
   * Allows users to manually mark a conversation unread.
   */
  manuallyMarkConversationUnread(
    body: ApiManuallyMarkConversationUnreadRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiManuallyMarkConversationUnread200Response>(
      '/v2/direct-cast-manually-mark-unread',
      {
        headers,
        endpointName: 'manuallyMarkConversationUnread',
        body,
      },
    );
  }

  /**
   * Alters a direct cast conversation categorization.
   */
  postDirectCastConversationCategorizationV3(
    body: ApiPostDirectCastConversationCategorizationV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastConversationCategorizationV3200Response>(
      '/v2/direct-cast-conversation-categorization',
      {
        headers,
        endpointName: 'postDirectCastConversationCategorizationV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast conversation's message ttl.
   */
  postDirectCastConversationMessageTTL(
    body: ApiPostDirectCastConversationMessageTTLRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastConversationMessageTTL200Response>(
      '/v2/direct-cast-conversation-message-ttl',
      {
        headers,
        endpointName: 'postDirectCastConversationMessageTTL',
        body,
      },
    );
  }

  /**
   * Alters a direct cast conversation's notification behavior.
   */
  postDirectCastConversationNotificationsV3(
    body: ApiPostDirectCastConversationNotificationsV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastConversationNotificationsV3200Response>(
      '/v2/direct-cast-conversation-notifications',
      {
        headers,
        endpointName: 'postDirectCastConversationNotificationsV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast conversations categorization in bulk.
   */
  postDirectCastConversationCategorizationBulkV3(
    body: ApiPostDirectCastConversationCategorizationBulkV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastConversationCategorizationBulkV3200Response>(
      '/v2/direct-cast-conversation-categorization-all',
      {
        headers,
        endpointName: 'postDirectCastConversationCategorizationBulkV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast group's membership.
   */
  postDirectCastGroupMembershipV3(
    body: ApiPostDirectCastGroupMembershipV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastGroupMembershipV3200Response>(
      '/v2/direct-cast-group-membership',
      {
        headers,
        endpointName: 'postDirectCastGroupMembershipV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast group's name.
   */
  postDirectCastGroupNameV3(
    body: ApiPostDirectCastGroupNameV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastGroupNameV3200Response>(
      '/v2/direct-cast-group',
      {
        headers,
        endpointName: 'postDirectCastGroupNameV3',
        body,
      },
    );
  }

  /**
   * Alters a direct cast group's photo url.
   */
  postDirectCastGroupPhotoUrlV3(
    body: ApiPostDirectCastGroupPhotoUrlV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastGroupPhotoUrlV3200Response>(
      '/v2/direct-cast-group-photo',
      {
        headers,
        endpointName: 'postDirectCastGroupPhotoUrlV3',
        body,
      },
    );
  }

  /**
   * Applies a reaction to the specified message.
   */
  putDirectCastConversationReactionsV3(
    body: ApiPutDirectCastConversationReactionsV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiPutDirectCastConversationReactionsV3200Response>(
      '/v2/direct-cast-message-reaction',
      {
        headers,
        endpointName: 'putDirectCastConversationReactionsV3',
        body,
      },
    );
  }

  /**
   * Approve a signed key request.
   */
  approveSignedKeyRequest(
    body: ApiApproveSignedKeyRequestRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiApproveSignedKeyRequest200Response>(
      '/v2/approve-signed-key-requests',
      {
        headers,
        endpointName: 'approveSignedKeyRequest',
        body,
      },
    );
  }

  /**
   * Approve or reject a pending admin review
   */
  updatePendingAdminReview(
    body: ApiUpdatePendingAdminReviewRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiUpdatePendingAdminReview200Response>(
      '/v2/pending-admin-reviews',
      {
        headers,
        endpointName: 'updatePendingAdminReview',
        body,
      },
    );
  }

  /**
   * Ban a user from a channel
   */
  banUserFromChannel(
    body: ApiBanUserFromChannelRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiBanUserFromChannel200Response>(
      '/v1/channel-banned-users',
      {
        headers,
        endpointName: 'banUserFromChannel',
        body,
      },
    );
  }

  /**
   * Ban a user from a channel
   */
  fcBanUserFromChannel(
    body: ApiFcBanUserFromChannelRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFcBanUserFromChannel200Response>('/fc/channel-bans', {
      headers,
      endpointName: 'fcBanUserFromChannel',
      body,
    });
  }

  /**
   * Block a user
   */
  fcBlockUser(
    body: ApiFcBlockUserRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFcBlockUser200Response>('/fc/blocked-users', {
      headers,
      endpointName: 'fcBlockUser',
      body,
    });
  }

  /**
   * Block users from using the app
   */
  setAppBlockedUsers(
    body: ApiSetAppBlockedUsersRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetAppBlockedUsers200Response>('/v1/app-blocked-users', {
      headers,
      endpointName: 'setAppBlockedUsers',
      body,
    });
  }

  /**
   * Bookmark a cast.
   */
  bookmarkCast(
    body: ApiBookmarkCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiBookmarkCast200Response>('/v2/bookmarked-casts', {
      headers,
      endpointName: 'bookmarkCast',
      body,
    });
  }

  /**
   * Boost a cast in a channel
   */
  boostCast(
    body: ApiBoostCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiBoostCast200Response>('/v2/boost-cast', {
      headers,
      endpointName: 'boostCast',
      body,
    });
  }

  /**
   * Browse different lists of actions
   */
  discoverActions(
    params: ApiDiscoverActionsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiDiscoverActions200Response>(
      '/v2/discover-actions',
      {
        headers,
        endpointName: 'discoverActions',
        params,
      },
    );
  }

  /**
   * Browse different lists of actions
   */
  discoverComposerActions(
    params: ApiDiscoverComposerActionsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiDiscoverComposerActions200Response>(
      '/v2/discover-composer-actions',
      {
        headers,
        endpointName: 'discoverComposerActions',
        params,
      },
    );
  }

  /**
   * Browse different lists of apps
   */
  discoverApps(
    params: ApiDiscoverAppsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiDiscoverApps200Response>('/v2/discover-apps', {
      headers,
      endpointName: 'discoverApps',
      params,
    });
  }

  /**
   * Browse different lists of frames
   */
  discoverFrames(
    params: ApiDiscoverFramesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiDiscoverFrames200Response>('/v2/discover-frames', {
      headers,
      endpointName: 'discoverFrames',
      params,
    });
  }

  /**
   * Buy Warps with Coinbase Commerce.
   */
  buyWarpsCoinbaseCommerce(
    body: ApiBuyWarpsCoinbaseCommerceRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiBuyWarpsCoinbaseCommerce200Response>(
      '/v2/buy-warps-coinbase-commerce',
      {
        headers,
        endpointName: 'buyWarpsCoinbaseCommerce',
        body,
      },
    );
  }

  /**
   * Check and confirm the receipt to finalize in-app purchase for authenticated user.
   */
  finishInAppPurchase(
    body: ApiFinishInAppPurchaseRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFinishInAppPurchase200Response>(
      '/v2/finish-in-app-purchase',
      {
        headers,
        endpointName: 'finishInAppPurchase',
        body,
      },
    );
  }

  /**
   * Check if gifting is allowed between users
   */
  canGiftWarps(
    params: ApiCanGiftWarpsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiCanGiftWarps200Response>('/v2/gift-warps', {
      headers,
      endpointName: 'canGiftWarps',
      params,
    });
  }

  /**
   * Check if the desired key for a channel is valid and free
   */
  validateNewChannelKey(
    params: ApiValidateNewChannelKeyQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiValidateNewChannelKey200Response>(
      '/v2/validate-new-channel-key',
      {
        headers,
        endpointName: 'validateNewChannelKey',
        params,
      },
    );
  }

  /**
   * Claim an invite
   */
  claimInvite(
    body: ApiClaimInviteRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiClaimInvite200Response>('/v2/claim-invite', {
      headers,
      endpointName: 'claimInvite',
      body,
    });
  }

  /**
   * Clear job queue or queue shart
   */
  clearJobQueue(
    body: ApiClearJobQueueRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiClearJobQueue200Response>('/v1/job-queue', {
      headers,
      endpointName: 'clearJobQueue',
      body,
    });
  }

  /**
   * Complete Farcaster signed up quest with an attestation
   */
  completeSignedUpAttestations({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiCompleteSignedUpAttestations200Response>(
      '/v2/signed-up-attestations',
      {
        headers,
        endpointName: 'completeSignedUpAttestations',
        body: {},
      },
    );
  }

  /**
   * Complete a phone verification
   */
  completePhoneVerification(
    body: ApiCompletePhoneVerificationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiCompletePhoneVerification200Response>(
      '/v2/complete-phone-verification',
      {
        headers,
        endpointName: 'completePhoneVerification',
        body,
      },
    );
  }

  /**
   * Complete registration for an FID by submitting a username and delegating a signer.
   */
  completeRegistration(
    body: ApiCompleteRegistrationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCompleteRegistration200Response>(
      '/v2/complete-registration',
      {
        headers,
        endpointName: 'completeRegistration',
        body,
      },
    );
  }

  /**
   * Confirm a magic link
   */
  completeMagicLink(
    body: ApiCompleteMagicLinkRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiCompleteMagicLink200Response>(
      '/v2/magic-link-complete',
      {
        headers,
        endpointName: 'completeMagicLink',
        body,
      },
    );
  }

  /**
   * Confirms account deletion. Intended to be loaded via browser.
   */
  confirmAccountDelete(
    params: ApiConfirmAccountDeleteQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/v2/confirm-account-delete', {
      headers,
      endpointName: 'confirmAccountDelete',
      params,
    });
  }

  /**
   * Create a direct cast conversation.
   */
  fcPutConversation(
    body: ApiFcPutConversationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiFcPutConversation200Response>('/fc/conversation', {
      headers,
      endpointName: 'fcPutConversation',
      body,
    });
  }

  /**
   * Create a direct cast group.
   */
  fcPutGroup(
    body: ApiFcPutGroupRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiFcPutGroup200Response>('/fc/group', {
      headers,
      endpointName: 'fcPutGroup',
      body,
    });
  }

  /**
   * Create a like reaction for a cast.
   */
  createCastLike(
    body: ApiCreateCastLikeRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCreateCastLike200Response>('/v2/cast-likes', {
      headers,
      endpointName: 'createCastLike',
      body,
    });
  }

  /**
   * Create a new channel
   */
  createChannel(
    body: ApiCreateChannelRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiCreateChannel200Response>('/v2/channels-owned', {
      headers,
      endpointName: 'createChannel',
      body,
    });
  }

  /**
   * Create a new invite.
   */
  createInvite(
    body: ApiCreateInviteRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCreateInvite200Response>('/v2/invite', {
      headers,
      endpointName: 'createInvite',
      body,
    });
  }

  /**
   * Create a new recovery
   */
  createRecovery(
    body: ApiCreateRecoveryRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiCreateRecovery200Response>('/v2/recoveries', {
      headers,
      endpointName: 'createRecovery',
      body,
    });
  }

  /**
   * Create a poll
   */
  createPoll(
    body: ApiCreatePollRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiCreatePoll200Response>('/v2/create-poll', {
      headers,
      endpointName: 'createPoll',
      body,
    });
  }

  /**
   * Create a reaction to an NFT.
   */
  createNftReaction(
    body: ApiCreateNftReactionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCreateNftReaction200Response>('/v2/nft-reactions', {
      headers,
      endpointName: 'createNftReaction',
      body,
    });
  }

  /**
   * Create a signed key request.
   */
  createSignedKeyRequest(
    body: ApiCreateSignedKeyRequestRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiCreateSignedKeyRequest200Response>(
      '/v2/signed-key-requests',
      {
        headers,
        endpointName: 'createSignedKeyRequest',
        body,
      },
    );
  }

  /**
   * Creates a cast for the currently authenticated user.
   */
  createCast(
    body: ApiCreateCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiCreateCast201Response>('/v2/casts', {
      headers,
      endpointName: 'createCast',
      body,
    });
  }

  /**
   * Creates a group for direct casts.
   */
  putDirectCastGroupV3(
    body: ApiPutDirectCastGroupV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiPutDirectCastGroupV3200Response>(
      '/v2/direct-cast-group',
      {
        headers,
        endpointName: 'putDirectCastGroupV3',
        body,
      },
    );
  }

  /**
   * Creates an invite to a direct cast group, removing the old one if present.
   */
  putDirectCastGroupInviteV3(
    body: ApiPutDirectCastGroupInviteV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPutDirectCastGroupInviteV3200Response>(
      '/v2/direct-cast-group-invite',
      {
        headers,
        endpointName: 'putDirectCastGroupInviteV3',
        body,
      },
    );
  }

  /**
   * Creates an onboarding for an Ethereum account
   */
  createOnboarding(
    body: ApiCreateOnboardingRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCreateOnboarding200Response>('/v2/onboarding', {
      headers,
      endpointName: 'createOnboarding',
      body,
    });
  }

  /**
   * Declines an invite to a direct cast group.
   */
  postDirectCastDeclineGroupInviteV3(
    body: ApiPostDirectCastDeclineGroupInviteV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastDeclineGroupInviteV3200Response>(
      '/v2/direct-cast-decline-group-invite',
      {
        headers,
        endpointName: 'postDirectCastDeclineGroupInviteV3',
        body,
      },
    );
  }

  /**
   * Deeplink to clients.
   */
  redirectToCastDeepLink({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<void>('/casts/*', {
      headers,
      endpointName: 'redirectToCastDeepLink',
    });
  }

  /**
   * Delete a composer action
   */
  deleteComposerAction(
    body: ApiDeleteComposerActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteComposerAction200Response>(
      '/v2/composer-action',
      {
        headers,
        endpointName: 'deleteComposerAction',
        body,
      },
    );
  }

  /**
   * Delete a direct cast message.
   */
  fcDeleteMessage(
    body: ApiFcDeleteMessageRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiFcDeleteMessage200Response>('/fc/message', {
      headers,
      endpointName: 'fcDeleteMessage',
      body,
    });
  }

  /**
   * Delete a discovery app
   */
  deleteDiscoveryApp(
    body: ApiDeleteDiscoveryAppRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteDiscoveryApp200Response>('/v2/discovery-app', {
      headers,
      endpointName: 'deleteDiscoveryApp',
      body,
    });
  }

  /**
   * Delete a discovery frame
   */
  deleteDiscoveryFrame(
    body: ApiDeleteDiscoveryFrameRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteDiscoveryFrame200Response>(
      '/v2/discovery-frame',
      {
        headers,
        endpointName: 'deleteDiscoveryFrame',
        body,
      },
    );
  }

  /**
   * Delete a recast.
   */
  deleteRecast(
    body: ApiDeleteRecastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteRecast200Response>('/v2/recasts', {
      headers,
      endpointName: 'deleteRecast',
      body,
    });
  }

  /**
   * Delete direct cast message
   */
  deleteDirectCastMessage(
    body: ApiDeleteDirectCastMessageRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteDirectCastMessage200Response>(
      '/v2/delete-message',
      {
        headers,
        endpointName: 'deleteDirectCastMessage',
        body,
      },
    );
  }

  /**
   * Delete information about the specified device.
   */
  unregisterDevice(
    body: ApiUnregisterDeviceRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiUnregisterDevice200Response>('/v2/devices', {
      headers,
      endpointName: 'unregisterDevice',
      body,
    });
  }

  /**
   * Delete uploaded video
   */
  abandonVideoUpload(
    body: ApiAbandonVideoUploadRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiAbandonVideoUpload200Response>('/v1/uploaded-video', {
      headers,
      endpointName: 'abandonVideoUpload',
      body,
    });
  }

  /**
   * Deletes a cast.
   */
  deleteCast(
    body: ApiDeleteCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteCast200Response>('/v2/casts', {
      headers,
      endpointName: 'deleteCast',
      body,
    });
  }

  /**
   * Deletes a user's contacts.
   */
  deleteContacts({ headers }: { headers?: RequestHeaders } = {}) {
    return this.delete<ApiDeleteContacts200Response>('/v2/contacts', {
      headers,
      endpointName: 'deleteContacts',
      body: {},
    });
  }

  /**
   * Deletes an API key.
   */
  deleteApiKey(
    body: ApiDeleteApiKeyRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteApiKey200Response>('/v2/api-keys', {
      headers,
      endpointName: 'deleteApiKey',
      body,
    });
  }

  /**
   * Deletes an action from a users actions
   */
  deleteUserCastAction(
    body: ApiDeleteUserCastActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteUserCastAction200Response>(
      '/v2/user-cast-actions',
      {
        headers,
        endpointName: 'deleteUserCastAction',
        body,
      },
    );
  }

  /**
   * Deletes the synchronization channel.
   */
  deleteSyncChannel(
    params: ApiDeleteSyncChannelQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteSyncChannel200Response>('/v2/sync-channel', {
      headers,
      endpointName: 'deleteSyncChannel',
      params,
    });
  }

  /**
   * Deny a recovery, providing a signature representing an admin's rejection of the recovery.
   */
  denyRecoveryTransaction(
    body: ApiDenyRecoveryTransactionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiDenyRecoveryTransaction200Response>(
      '/v2/deny-recovery-transaction',
      {
        headers,
        endpointName: 'denyRecoveryTransaction',
        body,
      },
    );
  }

  /**
   * Disable notifications on link cast activity.
   */
  disableChannelNotifications(
    body: ApiDisableChannelNotificationsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDisableChannelNotifications200Response>(
      '/v2/channel-notifications',
      {
        headers,
        endpointName: 'disableChannelNotifications',
        body,
      },
    );
  }

  /**
   * Disable notifications on link cast activity.
   */
  disableLinkNotifications(
    body: ApiDisableLinkNotificationsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDisableLinkNotifications200Response>(
      '/v2/link-notifications',
      {
        headers,
        endpointName: 'disableLinkNotifications',
        body,
      },
    );
  }

  /**
   * Discards a draft cast
   */
  discardDraftCast(
    body: ApiDiscardDraftCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDiscardDraftCast200Response>('/v2/draft-casts', {
      headers,
      endpointName: 'discardDraftCast',
      body,
    });
  }

  /**
   * Dismiss suggested users
   */
  dismissSuggestedUsers(
    body: ApiDismissSuggestedUsersRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiDismissSuggestedUsers200Response>(
      '/v1/dismiss-suggested-users',
      {
        headers,
        endpointName: 'dismissSuggestedUsers',
        body,
      },
    );
  }

  /**
   * Downvote casts
   */
  downvoteCast(
    body: ApiDownvoteCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiDownvoteCast200Response>('/v2/debug-cast-embeds', {
      headers,
      endpointName: 'downvoteCast',
      body,
    });
  }

  /**
   * Enable notifications on link cast activity.
   */
  enableChannelNotifications(
    body: ApiEnableChannelNotificationsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiEnableChannelNotifications200Response>(
      '/v2/channel-notifications',
      {
        headers,
        endpointName: 'enableChannelNotifications',
        body,
      },
    );
  }

  /**
   * Enable notifications on link cast activity.
   */
  enableLinkNotifications(
    body: ApiEnableLinkNotificationsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiEnableLinkNotifications200Response>(
      '/v2/link-notifications',
      {
        headers,
        endpointName: 'enableLinkNotifications',
        body,
      },
    );
  }

  /**
   * Enables a cast action
   */
  enableCastAction(
    body: ApiEnableCastActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiEnableCastAction200Response>('/v2/enable-cast-action', {
      headers,
      endpointName: 'enableCastAction',
      body,
    });
  }

  /**
   * Establish or update an E2EE synchronization channel between devices.
   */
  updateSyncChannel(
    body: ApiUpdateSyncChannelRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiUpdateSyncChannel200Response>('/v2/sync-channel', {
      headers,
      endpointName: 'updateSyncChannel',
      body,
    });
  }

  /**
   * Execute a post action on a frame
   */
  postFrameAction(
    body: ApiPostFrameActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostFrameAction200Response>('/v2/frame-action', {
      headers,
      endpointName: 'postFrameAction',
      body,
    });
  }

  /**
   * Execute a post action on a frame
   */
  postFrameJSON(
    body: ApiPostFrameJSONRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostFrameJSON200Response>('/v2/frame-json', {
      headers,
      endpointName: 'postFrameJSON',
      body,
    });
  }

  /**
   * Execute a post_redirect action on a frame
   */
  postFrameRedirect(
    body: ApiPostFrameRedirectRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostFrameRedirect200Response>('/v2/frame-redirect', {
      headers,
      endpointName: 'postFrameRedirect',
      body,
    });
  }

  /**
   * Execute a transaction action on a frame
   */
  postFrameTransaction(
    body: ApiPostFrameTransactionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostFrameTransaction200Response>(
      '/v2/frame-transaction',
      {
        headers,
        endpointName: 'postFrameTransaction',
        body,
      },
    );
  }

  /**
   * Favorite a feed
   */
  addFavoriteFeed(
    body: ApiAddFavoriteFeedRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAddFavoriteFeed200Response>('/v2/favorite-feeds', {
      headers,
      endpointName: 'addFavoriteFeed',
      body,
    });
  }

  /**
   * Fetch information about a key transaction
   */
  getKeyTransaction(
    params: ApiGetKeyTransactionQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetKeyTransaction200Response>(
      '/v2/key-transaction',
      {
        headers,
        endpointName: 'getKeyTransaction',
        params,
      },
    );
  }

  /**
   * Fetch possible metadata for direct cast message.
   */
  processDirectCastMessageMetadata(
    body: ApiProcessDirectCastMessageMetadataRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiProcessDirectCastMessageMetadata200Response>(
      '/v2/direct-cast-message-metadata',
      {
        headers,
        endpointName: 'processDirectCastMessageMetadata',
        body,
      },
    );
  }

  /**
   * Fetch status of the mint workflow.
   */
  getMintStatus(
    params: ApiGetMintStatusQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetMintStatus200Response>('/v2/mint-status', {
      headers,
      endpointName: 'getMintStatus',
      params,
    });
  }

  /**
   * Finalize in-app purchase flow for custody-token auth user.
   */
  finishInAppPurchaseWithCustody(
    body: ApiFinishInAppPurchaseWithCustodyRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFinishInAppPurchaseWithCustody200Response>(
      '/v2/finish-in-app-purchase-with-custody',
      {
        headers,
        endpointName: 'finishInAppPurchaseWithCustody',
        body,
      },
    );
  }

  /**
   * Follow a channel
   */
  fcFollowChannel(
    body: ApiFcFollowChannelRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFcFollowChannel200Response>('/fc/channel-follows', {
      headers,
      endpointName: 'fcFollowChannel',
      body,
    });
  }

  /**
   * Follow a feed.
   */
  createFeedFollow(
    body: ApiCreateFeedFollowRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCreateFeedFollow200Response>('/v2/feed-follows', {
      headers,
      endpointName: 'createFeedFollow',
      body,
    });
  }

  /**
   * Follow a user.
   */
  createFollow(
    body: ApiCreateFollowRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCreateFollow200Response>('/v2/follows', {
      headers,
      endpointName: 'createFollow',
      body,
    });
  }

  /**
   * Force add a moderator to a channel as an admin (skips invites)
   */
  adminAddChannelModerator(
    body: ApiAdminAddChannelModeratorRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAdminAddChannelModerator200Response>(
      '/v1/admin-add-channel-moderator',
      {
        headers,
        endpointName: 'adminAddChannelModerator',
        body,
      },
    );
  }

  /**
   * Force change channel owner as an admin (skips invites)
   */
  adminChangeChannelOwner(
    body: ApiAdminChangeChannelOwnerRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAdminChangeChannelOwner200Response>(
      '/v1/admin-transfer-channel-ownership',
      {
        headers,
        endpointName: 'adminChangeChannelOwner',
        body,
      },
    );
  }

  /**
   * Generate a SIWE nonce to be used for registration
   */
  getSiweNonce({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetSiweNonce200Response>('/v2/get-siwe-nonce', {
      headers,
      endpointName: 'getSiweNonce',
    });
  }

  /**
   * Generate a unique URL that can be used to upload an image directly to Cloudflare.
   */
  generateImageUploadUrl({ headers }: { headers?: RequestHeaders } = {}) {
    return this.post<ApiGenerateImageUploadUrl201Response>(
      '/v1/generate-image-upload-url',
      {
        headers,
        endpointName: 'generateImageUploadUrl',
        body: {},
      },
    );
  }

  /**
   * Generates an API key.
   */
  createApiKey(
    body: ApiCreateApiKeyRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCreateApiKey200Response>('/v2/api-keys', {
      headers,
      endpointName: 'createApiKey',
      body,
    });
  }

  /**
   * Generates an OpenGraph image
   */
  generateOpenGraphImage(
    params: ApiGenerateOpenGraphImageQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/v2/og-image', {
      headers,
      endpointName: 'generateOpenGraphImage',
      params,
    });
  }

  /**
   * Generates an authentication token.
   */
  createAuthToken(
    body: ApiCreateAuthTokenRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCreateAuthToken200Response>('/v2/auth', {
      headers,
      endpointName: 'createAuthToken',
      body,
    });
  }

  /**
   * Generates an default image for a channel
   */
  generateChannelImage(
    params: ApiGenerateChannelImageQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/v2/channel-image', {
      headers,
      endpointName: 'generateChannelImage',
      params,
    });
  }

  /**
   * Generates an niceley formatted cast image for sharing
   */
  generateCastShareableImage(
    params: ApiGenerateCastShareableImageQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/v2/cast-image', {
      headers,
      endpointName: 'generateCastShareableImage',
      params,
    });
  }

  /**
   * Generates an nicely formatted image for invite links
   */
  generateInviteOpenGraphImage(
    params: ApiGenerateInviteOpenGraphImageQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/v2/invite-image', {
      headers,
      endpointName: 'generateInviteOpenGraphImage',
      params,
    });
  }

  /**
   * Get Farcasters matched through uploaded contacts
   */
  getContactsUsers(
    params: ApiGetContactsUsersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetContactsUsers200Response>(
      '/v2/contacts-users',
      {
        headers,
        endpointName: 'getContactsUsers',
        params,
      },
    );
  }

  /**
   * Get TTL for direct casts authenticated user is a part of. (deprecated)
   */
  getDirectCastTTLs({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetDirectCastTTLs200Response>(
      '/v2/direct-cast-ttls',
      {
        headers,
        endpointName: 'getDirectCastTTLs',
      },
    );
  }

  /**
   * Get Warpcast SKU offering for various onchain transactions.
   */
  getOffering(
    params: ApiGetOfferingQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetOffering200Response>('/v2/offering', {
      headers,
      endpointName: 'getOffering',
      params,
    });
  }

  /**
   * Get Warpcast in-app minting warps offering. (deprecated)
   */
  getGeneralizedInAppMintsWarpsOffering(
    params: ApiGetGeneralizedInAppMintsWarpsOfferingQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetGeneralizedInAppMintsWarpsOffering200Response>(
      '/v2/generalized-in-app-mints-warps-offering',
      {
        headers,
        endpointName: 'getGeneralizedInAppMintsWarpsOffering',
        params,
      },
    );
  }

  /**
   * Get Warpcast in-app minting warps offering. (deprecated)
   */
  getInAppMintsWarpsOffering(
    params: ApiGetInAppMintsWarpsOfferingQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetInAppMintsWarpsOffering200Response>(
      '/v2/in-app-mints-warps-offering',
      {
        headers,
        endpointName: 'getInAppMintsWarpsOffering',
        params,
      },
    );
  }

  /**
   * Get Warpcast mint with warps eligibility and offering.
   */
  getMintWithWarpsOffering(
    params: ApiGetMintWithWarpsOfferingQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetMintWithWarpsOffering200Response>(
      '/v2/mint-with-warps-offering',
      {
        headers,
        endpointName: 'getMintWithWarpsOffering',
        params,
      },
    );
  }

  /**
   * Get Warpcast product catalog and all offerings.
   */
  getProductCatalog({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetProductCatalog200Response>(
      '/v2/product-catalog',
      {
        headers,
        endpointName: 'getProductCatalog',
      },
    );
  }

  /**
   * Get Warpcast rent storage offering SKUs
   */
  getRentStorageOfferings({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetRentStorageOfferings200Response>(
      '/v2/rent-storage-offerings',
      {
        headers,
        endpointName: 'getRentStorageOfferings',
      },
    );
  }

  /**
   * Get Warpcast warps offering for signers feature specifically.
   */
  getWarpsOffering(
    params: ApiGetWarpsOfferingQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetWarpsOffering200Response>(
      '/v2/warps-offering',
      {
        headers,
        endpointName: 'getWarpsOffering',
        params,
      },
    );
  }

  /**
   * Get X auth link to redirect authenticated user
   */
  getXAuthLink({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetXAuthLink200Response>('/v2/get-x-auth-link', {
      headers,
      endpointName: 'getXAuthLink',
    });
  }

  /**
   * Get a direct cast conversation.
   */
  fcGetConversation(
    params: ApiFcGetConversationQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetConversation200Response>('/fc/conversation', {
      headers,
      endpointName: 'fcGetConversation',
      params,
    });
  }

  /**
   * Get a direct cast group's invites.
   */
  fcGetGroupInvites(
    params: ApiFcGetGroupInvitesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetGroupInvites200Response>(
      '/fc/group-invites',
      {
        headers,
        endpointName: 'fcGetGroupInvites',
        params,
      },
    );
  }

  /**
   * Get a direct cast group's members.
   */
  fcGetGroupMembers(
    params: ApiFcGetGroupMembersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetGroupMembers200Response>(
      '/fc/group-members',
      {
        headers,
        endpointName: 'fcGetGroupMembers',
        params,
      },
    );
  }

  /**
   * Get a direct cast group.
   */
  fcGetGroup(
    params: ApiFcGetGroupQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetGroup200Response>('/fc/group', {
      headers,
      endpointName: 'fcGetGroup',
      params,
    });
  }

  /**
   * Get a direct cast message.
   */
  fcGetMessage(
    params: ApiFcGetMessageQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetMessage200Response>('/fc/message', {
      headers,
      endpointName: 'fcGetMessage',
      params,
    });
  }

  /**
   * Get a list of direct cast conversations.
   */
  fcGetConversationList(
    params: ApiFcGetConversationListQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetConversationList200Response>(
      '/fc/conversation-list',
      {
        headers,
        endpointName: 'fcGetConversationList',
        params,
      },
    );
  }

  /**
   * Get a list of direct cast groups.
   */
  fcGetGroupList(
    params: ApiFcGetGroupListQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetGroupList200Response>('/fc/group-list', {
      headers,
      endpointName: 'fcGetGroupList',
      params,
    });
  }

  /**
   * Get a list of direct cast messages.
   */
  fcGetMessageList(
    params: ApiFcGetMessageListQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetMessageList200Response>('/fc/message-list', {
      headers,
      endpointName: 'fcGetMessageList',
      params,
    });
  }

  /**
   * Get a list of the actors for a specified notification group.
   */
  getNotificationActorsInGroup(
    params: ApiGetNotificationActorsInGroupQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetNotificationActorsInGroup200Response>(
      '/v2/notification-group-actors',
      {
        headers,
        endpointName: 'getNotificationActorsInGroup',
        params,
      },
    );
  }

  /**
   * Get a recovery
   */
  getRecovery(
    params: ApiGetRecoveryQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetRecovery200Response>('/v2/recoveries', {
      headers,
      endpointName: 'getRecovery',
      params,
    });
  }

  /**
   * Get a signed key request.
   */
  getSignedKeyRequest(
    params: ApiGetSignedKeyRequestQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetSignedKeyRequest200Response>(
      '/v2/signed-key-request',
      {
        headers,
        endpointName: 'getSignedKeyRequest',
        params,
      },
    );
  }

  /**
   * Get a video
   */
  getVideo({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<void>('/v1/video/:uid.m3u8', {
      headers,
      endpointName: 'getVideo',
    });
  }

  /**
   * Get all API keys for a user.
   */
  getApiKeys({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetApiKeys200Response>('/v2/api-keys', {
      headers,
      endpointName: 'getApiKeys',
    });
  }

  /**
   * Get all asset events related to the given collection.
   */
  getCollectionActivity(
    params: ApiGetCollectionActivityQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCollectionActivity200Response>(
      '/v2/collection-activity',
      {
        headers,
        endpointName: 'getCollectionActivity',
        params,
      },
    );
  }

  /**
   * Get all available addresses we can mint an asset for the authed user.
   */
  getAvailableWalletsToMintTo({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetAvailableWalletsToMintTo200Response>(
      '/v2/available-wallets-to-mint-to',
      {
        headers,
        endpointName: 'getAvailableWalletsToMintTo',
      },
    );
  }

  /**
   * Get all available usernames for the authenticated user
   */
  getUserUsernames({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetUserUsernames200Response>(
      '/v2/user-usernames',
      {
        headers,
        endpointName: 'getUserUsernames',
      },
    );
  }

  /**
   * Get all bookmarked casts.
   */
  getBookmarkedCasts(
    params: ApiGetBookmarkedCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetBookmarkedCasts200Response>(
      '/v2/bookmarked-casts',
      {
        headers,
        endpointName: 'getBookmarkedCasts',
        params,
      },
    );
  }

  /**
   * Get all casts in reverse chronological order.
   */
  getAllRecentCasts(
    params: ApiGetAllRecentCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetAllRecentCasts200Response>('/v2/recent-casts', {
      headers,
      endpointName: 'getAllRecentCasts',
      params,
    });
  }

  /**
   * Get all collections owned by the specified user.
   */
  getCollectionsOwnedByUser(
    params: ApiGetCollectionsOwnedByUserQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCollectionsOwnedByUser200Response>(
      '/v2/user-collections',
      {
        headers,
        endpointName: 'getCollectionsOwnedByUser',
        params,
      },
    );
  }

  /**
   * Get all connected accounts for authenticated user
   */
  getConnectedAccounts(
    params: ApiGetConnectedAccountsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetConnectedAccounts200Response>(
      '/v2/connected-accounts',
      {
        headers,
        endpointName: 'getConnectedAccounts',
        params,
      },
    );
  }

  /**
   * Get all direct cast users the authenticated user can start a conversation with.
   */
  getDirectCastUsers(
    params: ApiGetDirectCastUsersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastUsers200Response>(
      '/v2/direct-cast-users',
      {
        headers,
        endpointName: 'getDirectCastUsers',
        params,
      },
    );
  }

  /**
   * Get all like reactions for a cast.
   */
  getCastLikes(
    params: ApiGetCastLikesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCastLikes200Response>('/v2/cast-likes', {
      headers,
      endpointName: 'getCastLikes',
      params,
    });
  }

  /**
   * Get all of casts liked by user.
   */
  getUserLikedCasts(
    params: ApiGetUserLikedCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserLikedCasts200Response>(
      '/v2/user-liked-casts',
      {
        headers,
        endpointName: 'getUserLikedCasts',
        params,
      },
    );
  }

  /**
   * Get all possible interests to showcase to user during onboarding
   */
  getOnboardingInterests(
    params: ApiGetOnboardingInterestsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetOnboardingInterests200Response>(
      '/v2/onboarding-interests',
      {
        headers,
        endpointName: 'getOnboardingInterests',
        params,
      },
    );
  }

  /**
   * Get all preferences for the authenticated user.
   */
  getUserPreferences({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetUserPreferences200Response>(
      '/v2/user-preferences',
      {
        headers,
        endpointName: 'getUserPreferences',
      },
    );
  }

  /**
   * Get all quotes of a cast.
   */
  getCastQuotes(
    params: ApiGetCastQuotesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCastQuotes200Response>('/v2/cast-quotes', {
      headers,
      endpointName: 'getCastQuotes',
      params,
    });
  }

  /**
   * Get all reactions (both likes and recasts) for a cast.
   */
  getCastReactions(
    params: ApiGetCastReactionsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCastReactions200Response>(
      '/v2/cast-reactions',
      {
        headers,
        endpointName: 'getCastReactions',
        params,
      },
    );
  }

  /**
   * Get all signers for a user.
   */
  getSigners(
    params: ApiGetSignersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetSigners200Response>('/v2/signers', {
      headers,
      endpointName: 'getSigners',
      params,
    });
  }

  /**
   * Get all the viewed casts for a user that are stored in Redis.
   */
  getUserViewedCasts(
    params: ApiGetUserViewedCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserViewedCasts200Response>(
      '/v2/user-viewed-casts',
      {
        headers,
        endpointName: 'getUserViewedCasts',
        params,
      },
    );
  }

  /**
   * Get all users blocked from using the app
   */
  getAppBlockedUsers({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetAppBlockedUsers200Response>(
      '/v1/app-blocked-users',
      {
        headers,
        endpointName: 'getAppBlockedUsers',
      },
    );
  }

  /**
   * Get all users that follow the specified channel that the viewer follows.
   */
  getChannelFollowersYouKnow(
    params: ApiGetChannelFollowersYouKnowQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelFollowersYouKnow200Response>(
      '/v2/channel-followers-you-know',
      {
        headers,
        endpointName: 'getChannelFollowersYouKnow',
        params,
      },
    );
  }

  /**
   * Get all users that follow the specified channel.
   */
  getChannelFollowers(
    params: ApiGetChannelFollowersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelFollowers200Response>(
      '/v2/channel-followers',
      {
        headers,
        endpointName: 'getChannelFollowers',
        params,
      },
    );
  }

  /**
   * Get all users that follow the specified user that viewer knows.
   */
  getFollowersYouKnow(
    params: ApiGetFollowersYouKnowQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetFollowersYouKnow200Response>(
      '/v2/followers-you-know',
      {
        headers,
        endpointName: 'getFollowersYouKnow',
        params,
      },
    );
  }

  /**
   * Get all users that follow the specified user.
   */
  getFollowers(
    params: ApiGetFollowersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetFollowers200Response>('/v2/followers', {
      headers,
      endpointName: 'getFollowers',
      params,
    });
  }

  /**
   * Get all users the specified user is following.
   */
  getFollowing(
    params: ApiGetFollowingQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetFollowing200Response>('/v2/following', {
      headers,
      endpointName: 'getFollowing',
      params,
    });
  }

  /**
   * Get all users who own at least one asset from the specified collection.
   */
  getCollectionOwners(
    params: ApiGetCollectionOwnersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCollectionOwners200Response>(
      '/v2/collection-owners',
      {
        headers,
        endpointName: 'getCollectionOwners',
        params,
      },
    );
  }

  /**
   * Get all users who recasted a cast.
   */
  getCastRecasters(
    params: ApiGetCastRecastersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCastRecasters200Response>(
      '/v2/cast-recasters',
      {
        headers,
        endpointName: 'getCastRecasters',
        params,
      },
    );
  }

  /**
   * Get all users with a particular profile location.
   */
  getUsersByLocation(
    params: ApiGetUsersByLocationQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUsersByLocation200Response>(
      '/v2/location-users',
      {
        headers,
        endpointName: 'getUsersByLocation',
        params,
      },
    );
  }

  /**
   * Get all verifications for the specified user.
   */
  getVerifications(
    params: ApiGetVerificationsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetVerifications200Response>('/v2/verifications', {
      headers,
      endpointName: 'getVerifications',
      params,
    });
  }

  /**
   * Get all watched casts.
   */
  getWatchedCasts(
    params: ApiGetWatchedCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetWatchedCasts200Response>('/v2/watched-casts', {
      headers,
      endpointName: 'getWatchedCasts',
      params,
    });
  }

  /**
   * Get application context for the current user.
   */
  getUserAppContext({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetUserAppContext200Response>(
      '/v2/user-app-context',
      {
        headers,
        endpointName: 'getUserAppContext',
      },
    );
  }

  /**
   * Get assets grouped by collection.
   */
  getGroupedAssetsOwnedByUser(
    params: ApiGetGroupedAssetsOwnedByUserQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetGroupedAssetsOwnedByUser200Response>(
      '/v2/grouped-assets',
      {
        headers,
        endpointName: 'getGroupedAssetsOwnedByUser',
        params,
      },
    );
  }

  /**
   * Get autocomplete predictions for a location query.
   */
  findLocation(
    params: ApiFindLocationQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFindLocation200Response>('/v2/find-location', {
      headers,
      endpointName: 'findLocation',
      params,
    });
  }

  /**
   * Get balance of warps
   */
  getWarpsBalance(
    params: ApiGetWarpsBalanceQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetWarpsBalance200Response>('/v2/warps-balance', {
      headers,
      endpointName: 'getWarpsBalance',
      params,
    });
  }

  /**
   * Get cast moderations
   */
  fcGetModeratedCasts(
    params: ApiFcGetModeratedCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetModeratedCasts200Response>(
      '/fc/moderated-casts',
      {
        headers,
        endpointName: 'fcGetModeratedCasts',
        params,
      },
    );
  }

  /**
   * Get casts corresponding to the specified thread given by the cast hash prefix and username, filtered for relevancy.
   */
  getUserThreadCasts(
    params: ApiGetUserThreadCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserThreadCasts200Response>(
      '/v2/user-thread-casts',
      {
        headers,
        endpointName: 'getUserThreadCasts',
        params,
      },
    );
  }

  /**
   * Get casts corresponding to the specified thread, filtered for relevancy.
   */
  getThread(
    params: ApiGetThreadQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetThread200Response>('/v2/thread-casts', {
      headers,
      endpointName: 'getThread',
      params,
    });
  }

  /**
   * Get channel suggestions during cast compose.
   */
  getComposerChannelSuggestions(
    params: ApiGetComposerChannelSuggestionsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetComposerChannelSuggestions200Response>(
      '/v2/composer-channel-suggestions',
      {
        headers,
        endpointName: 'getComposerChannelSuggestions',
        params,
      },
    );
  }

  /**
   * Get combined unseen counts for notifications, direct casts, warps & invites.
   */
  getUnseen({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetUnseen200Response>('/v2/unseen', {
      headers,
      endpointName: 'getUnseen',
    });
  }

  /**
   * Get details about a collection of assets.
   */
  getCollection(
    params: ApiGetCollectionQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCollection200Response>('/v2/collection', {
      headers,
      endpointName: 'getCollection',
      params,
    });
  }

  /**
   * Get details about all non-following channels, and include a summary of the followed ones.
   */
  discoverChannels(
    params: ApiDiscoverChannelsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiDiscoverChannels200Response>(
      '/v2/discover-channels',
      {
        headers,
        endpointName: 'discoverChannels',
        params,
      },
    );
  }

  /**
   * Get details for an existing invite.
   */
  getInvite(
    params: ApiGetInviteQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetInvite200Response>('/v2/invite', {
      headers,
      endpointName: 'getInvite',
      params,
    });
  }

  /**
   * Get direct cast conversation (if authed user has access)
   */
  getDirectCastConversation(
    params: ApiGetDirectCastConversationQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversation200Response>(
      '/v2/direct-cast-conversation',
      {
        headers,
        endpointName: 'getDirectCastConversation',
        params,
      },
    );
  }

  /**
   * Get extra details about a channel
   */
  getChannelDetails(
    params: ApiGetChannelDetailsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelDetails200Response>(
      '/v1/channel-details',
      {
        headers,
        endpointName: 'getChannelDetails',
        params,
      },
    );
  }

  /**
   * Get feed items, excluding ones the client already has
   */
  getFeedItems(
    body: ApiGetFeedItemsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiGetFeedItems200Response>('/v2/feed-items', {
      headers,
      endpointName: 'getFeedItems',
      body,
    });
  }

  /**
   * Get feed of all asset-related events relevant to the authenticated user..
   */
  getAssetEventsFeed(
    params: ApiGetAssetEventsFeedQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetAssetEventsFeed200Response>(
      '/v2/asset-events-feed',
      {
        headers,
        endpointName: 'getAssetEventsFeed',
        params,
      },
    );
  }

  /**
   * Get flag indicating whether invites page has been viewed since nudge indicator shown
   */
  getInvitesViewed({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetInvitesViewed200Response>(
      '/v2/invites-viewed',
      {
        headers,
        endpointName: 'getInvitesViewed',
      },
    );
  }

  /**
   * Get flag indicating whether invites page has been viewed since nudge indicator shown
   */
  setInvitesViewed({ headers }: { headers?: RequestHeaders } = {}) {
    return this.post<ApiSetInvitesViewed200Response>('/v2/invites-viewed', {
      headers,
      endpointName: 'setInvitesViewed',
      body: {},
    });
  }

  /**
   * Get frame blocklist
   */
  getFrameBlocklist({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetFrameBlocklist200Response>(
      '/v2/frame-blocklist',
      {
        headers,
        endpointName: 'getFrameBlocklist',
      },
    );
  }

  /**
   * Get global frame analytics data
   */
  getGlobalFrameAnalytics(
    params: ApiGetGlobalFrameAnalyticsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetGlobalFrameAnalytics200Response>(
      '/v2/global-frame-analytics',
      {
        headers,
        endpointName: 'getGlobalFrameAnalytics',
        params,
      },
    );
  }

  /**
   * Get hidden replies for a thread of a focused cast
   */
  getUserThreadHiddenReplies(
    params: ApiGetUserThreadHiddenRepliesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserThreadHiddenReplies200Response>(
      '/v1/user-thread-hidden-replies',
      {
        headers,
        endpointName: 'getUserThreadHiddenReplies',
        params,
      },
    );
  }

  /**
   * Get hosts for a channel
   */
  getChannelHosts(
    params: ApiGetChannelHostsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelHosts200Response>(
      '/v2/get-channel-hosts',
      {
        headers,
        endpointName: 'getChannelHosts',
        params,
      },
    );
  }

  /**
   * Get information about a Farcaster action
   */
  getFarcasterAction(
    params: ApiGetFarcasterActionQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetFarcasterAction200Response>(
      '/v2/farcaster-action',
      {
        headers,
        endpointName: 'getFarcasterAction',
        params,
      },
    );
  }

  /**
   * Get information about a Farcaster composer action
   */
  getComposerAction(
    params: ApiGetComposerActionQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetComposerAction200Response>(
      '/v2/composer-action',
      {
        headers,
        endpointName: 'getComposerAction',
        params,
      },
    );
  }

  /**
   * Get information about a Farcaster username (fname).
   */
  getFname(
    params: ApiGetFnameQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetFname200Response>('/v2/fname', {
      headers,
      endpointName: 'getFname',
      params,
    });
  }

  /**
   * Get information about a discovery app
   */
  getDiscoveryApp(
    params: ApiGetDiscoveryAppQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDiscoveryApp200Response>('/v2/discovery-app', {
      headers,
      endpointName: 'getDiscoveryApp',
      params,
    });
  }

  /**
   * Get information about a discovery frame
   */
  getDiscoveryFrame(
    params: ApiGetDiscoveryFrameQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDiscoveryFrame200Response>(
      '/v2/discovery-frame',
      {
        headers,
        endpointName: 'getDiscoveryFrame',
        params,
      },
    );
  }

  /**
   * Get information about creating a channel, including cost
   */
  getChannelCreationInfo({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetChannelCreationInfo200Response>(
      '/v2/channel-creation-info',
      {
        headers,
        endpointName: 'getChannelCreationInfo',
      },
    );
  }

  /**
   * Get information about the AMA
   */
  getAMA(
    params: ApiGetAMAQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetAMA200Response>('/v2/ama', {
      headers,
      endpointName: 'getAMA',
      params,
    });
  }

  /**
   * Get invites for a channel
   */
  fcGetChannelInvites(
    params: ApiFcGetChannelInvitesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetChannelInvites200Response>(
      '/fc/channel-invites',
      {
        headers,
        endpointName: 'fcGetChannelInvites',
        params,
      },
    );
  }

  /**
   * Get least interacted with users, user is following.
   */
  getLeastInteractedWithFollowing(
    params: ApiGetLeastInteractedWithFollowingQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetLeastInteractedWithFollowing200Response>(
      '/v2/least-interacted-with-following',
      {
        headers,
        endpointName: 'getLeastInteractedWithFollowing',
        params,
      },
    );
  }

  /**
   * Get list of channel in which the user can cast a root cast
   */
  getUserCastableChannels(
    params: ApiGetUserCastableChannelsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserCastableChannels200Response>(
      '/v2/user-following-channels',
      {
        headers,
        endpointName: 'getUserCastableChannels',
        params,
      },
    );
  }

  /**
   * Get list of channels user follow, is a member or moderate.
   */
  getUserChannels(
    params: ApiGetUserChannelsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserChannels200Response>('/v2/user-channels', {
      headers,
      endpointName: 'getUserChannels',
      params,
    });
  }

  /**
   * Get members of a channel
   */
  fcGetChannelMembers(
    params: ApiFcGetChannelMembersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetChannelMembers200Response>(
      '/fc/channel-members',
      {
        headers,
        endpointName: 'fcGetChannelMembers',
        params,
      },
    );
  }

  /**
   * Get message hashes needed to perform a registration on behalf an address.
   */
  generateRegistrationHashes(
    body: ApiGenerateRegistrationHashesRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiGenerateRegistrationHashes200Response>(
      '/v2/registration-hashes',
      {
        headers,
        endpointName: 'generateRegistrationHashes',
        body,
      },
    );
  }

  /**
   * Get metadata about a channel when editing it
   */
  getChannelSettings(
    params: ApiGetChannelSettingsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelSettings200Response>(
      '/v2/channels-owned',
      {
        headers,
        endpointName: 'getChannelSettings',
        params,
      },
    );
  }

  /**
   * Get metadata about channel.
   */
  getChannel(
    params: ApiGetChannelQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannel200Response>('/v2/channel', {
      headers,
      endpointName: 'getChannel',
      params,
    });
  }

  /**
   * Get muted keywords for a user
   */
  getMutedKeywords({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetMutedKeywords200Response>(
      '/v2/get-muted-keywords',
      {
        headers,
        endpointName: 'getMutedKeywords',
      },
    );
  }

  /**
   * Get notifications for a given tab
   */
  getNotificationsForTab(
    params: ApiGetNotificationsForTabQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetNotificationsForTab200Response>(
      '/v1/notifications-for-tab',
      {
        headers,
        endpointName: 'getNotificationsForTab',
        params,
      },
    );
  }

  /**
   * Get notifications within the specified group.
   */
  getNotificationsInGroup(
    params: ApiGetNotificationsInGroupQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetNotificationsInGroup200Response>(
      '/v2/notification-group',
      {
        headers,
        endpointName: 'getNotificationsInGroup',
        params,
      },
    );
  }

  /**
   * Get paid invite warps offering.
   */
  getInviteWithWarpsOffering({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetInviteWithWarpsOffering200Response>(
      '/v2/invite-with-warps-offering',
      {
        headers,
        endpointName: 'getInviteWithWarpsOffering',
      },
    );
  }

  /**
   * Get pending admin reviews
   */
  getPendingAdminReviews(
    params: ApiGetPendingAdminReviewsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetPendingAdminReviews200Response>(
      '/v2/pending-admin-reviews',
      {
        headers,
        endpointName: 'getPendingAdminReviews',
        params,
      },
    );
  }

  /**
   * Get pending invites for a direct cast group.
   */
  getDirectCastGroupInvites(
    params: ApiGetDirectCastGroupInvitesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastGroupInvites200Response>(
      '/v2/direct-cast-group-invites',
      {
        headers,
        endpointName: 'getDirectCastGroupInvites',
        params,
      },
    );
  }

  /**
   * Get poll results
   */
  getPollResults(
    params: ApiGetPollResultsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetPollResults200Response>(
      '/v2/get-poll-results',
      {
        headers,
        endpointName: 'getPollResults',
        params,
      },
    );
  }

  /**
   * Get primary address for a user
   */
  getPrimaryAddress(
    params: ApiGetPrimaryAddressQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetPrimaryAddress200Response>(
      '/v2/get-primary-address',
      {
        headers,
        endpointName: 'getPrimaryAddress',
        params,
      },
    );
  }

  /**
   * Get public keys related to direct cast conversations (deprecated).
   */
  getDirectCastKeys(
    params: ApiGetDirectCastKeysQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastKeys200Response>(
      '/v2/direct-cast-keys',
      {
        headers,
        endpointName: 'getDirectCastKeys',
        params,
      },
    );
  }

  /**
   * Get public keys related to direct cast conversations. (deprecated)
   */
  getDirectCastKeysByAccount(
    params: ApiGetDirectCastKeysByAccountQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastKeysByAccount200Response>(
      '/v2/direct-cast-device-keys',
      {
        headers,
        endpointName: 'getDirectCastKeysByAccount',
        params,
      },
    );
  }

  /**
   * Get recommendations for channels to follow, and include a summary of the followed ones.
   */
  recommendedChannels(
    params: ApiRecommendedChannelsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiRecommendedChannels200Response>(
      '/v2/channel-recommendations',
      {
        headers,
        endpointName: 'recommendedChannels',
        params,
      },
    );
  }

  /**
   * Get recommended channels based on cast text
   */
  getChannelRecsForCast(
    params: ApiGetChannelRecsForCastQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelRecsForCast200Response>(
      '/v2/get-channel-recs-for-cast',
      {
        headers,
        endpointName: 'getChannelRecsForCast',
        params,
      },
    );
  }

  /**
   * Get recover EIP-712 message hash
   */
  getRecoverHash(
    params: ApiGetRecoverHashQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetRecoverHash200Response>('/v2/recover-hash', {
      headers,
      endpointName: 'getRecoverHash',
      params,
    });
  }

  /**
   * Get restricted users in a channel
   */
  fcGetChannelRestrictedUsers(
    params: ApiFcGetChannelRestrictedUsersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetChannelRestrictedUsers200Response>(
      '/fc/channel-restricted-users',
      {
        headers,
        endpointName: 'fcGetChannelRestrictedUsers',
        params,
      },
    );
  }

  /**
   * Get restricted users in a channel
   */
  fcGetChannelRestrictedUsersObsolete(
    params: ApiFcGetChannelRestrictedUsersObsoleteQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetChannelRestrictedUsersObsolete200Response>(
      '/fc/channel-banned-users',
      {
        headers,
        endpointName: 'fcGetChannelRestrictedUsersObsolete',
        params,
      },
    );
  }

  /**
   * Get storage utilization for a user
   */
  getStorageUtilization({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetStorageUtilization200Response>(
      '/v2/storage-utilization',
      {
        headers,
        endpointName: 'getStorageUtilization',
      },
    );
  }

  /**
   * Get suggested users for the authenticated user to follow.
   */
  getSuggestedUsers(
    params: ApiGetSuggestedUsersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetSuggestedUsers200Response>(
      '/v2/suggested-users',
      {
        headers,
        endpointName: 'getSuggestedUsers',
        params,
      },
    );
  }

  /**
   * Get the admin home feed
   */
  getAdminFeed(
    params: ApiGetAdminFeedQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetAdminFeed200Response>('/v2/admin-feed', {
      headers,
      endpointName: 'getAdminFeed',
      params,
    });
  }

  /**
   * Get the affinity scores between a user and the people they follow
   */
  getFollowedUsersAffinityScore(
    params: ApiGetFollowedUsersAffinityScoreQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetFollowedUsersAffinityScore200Response>(
      '/v1/followed-users-affinity-scores',
      {
        headers,
        endpointName: 'getFollowedUsersAffinityScore',
        params,
      },
    );
  }

  /**
   * Get the authenticated requester's available invites count.
   */
  getInvitesAvailable({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetInvitesAvailable200Response>(
      '/v2/invites-available',
      {
        headers,
        endpointName: 'getInvitesAvailable',
      },
    );
  }

  /**
   * Get the channels highlighted for the authenticated user
   */
  getHighlightedChannels({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetHighlightedChannels200Response>(
      '/v2/highlighted-channels',
      {
        headers,
        endpointName: 'getHighlightedChannels',
      },
    );
  }

  /**
   * Get the custody address associated with an FID.
   */
  getCustodyAddress(
    params: ApiGetCustodyAddressQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCustodyAddress200Response>(
      '/v2/custody-address',
      {
        headers,
        endpointName: 'getCustodyAddress',
        params,
      },
    );
  }

  /**
   * Get the feeds the authenticated user is following, including non-subscribable.
   */
  getFeedSummaries({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetFeedSummaries200Response>('/v2/feeds', {
      headers,
      endpointName: 'getFeedSummaries',
    });
  }

  /**
   * Get the history of home feed generations and casts included in the home feed
   */
  getHomeFeedGenerationHistory(
    params: ApiGetHomeFeedGenerationHistoryQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetHomeFeedGenerationHistory200Response>(
      '/v1/home-feed-history',
      {
        headers,
        endpointName: 'getHomeFeedGenerationHistory',
        params,
      },
    );
  }

  /**
   * Get the most active feeds for a user
   */
  getMostActiveChannels(
    params: ApiGetMostActiveChannelsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetMostActiveChannels200Response>(
      '/v2/most-active-channels',
      {
        headers,
        endpointName: 'getMostActiveChannels',
        params,
      },
    );
  }

  /**
   * Get the non-signed delegate signer add message hash managed by Merkle for the authenticated user.
   */
  getDelegateSigner({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetDelegateSigner200Response>(
      '/v2/delegate-signer',
      {
        headers,
        endpointName: 'getDelegateSigner',
      },
    );
  }

  /**
   * Get the number of page views per feed for a user
   */
  getFeedPageViews(
    params: ApiGetFeedPageViewsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetFeedPageViews200Response>(
      '/v2/feed-page-views',
      {
        headers,
        endpointName: 'getFeedPageViews',
        params,
      },
    );
  }

  /**
   * Get the replies to a conversation cast for interactive expansion
   */
  getConversationCastReplies(
    params: ApiGetConversationCastRepliesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetConversationCastReplies200Response>(
      '/v1/conversation-cast-replies',
      {
        headers,
        endpointName: 'getConversationCastReplies',
        params,
      },
    );
  }

  /**
   * Get the replies to a conversation cast for interactive expansion. Excludes replies by the focus cast author, followed users, and viewer since those should have been returned by the thread endpoint
   */
  getConversationCastRepliesObsolete(
    params: ApiGetConversationCastRepliesObsoleteQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetConversationCastRepliesObsolete200Response>(
      '/v2/conversation-cast-replies',
      {
        headers,
        endpointName: 'getConversationCastRepliesObsolete',
        params,
      },
    );
  }

  /**
   * Get the state of an onchain action
   */
  getOnchainAction(
    params: ApiGetOnchainActionQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetOnchainAction200Response>(
      '/v2/onchain-action',
      {
        headers,
        endpointName: 'getOnchainAction',
        params,
      },
    );
  }

  /**
   * Get the state of an uploaded video
   */
  getVideoState(
    params: ApiGetVideoStateQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetVideoState200Response>('/v1/uploaded-video', {
      headers,
      endpointName: 'getVideoState',
      params,
    });
  }

  /**
   * Get the value of all dynamic configs
   */
  getDynamicConfigs({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetDynamicConfigs200Response>(
      '/v1/dynamic-configs',
      {
        headers,
        endpointName: 'getDynamicConfigs',
      },
    );
  }

  /**
   * Get top casters for the channel.
   */
  getChannelTopCasters(
    params: ApiGetChannelTopCastersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelTopCasters200Response>(
      '/v2/channel-top-casters',
      {
        headers,
        endpointName: 'getChannelTopCasters',
        params,
      },
    );
  }

  /**
   * Get top inviters with score
   */
  getInvitersLeaderboard({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetInvitersLeaderboard200Response>(
      '/v2/inviters-leaderboard',
      {
        headers,
        endpointName: 'getInvitersLeaderboard',
      },
    );
  }

  /**
   * Get user account verifications attested by Warpcast
   */
  fcGetAccountVerifications(
    params: ApiFcGetAccountVerificationsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetAccountVerifications200Response>(
      '/fc/account-verifications',
      {
        headers,
        endpointName: 'fcGetAccountVerifications',
        params,
      },
    );
  }

  /**
   * Get whether the specified email address has already been invited.
   */
  getIsUserInvited(
    params: ApiGetIsUserInvitedQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetIsUserInvited200Response>('/v2/invites', {
      headers,
      endpointName: 'getIsUserInvited',
      params,
    });
  }

  /**
   * Gets a single cast for a specific user using a shortened hash.
   */
  getUserCast(
    params: ApiGetUserCastQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserCast200Response>('/v2/user-cast', {
      headers,
      endpointName: 'getUserCast',
      params,
    });
  }

  /**
   * Gets all asset-related events for the specified user.
   */
  getAssetEvents(
    params: ApiGetAssetEventsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetAssetEvents200Response>('/v2/asset-events', {
      headers,
      endpointName: 'getAssetEvents',
      params,
    });
  }

  /**
   * Gets all assets owned by a given user for a specific collection.
   */
  getCollectionAssetsOwnedByUser(
    params: ApiGetCollectionAssetsOwnedByUserQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetCollectionAssetsOwnedByUser200Response>(
      '/v2/collection-assets',
      {
        headers,
        endpointName: 'getCollectionAssetsOwnedByUser',
        params,
      },
    );
  }

  /**
   * Gets all casts (including replies and recasts) created by the specified user.
   */
  getUserCastsAndReplies(
    params: ApiGetUserCastsAndRepliesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserCastsAndReplies200Response>('/v2/casts', {
      headers,
      endpointName: 'getUserCastsAndReplies',
      params,
    });
  }

  /**
   * Gets an approval message hash for a recovery address change
   */
  getRecoveryAddressChangeHash(
    params: ApiGetRecoveryAddressChangeHashQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetRecoveryAddressChangeHash200Response>(
      '/v2/recovery-address-change-hash',
      {
        headers,
        endpointName: 'getRecoveryAddressChangeHash',
        params,
      },
    );
  }

  /**
   * Gets casts that are new conversations (i.e. are not replies or recasts) created by the specified user.
   */
  getUserCasts(
    params: ApiGetUserCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserCasts200Response>('/v2/profile-casts', {
      headers,
      endpointName: 'getUserCasts',
      params,
    });
  }

  /**
   * Gets details about the specified asset.
   */
  getAsset(
    params: ApiGetAssetQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetAsset200Response>('/v2/asset', {
      headers,
      endpointName: 'getAsset',
      params,
    });
  }

  /**
   * Gets details of a signer.
   */
  getSigner(
    params: ApiGetSignerQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetSigner200Response>('/v2/signer', {
      headers,
      endpointName: 'getSigner',
      params,
    });
  }

  /**
   * Gets details of a signer.
   */
  getSignerRemoveHash(
    params: ApiGetSignerRemoveHashQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetSignerRemoveHash200Response>(
      '/v2/signer-remove-hash',
      {
        headers,
        endpointName: 'getSignerRemoveHash',
        params,
      },
    );
  }

  /**
   * Gets information about a recovery address change
   */
  getRecoveryAddressChange(
    params: ApiGetRecoveryAddressChangeQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetRecoveryAddressChange200Response>(
      '/v2/recovery-address-changes',
      {
        headers,
        endpointName: 'getRecoveryAddressChange',
        params,
      },
    );
  }

  /**
   * Gets information about application feature flags for the authenticated user.
   */
  getClientConfig({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetClientConfig200Response>('/v2/client-config', {
      headers,
      endpointName: 'getClientConfig',
    });
  }

  /**
   * Gets invite info for a direct cast group.
   */
  getDirectCastGroupInviteV3(
    params: ApiGetDirectCastGroupInviteV3QueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastGroupInviteV3200Response>(
      '/v2/direct-cast-group-invite',
      {
        headers,
        endpointName: 'getDirectCastGroupInviteV3',
        params,
      },
    );
  }

  /**
   * Gets the current onboarding state for the authenticated user and creates an auth token if possible.,  * This is an optimization to reduce the number of requests the client needs to make during initial,  * onboarding.
   */
  getOnboardingStateAndAuthToken(
    body: ApiGetOnboardingStateAndAuthTokenRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiGetOnboardingStateAndAuthToken200Response>(
      '/v2/onboarding-state',
      {
        headers,
        endpointName: 'getOnboardingStateAndAuthToken',
        body,
      },
    );
  }

  /**
   * Gets the current onboarding state for the authenticated user.
   */
  getOnboardingState({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetOnboardingState200Response>(
      '/v2/onboarding-state',
      {
        headers,
        endpointName: 'getOnboardingState',
      },
    );
  }

  /**
   * Gets the currently authenticated user.
   */
  getAuthenticatedUser({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetAuthenticatedUser200Response>('/v2/me', {
      headers,
      endpointName: 'getAuthenticatedUser',
    });
  }

  /**
   * Gets the direct cast conversations for the requesting user.
   */
  getDirectCastConversationsV3(
    params: ApiGetDirectCastConversationsV3QueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationsV3200Response>(
      '/v2/direct-cast-conversation-list',
      {
        headers,
        endpointName: 'getDirectCastConversationsV3',
        params,
      },
    );
  }

  /**
   * Gets the direct casts for the specified conversation.
   */
  getDirectCastConversationMessages(
    params: ApiGetDirectCastConversationMessagesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationMessages200Response>(
      '/v2/direct-cast-conversation-messages',
      {
        headers,
        endpointName: 'getDirectCastConversationMessages',
        params,
      },
    );
  }

  /**
   * Gets the direct casts for the specified conversation. (deprecated)
   */
  getDirectCastConversationV3(
    params: ApiGetDirectCastConversationV3QueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationV3200Response>(
      '/v2/direct-cast-conversation-details',
      {
        headers,
        endpointName: 'getDirectCastConversationV3',
        params,
      },
    );
  }

  /**
   * Gets the inbox conversations for the requesting user.
   */
  getDirectCastInbox(
    params: ApiGetDirectCastInboxQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastInbox200Response>(
      '/v2/direct-cast-inbox',
      {
        headers,
        endpointName: 'getDirectCastInbox',
        params,
      },
    );
  }

  /**
   * Gets the recent direct casts for the specified conversation.
   */
  getDirectCastConversationRecentMessages(
    params: ApiGetDirectCastConversationRecentMessagesQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationRecentMessages200Response>(
      '/v2/direct-cast-conversation-recent-messages',
      {
        headers,
        endpointName: 'getDirectCastConversationRecentMessages',
        params,
      },
    );
  }

  /**
   * Gets the recovery address for the authenticated user
   */
  getRecoveryAddress({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetRecoveryAddress200Response>(
      '/v2/recovery-address',
      {
        headers,
        endpointName: 'getRecoveryAddress',
      },
    );
  }

  /**
   * Gets the specified user via their FID through replica.
   */
  getUserByFID(
    params: ApiGetUserByFIDQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserByFID200Response>('/v2/user-by-fid', {
      headers,
      endpointName: 'getUserByFID',
      params,
    });
  }

  /**
   * Gets the specified user via their FID. (Dangerous)
   */
  getUser(
    params: ApiGetUserQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUser200Response>('/v2/user', {
      headers,
      endpointName: 'getUser',
      params,
    });
  }

  /**
   * Gets the specified user via their username.
   */
  getUserByUsername(
    params: ApiGetUserByUsernameQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserByUsername200Response>(
      '/v2/user-by-username',
      {
        headers,
        endpointName: 'getUserByUsername',
        params,
      },
    );
  }

  /**
   * Gets the user who has most recently verified ownership of the specified ETH address.
   */
  getUserByVerification(
    params: ApiGetUserByVerificationQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserByVerification200Response>(
      '/v2/user-by-verification',
      {
        headers,
        endpointName: 'getUserByVerification',
        params,
      },
    );
  }

  /**
   * Gift warps between users
   */
  giftWarps(
    body: ApiGiftWarpsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiGiftWarps200Response>('/v2/gift-warps', {
      headers,
      endpointName: 'giftWarps',
      body,
    });
  }

  /**
   * Handler for gift 10 warps Farcaster action
   */
  tip10WarpsAction(
    body: ApiTip10WarpsActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<void>('/v2/tip-10-warps-action', {
      headers,
      endpointName: 'tip10WarpsAction',
      body,
    });
  }

  /**
   * History of warps for given authenticated user.
   */
  getWarpTransactions(
    params: ApiGetWarpTransactionsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetWarpTransactions200Response>(
      '/v2/warp-transactions',
      {
        headers,
        endpointName: 'getWarpTransactions',
        params,
      },
    );
  }

  /**
   * Information about buying Warps with Coinbase Commerce.
   */
  buyWarpsCoinbaseCommerceInfo({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiBuyWarpsCoinbaseCommerceInfo200Response>(
      '/v2/buy-warps-coinbase-commerce',
      {
        headers,
        endpointName: 'buyWarpsCoinbaseCommerceInfo',
      },
    );
  }

  /**
   * Initiate a new recovery
   */
  initiateRecovery(
    body: ApiInitiateRecoveryRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiInitiateRecovery200Response>('/v2/initiate-recovery', {
      headers,
      endpointName: 'initiateRecovery',
      body,
    });
  }

  /**
   * Initiate a signer key request for a Warpcast signer.
   */
  createWarpcastSignedKeyRequest({
    headers,
  }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiCreateWarpcastSignedKeyRequest200Response>(
      '/v2/warpcast-signed-key-request',
      {
        headers,
        endpointName: 'createWarpcastSignedKeyRequest',
        body: {},
      },
    );
  }

  /**
   * Invite a user to a channel as a member or moderator
   */
  inviteChannelUserToRole(
    body: ApiInviteChannelUserToRoleRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiInviteChannelUserToRole200Response>(
      '/v1/manage-channel-users',
      {
        headers,
        endpointName: 'inviteChannelUserToRole',
        body,
      },
    );
  }

  /**
   * Invite a user to a channel as a member or moderator (public signer version)
   */
  fcInviteUserToChannelRole(
    body: ApiFcInviteUserToChannelRoleRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFcInviteUserToChannelRole200Response>(
      '/fc/channel-invites',
      {
        headers,
        endpointName: 'fcInviteUserToChannelRole',
        body,
      },
    );
  }

  /**
   * Invite a user to a direct cast group.
   */
  fcPutGroupInvites(
    body: ApiFcPutGroupInvitesRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiFcPutGroupInvites200Response>('/fc/group-invites', {
      headers,
      endpointName: 'fcPutGroupInvites',
      body,
    });
  }

  /**
   * Join a channel via an invite code
   */
  joinChannelViaCode(
    body: ApiJoinChannelViaCodeRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiJoinChannelViaCode200Response>(
      '/v1/join-channel-via-code',
      {
        headers,
        endpointName: 'joinChannelViaCode',
        body,
      },
    );
  }

  /**
   * Limits the content visibility between users.
   */
  limitVisibility(
    body: ApiLimitVisibilityRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiLimitVisibility200Response>('/v2/limit-visibility', {
      headers,
      endpointName: 'limitVisibility',
      body,
    });
  }

  /**
   * List and search for channel banned users
   */
  fcGetChannelBannedUsers(
    params: ApiFcGetChannelBannedUsersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetChannelBannedUsers200Response>(
      '/fc/channel-bans',
      {
        headers,
        endpointName: 'fcGetChannelBannedUsers',
        params,
      },
    );
  }

  /**
   * List and search for channel banned users
   */
  getChannelBannedUsers(
    params: ApiGetChannelBannedUsersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelBannedUsers200Response>(
      '/v1/channel-banned-users',
      {
        headers,
        endpointName: 'getChannelBannedUsers',
        params,
      },
    );
  }

  /**
   * List and search members and directly invitable moderators of a channel
   */
  getChannelUsersForManagement(
    params: ApiGetChannelUsersForManagementQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelUsersForManagement200Response>(
      '/v1/manage-channel-users',
      {
        headers,
        endpointName: 'getChannelUsersForManagement',
        params,
      },
    );
  }

  /**
   * List and search users to invite to a channel
   */
  getChannelUsersForInvite(
    params: ApiGetChannelUsersForInviteQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelUsersForInvite200Response>(
      '/v1/invite-channel-users',
      {
        headers,
        endpointName: 'getChannelUsersForInvite',
        params,
      },
    );
  }

  /**
   * List or search for followers and/or members of a channel
   */
  getChannelUsers(
    params: ApiGetChannelUsersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelUsers200Response>('/v1/channel-users', {
      headers,
      endpointName: 'getChannelUsers',
      params,
    });
  }

  /**
   * Mark a peer-to-peer payment as completed
   */
  completePeerToPeerPayment(
    body: ApiCompletePeerToPeerPaymentRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiCompletePeerToPeerPayment200Response>(
      '/v2/complete-peer-to-peer-payment',
      {
        headers,
        endpointName: 'completePeerToPeerPayment',
        body,
      },
    );
  }

  /**
   * Mark all notifications as read.
   */
  markAllNotificationsRead({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiMarkAllNotificationsRead200Response>(
      '/v2/mark-all-notifications-read',
      {
        headers,
        endpointName: 'markAllNotificationsRead',
        body: {},
      },
    );
  }

  /**
   * Mark all notifications in a tab as seen.
   */
  markAllTabNotificationsSeen(
    body: ApiMarkAllTabNotificationsSeenRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiMarkAllTabNotificationsSeen200Response>(
      '/v2/mark-tab-notifications-seen',
      {
        headers,
        endpointName: 'markAllTabNotificationsSeen',
        body,
      },
    );
  }

  /**
   * Mark all warp transactions as read.
   */
  markAllWarpTransactionsRead({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiMarkAllWarpTransactionsRead200Response>(
      '/v2/mark-all-warp-transactions-read',
      {
        headers,
        endpointName: 'markAllWarpTransactionsRead',
        body: {},
      },
    );
  }

  /**
   * Mark direct cast key as dead. (deprecated)
   */
  markDirectCastKeyAsDead(
    body: ApiMarkDirectCastKeyAsDeadRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiMarkDirectCastKeyAsDead200Response>(
      '/v2/direct-cast-keys',
      {
        headers,
        endpointName: 'markDirectCastKeyAsDead',
        body,
      },
    );
  }

  /**
   * Mark direct cast keys as dead. (deprecated)
   */
  deleteDirectCastKeysByInbox(
    params: ApiDeleteDirectCastKeysByInboxQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteDirectCastKeysByInbox200Response>(
      '/v2/direct-cast-device-keys',
      {
        headers,
        endpointName: 'deleteDirectCastKeysByInbox',
        params,
      },
    );
  }

  /**
   * Mark prompt seen for user
   */
  markPromptedFor(
    body: ApiMarkPromptedForRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiMarkPromptedFor200Response>('/v2/mark-prompted-for', {
      headers,
      endpointName: 'markPromptedFor',
      body,
    });
  }

  /**
   * Mark suggested users as seen
   */
  setSuggestedUsersAsSeen(
    body: ApiSetSuggestedUsersAsSeenRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetSuggestedUsersAsSeen200Response>(
      '/v1/suggested-users-seen',
      {
        headers,
        endpointName: 'setSuggestedUsersAsSeen',
        body,
      },
    );
  }

  /**
   * Mark user nudged for contacts step during onboarding.
   */
  markNudgedForContacts({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiMarkNudgedForContacts200Response>(
      '/v2/mark-nudged-for-contacts',
      {
        headers,
        endpointName: 'markNudgedForContacts',
        body: {},
      },
    );
  }

  /**
   * Mark user nudged for interests step during onboarding.
   */
  markNudgedForInterests({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiMarkNudgedForInterests200Response>(
      '/v2/mark-nudged-for-interests',
      {
        headers,
        endpointName: 'markNudgedForInterests',
        body: {},
      },
    );
  }

  /**
   * Mark user nudged for push notifications step during onboarding.
   */
  markNudgedForPushNotifications({
    headers,
  }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiMarkNudgedForPushNotifications200Response>(
      '/v2/mark-nudged-for-push-notifications',
      {
        headers,
        endpointName: 'markNudgedForPushNotifications',
        body: {},
      },
    );
  }

  /**
   * Mark verification process start
   */
  markVerificationsStart({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiMarkVerificationsStart200Response>(
      '/v2/mark-verifications-start',
      {
        headers,
        endpointName: 'markVerificationsStart',
        body: {},
      },
    );
  }

  /**
   * Marks a conversation as read.
   */
  postDirectCastReadV3(
    body: ApiPostDirectCastReadV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostDirectCastReadV3200Response>(
      '/v2/direct-cast-mark-read',
      {
        headers,
        endpointName: 'postDirectCastReadV3',
        body,
      },
    );
  }

  /**
   * Marks the synchronization channel message as read for the requesting device.
   */
  markSyncChannelMessageRead(
    body: ApiMarkSyncChannelMessageReadRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiMarkSyncChannelMessageRead200Response>(
      '/v2/sync-channel-read',
      {
        headers,
        endpointName: 'markSyncChannelMessageRead',
        body,
      },
    );
  }

  /**
   * Moderate a cast
   */
  fcModerateCast(
    body: ApiFcModerateCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFcModerateCast200Response>('/fc/moderated-casts', {
      headers,
      endpointName: 'fcModerateCast',
      body,
    });
  }

  /**
   * No longer limit the content visibility between users.
   */
  removeVisibilityRestrictions(
    body: ApiRemoveVisibilityRestrictionsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiRemoveVisibilityRestrictions200Response>(
      '/v2/limit-visibility',
      {
        headers,
        endpointName: 'removeVisibilityRestrictions',
        body,
      },
    );
  }

  /**
   * Opens a page that loads JavaScript to sign a recovery transaction hash.
   */
  signRecoveryTransactionHash(
    params: ApiSignRecoveryTransactionHashQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/sign-recovery-transaction-hash', {
      headers,
      endpointName: 'signRecoveryTransactionHash',
      params,
    });
  }

  /**
   * POST to cast action URL and return response message
   */
  postCastAction(
    body: ApiPostCastActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostCastAction200Response>('/v2/cast-action', {
      headers,
      endpointName: 'postCastAction',
      body,
    });
  }

  /**
   * POST to composer action URL and return response message
   */
  postComposerAction(
    body: ApiPostComposerActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostComposerAction200Response>('/v2/composer-action', {
      headers,
      endpointName: 'postComposerAction',
      body,
    });
  }

  /**
   * Pin a cast to its channel
   */
  fcPinCast(
    body: ApiFcPinCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiFcPinCast200Response>('/fc/pinned-casts', {
      headers,
      endpointName: 'fcPinCast',
      body,
    });
  }

  /**
   * Pin a direct cast conversation.
   */
  pinDirectCastConversation(
    body: ApiPinDirectCastConversationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPinDirectCastConversation200Response>(
      '/v2/direct-cast-pin-conversation',
      {
        headers,
        endpointName: 'pinDirectCastConversation',
        body,
      },
    );
  }

  /**
   * Pin cast on user profile.
   */
  pinCastOnUserProfile(
    body: ApiPinCastOnUserProfileRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiPinCastOnUserProfile200Response>(
      '/v2/user-profile-pinned-casts',
      {
        headers,
        endpointName: 'pinCastOnUserProfile',
        body,
      },
    );
  }

  /**
   * Prepare a video upload
   */
  prepareVideoUpload(
    body: ApiPrepareVideoUploadRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPrepareVideoUpload200Response>(
      '/v1/prepare-video-upload',
      {
        headers,
        endpointName: 'prepareVideoUpload',
        body,
      },
    );
  }

  /**
   * Proxy an authentication request to the relay server
   */
  signInWithFarcaster(
    body: ApiSignInWithFarcasterRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiSignInWithFarcaster200Response>(
      '/v2/sign-in-with-farcaster',
      {
        headers,
        endpointName: 'signInWithFarcaster',
        body,
      },
    );
  }

  /**
   * Publicly return all blocked users
   */
  fcGetBlockedUsers(
    params: ApiFcGetBlockedUsersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiFcGetBlockedUsers200Response>(
      '/fc/blocked-users',
      {
        headers,
        endpointName: 'fcGetBlockedUsers',
        params,
      },
    );
  }

  /**
   * Publicly return all blocked users
   */
  getBlockedUsersPublic(
    params: ApiGetBlockedUsersPublicQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetBlockedUsersPublic200Response>(
      '/v1/blocked-users',
      {
        headers,
        endpointName: 'getBlockedUsersPublic',
        params,
      },
    );
  }

  /**
   * Recast a cast.
   */
  createRecast(
    body: ApiCreateRecastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiCreateRecast200Response>('/v2/recasts', {
      headers,
      endpointName: 'createRecast',
      body,
    });
  }

  /**
   * Receive App Store Server Notifications.
   */
  receiveAppStoreServerNotification(
    body: ApiReceiveAppStoreServerNotificationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiReceiveAppStoreServerNotification200Response>(
      '/v2/app-store-server-notifications',
      {
        headers,
        endpointName: 'receiveAppStoreServerNotification',
        body,
      },
    );
  }

  /**
   * Receive Coinbase Commerce webhook events.
   */
  receiveCoinbaseCommerceWebhookEvent({
    headers,
  }: { headers?: RequestHeaders } = {}) {
    return this.post<ApiReceiveCoinbaseCommerceWebhookEvent200Response>(
      '/coinbase-commerce-webhook-event',
      {
        headers,
        endpointName: 'receiveCoinbaseCommerceWebhookEvent',
        body: {},
      },
    );
  }

  /**
   * Receive sandbox App Store Server Notifications.
   */
  receiveAppStoreServerNotificationSandbox(
    body: ApiReceiveAppStoreServerNotificationSandboxRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiReceiveAppStoreServerNotificationSandbox200Response>(
      '/v2/app-store-server-notifications-sandbox',
      {
        headers,
        endpointName: 'receiveAppStoreServerNotificationSandbox',
        body,
      },
    );
  }

  /**
   * Record analytics events for storage in S3
   */
  recordAnalyticsEvents(
    body: ApiRecordAnalyticsEventsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiRecordAnalyticsEvents200Response>(
      '/v1/analytics-events',
      {
        headers,
        endpointName: 'recordAnalyticsEvents',
        body,
      },
    );
  }

  /**
   * Redirect client to a presigned URL to upload a video directly to Cloudflare using the TUS protocol.
   */
  uploadVideoWithTus(
    params: ApiUploadVideoWithTusQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<void>('/v1/upload-video-with-tus', {
      headers,
      endpointName: 'uploadVideoWithTus',
      params,
    });
  }

  /**
   * Redirect to the specified URL, validating the signature.
   */
  redirectToLink(
    params: ApiRedirectToLinkQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/links', {
      headers,
      endpointName: 'redirectToLink',
      params,
    });
  }

  /**
   * Redirect to the specified URL, validating the signature. (FID based)
   */
  redirectToLinkV2(
    params: ApiRedirectToLinkV2QueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/links-v2', {
      headers,
      endpointName: 'redirectToLinkV2',
      params,
    });
  }

  /**
   * Redirect to the specified farcaster:// URL. Not signed.
   */
  redirectToDeepLink({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<void>('/deeplinks/*', {
      headers,
      endpointName: 'redirectToDeepLink',
    });
  }

  /**
   * Register an FID for the authenticated address.
   */
  registerFid(
    body: ApiRegisterFidRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiRegisterFid200Response>('/v2/register-fid', {
      headers,
      endpointName: 'registerFid',
      body,
    });
  }

  /**
   * Register information about a user's device.
   */
  registerDevice(
    body: ApiRegisterDeviceRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiRegisterDevice200Response>('/v2/devices', {
      headers,
      endpointName: 'registerDevice',
      body,
    });
  }

  /**
   * Registers a composer action
   */
  addComposerAction(
    body: ApiAddComposerActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAddComposerAction200Response>(
      '/v2/add-composer-action',
      {
        headers,
        endpointName: 'addComposerAction',
        body,
      },
    );
  }

  /**
   * Registers a discovery app
   */
  addDiscoveryApp(
    body: ApiAddDiscoveryAppRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAddDiscoveryApp200Response>('/v2/discovery-app', {
      headers,
      endpointName: 'addDiscoveryApp',
      body,
    });
  }

  /**
   * Registers a discovery frame
   */
  addDiscoveryFrame(
    body: ApiAddDiscoveryFrameRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAddDiscoveryFrame200Response>('/v2/discovery-frame', {
      headers,
      endpointName: 'addDiscoveryFrame',
      body,
    });
  }

  /**
   * Remember that a new user has seen the instructions interstitial
   */
  dismissNewUserFollowInstructions({
    headers,
  }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiDismissNewUserFollowInstructions200Response>(
      '/v1/dismiss-new-user-follow-instructions',
      {
        headers,
        endpointName: 'dismissNewUserFollowInstructions',
        body: {},
      },
    );
  }

  /**
   * Remove a ban from a user in a channel
   */
  fcUnbanUserFromChannel(
    body: ApiFcUnbanUserFromChannelRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiFcUnbanUserFromChannel200Response>(
      '/fc/channel-bans',
      {
        headers,
        endpointName: 'fcUnbanUserFromChannel',
        body,
      },
    );
  }

  /**
   * Remove a ban from a user in a channel
   */
  unbanUserFromChannel(
    body: ApiUnbanUserFromChannelRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiUnbanUserFromChannel200Response>(
      '/v1/channel-banned-users',
      {
        headers,
        endpointName: 'unbanUserFromChannel',
        body,
      },
    );
  }

  /**
   * Remove a direct cast group's members.
   */
  fcDeleteGroupMembers(
    body: ApiFcDeleteGroupMembersRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiFcDeleteGroupMembers200Response>(
      '/fc/group-members',
      {
        headers,
        endpointName: 'fcDeleteGroupMembers',
        body,
      },
    );
  }

  /**
   * Remove a favorite a feed
   */
  removeFavoriteFeed(
    body: ApiRemoveFavoriteFeedRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiRemoveFavoriteFeed200Response>('/v2/favorite-feeds', {
      headers,
      endpointName: 'removeFavoriteFeed',
      body,
    });
  }

  /**
   * Remove a keyword from a user's mute list
   */
  removeMuteKeyword(
    body: ApiRemoveMuteKeywordRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiRemoveMuteKeyword200Response>('/v2/unmute-keyword', {
      headers,
      endpointName: 'removeMuteKeyword',
      body,
    });
  }

  /**
   * Remove a reaction from a cast.
   */
  deleteCastLike(
    body: ApiDeleteCastLikeRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteCastLike200Response>('/v2/cast-likes', {
      headers,
      endpointName: 'deleteCastLike',
      body,
    });
  }

  /**
   * Remove a reaction from an NFT.
   */
  deleteNftReaction(
    body: ApiDeleteNftReactionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteNftReaction200Response>('/v2/nft-reactions', {
      headers,
      endpointName: 'deleteNftReaction',
      body,
    });
  }

  /**
   * Remove a user from a channel as a member or moderator
   */
  removeChannelUserFromRole(
    body: ApiRemoveChannelUserFromRoleRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiRemoveChannelUserFromRole200Response>(
      '/v1/manage-channel-users',
      {
        headers,
        endpointName: 'removeChannelUserFromRole',
        body,
      },
    );
  }

  /**
   * Remove a user from a channel as a member or moderator (public signer version)
   */
  fcRemoveUserFromChannelRole(
    body: ApiFcRemoveUserFromChannelRoleRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiFcRemoveUserFromChannelRole200Response>(
      '/fc/channel-invites',
      {
        headers,
        endpointName: 'fcRemoveUserFromChannelRole',
        body,
      },
    );
  }

  /**
   * Remove boost from cast in a channel
   */
  removeCastBoost(
    body: ApiRemoveCastBoostRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiRemoveCastBoost200Response>('/v2/boost-cast', {
      headers,
      endpointName: 'removeCastBoost',
      body,
    });
  }

  /**
   * Remove cast bookmark.
   */
  removeCastBookmark(
    body: ApiRemoveCastBookmarkRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiRemoveCastBookmark200Response>(
      '/v2/bookmarked-casts',
      {
        headers,
        endpointName: 'removeCastBookmark',
        body,
      },
    );
  }

  /**
   * Remove info about connected account
   */
  removeConnectedAccount(
    body: ApiRemoveConnectedAccountRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiRemoveConnectedAccount200Response>(
      '/v2/connected-accounts',
      {
        headers,
        endpointName: 'removeConnectedAccount',
        body,
      },
    );
  }

  /**
   * Removes a reaction to the specified message.
   */
  deleteDirectCastConversationReactionsV3(
    body: ApiDeleteDirectCastConversationReactionsV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteDirectCastConversationReactionsV3200Response>(
      '/v2/direct-cast-message-reaction',
      {
        headers,
        endpointName: 'deleteDirectCastConversationReactionsV3',
        body,
      },
    );
  }

  /**
   * Removes a signer.
   */
  removeSigner(
    body: ApiRemoveSignerRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiRemoveSigner200Response>('/v2/signers', {
      headers,
      endpointName: 'removeSigner',
      body,
    });
  }

  /**
   * Rent storage
   */
  rentStorage(
    body: ApiRentStorageRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiRentStorage201Response>('/v2/rent-storage', {
      headers,
      endpointName: 'rentStorage',
      body,
    });
  }

  /**
   * Rent transaction intent
   */
  rentTransactionData(
    params: ApiRentTransactionDataQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<void>('/v2/rent-transaction-data', {
      headers,
      endpointName: 'rentTransactionData',
      params,
    });
  }

  /**
   * Report a cast.
   */
  reportCast(
    body: ApiReportCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiReportCast200Response>('/v2/report-cast', {
      headers,
      endpointName: 'reportCast',
      body,
    });
  }

  /**
   * Report a user.
   */
  reportUser(
    body: ApiReportUserRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiReportUser200Response>('/v2/report-user', {
      headers,
      endpointName: 'reportUser',
      body,
    });
  }

  /**
   * Report information about a frame transaction
   */
  reportFrameTransaction(
    body: ApiReportFrameTransactionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiReportFrameTransaction200Response>(
      '/v2/report-frame-transaction',
      {
        headers,
        endpointName: 'reportFrameTransaction',
        body,
      },
    );
  }

  /**
   * Request a deletion of an account.
   */
  requestAccountDelete({ headers }: { headers?: RequestHeaders } = {}) {
    return this.post<ApiRequestAccountDelete200Response>(
      '/v2/request-account-delete',
      {
        headers,
        endpointName: 'requestAccountDelete',
        body: {},
      },
    );
  }

  /**
   * Request a magic link
   */
  initiateMagicLink(
    body: ApiInitiateMagicLinkRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiInitiateMagicLink200Response>('/v2/magic-link', {
      headers,
      endpointName: 'initiateMagicLink',
      body,
    });
  }

  /**
   * Request an invite email with an email address
   */
  signupForInvite(
    body: ApiSignupForInviteRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiSignupForInvite200Response>('/v2/signup-for-invite', {
      headers,
      endpointName: 'signupForInvite',
      body,
    });
  }

  /**
   * Reset onboarding state associated with an email. (Super-admins only)
   */
  resetOnboardingState(
    body: ApiResetOnboardingStateRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiResetOnboardingState200Response>(
      '/v2/reset-onboarding-state',
      {
        headers,
        endpointName: 'resetOnboardingState',
        body,
      },
    );
  }

  /**
   * Reset the invite code for a channel
   */
  resetChannelInviteCode(
    body: ApiResetChannelInviteCodeRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiResetChannelInviteCode200Response>(
      '/v1/reset-channel-invite-code',
      {
        headers,
        endpointName: 'resetChannelInviteCode',
        body,
      },
    );
  }

  /**
   * Reset the value of a dynamic config to its default
   */
  resetDynamicConfig(
    body: ApiResetDynamicConfigRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiResetDynamicConfig200Response>(
      '/v1/dynamic-configs',
      {
        headers,
        endpointName: 'resetDynamicConfig',
        body,
      },
    );
  }

  /**
   * Reset user to go through new user experience
   */
  resetToNewUserExperience({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiResetToNewUserExperience200Response>(
      '/v1/reset-to-new-user-experience',
      {
        headers,
        endpointName: 'resetToNewUserExperience',
        body: {},
      },
    );
  }

  /**
   * Resets cached open-graph preview and fetches a new one by scraping the URL again.
   */
  scrapeEmbed(
    body: ApiScrapeEmbedRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiScrapeEmbed200Response>('/v2/scrape-embed', {
      headers,
      endpointName: 'scrapeEmbed',
      body,
    });
  }

  /**
   * Resets cached open-graph preview and fetches and validates a frame open graph value set.
   */
  validateFrameEmbed(
    body: ApiValidateFrameEmbedRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiValidateFrameEmbed200Response>('/v2/validate-frame', {
      headers,
      endpointName: 'validateFrameEmbed',
      body,
    });
  }

  /**
   * Retrieve the synchronization channel messages for the requesting device.
   */
  getSyncChannel(
    params: ApiGetSyncChannelQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetSyncChannel200Response>('/v2/sync-channel', {
      headers,
      endpointName: 'getSyncChannel',
      params,
    });
  }

  /**
   * Retrieves the reactions to a specific message.
   */
  getDirectCastConversationReactionsV3(
    params: ApiGetDirectCastConversationReactionsV3QueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDirectCastConversationReactionsV3200Response>(
      '/v2/direct-cast-message-reaction',
      {
        headers,
        endpointName: 'getDirectCastConversationReactionsV3',
        params,
      },
    );
  }

  /**
   * Return a few channels and users matching the given search query.
   */
  searchSummary(
    params: ApiSearchSummaryQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiSearchSummary200Response>('/v2/search-summary', {
      headers,
      endpointName: 'searchSummary',
      params,
    });
  }

  /**
   * Return a robotos.txt that disallows crawling our APIs
   */
  getRobotsTxt({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<void>('/robots.txt', {
      headers,
      endpointName: 'getRobotsTxt',
    });
  }

  /**
   * Return actions matching the given search query.
   */
  searchActions(
    params: ApiSearchActionsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiSearchActions200Response>('/v2/search-actions', {
      headers,
      endpointName: 'searchActions',
      params,
    });
  }

  /**
   * Return actions matching the given search query.
   */
  searchComposerActions(
    params: ApiSearchComposerActionsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiSearchComposerActions200Response>(
      '/v2/search-composer-actions',
      {
        headers,
        endpointName: 'searchComposerActions',
        params,
      },
    );
  }

  /**
   * Return all casts matching the given search query.
   */
  searchCasts(
    params: ApiSearchCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiSearchCasts200Response>('/v2/search-casts', {
      headers,
      endpointName: 'searchCasts',
      params,
    });
  }

  /**
   * Return all channels
   */
  getAllChannelsPublic({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetAllChannelsPublic200Response>(
      '/v2/all-channels',
      {
        headers,
        endpointName: 'getAllChannelsPublic',
      },
    );
  }

  /**
   * Return all channels matching the given search query.
   */
  searchChannels(
    params: ApiSearchChannelsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiSearchChannels200Response>('/v2/search-channels', {
      headers,
      endpointName: 'searchChannels',
      params,
    });
  }

  /**
   * Return all direct cast conversations matching the given search query.
   */
  searchDirectCasts(
    params: ApiSearchDirectCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiSearchDirectCasts200Response>(
      '/v2/search-direct-casts',
      {
        headers,
        endpointName: 'searchDirectCasts',
        params,
      },
    );
  }

  /**
   * Return all inbox conversations matching the given search query.
   */
  searchDirectCastInbox(
    params: ApiSearchDirectCastInboxQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiSearchDirectCastInbox200Response>(
      '/v2/search-direct-cast-inbox',
      {
        headers,
        endpointName: 'searchDirectCastInbox',
        params,
      },
    );
  }

  /**
   * Return all users matching the given search query.
   */
  searchUsers(
    params: ApiSearchUsersQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiSearchUsers200Response>('/v2/search-users', {
      headers,
      endpointName: 'searchUsers',
      params,
    });
  }

  /**
   * Return channel information
   */
  getChannelPublic(
    params: ApiGetChannelPublicQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelPublic200Response>('/v1/channel', {
      headers,
      endpointName: 'getChannelPublic',
      params,
    });
  }

  /**
   * Return channels a user is following
   */
  getUserFollowingChannelsPublic(
    params: ApiGetUserFollowingChannelsPublicQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserFollowingChannelsPublic200Response>(
      '/v1/user-following-channels',
      {
        headers,
        endpointName: 'getUserFollowingChannelsPublic',
        params,
      },
    );
  }

  /**
   * Return success if API key is valid.
   */
  validateApiKey({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiValidateApiKey200Response>(
      '/v2/validate-api-key',
      {
        headers,
        endpointName: 'validateApiKey',
      },
    );
  }

  /**
   * Return the status of a user potentially following a channel
   */
  getUserChannelPublic(
    params: ApiGetUserChannelPublicQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetUserChannelPublic200Response>(
      '/v1/user-channel',
      {
        headers,
        endpointName: 'getUserChannelPublic',
        params,
      },
    );
  }

  /**
   * Return users who follow a channel
   */
  getChannelFollowersPublic(
    params: ApiGetChannelFollowersPublicQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetChannelFollowersPublic200Response>(
      '/v1/channel-followers',
      {
        headers,
        endpointName: 'getChannelFollowersPublic',
        params,
      },
    );
  }

  /**
   * Returns common share targets for cast
   */
  shareCast(
    params: ApiShareCastQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiShareCast200Response>('/v2/share-cast', {
      headers,
      endpointName: 'shareCast',
      params,
    });
  }

  /**
   * Returns information about onboarding state. (Super-admins only)
   */
  lookupOnboardingState(
    params: ApiLookupOnboardingStateQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiLookupOnboardingState200Response>(
      '/v2/lookup-onboarding-state',
      {
        headers,
        endpointName: 'lookupOnboardingState',
        params,
      },
    );
  }

  /**
   * Returns rituals for channel or draft casts
   */
  getDraftCasts(
    params: ApiGetDraftCastsQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetDraftCasts200Response>('/v2/draft-casts', {
      headers,
      endpointName: 'getDraftCasts',
      params,
    });
  }

  /**
   * Returns the active channel streak for the user
   */
  getActiveChannelStreak(
    params: ApiGetActiveChannelStreakQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiGetActiveChannelStreak200Response>(
      '/v2/channel-streaks',
      {
        headers,
        endpointName: 'getActiveChannelStreak',
        params,
      },
    );
  }

  /**
   * Returns whether the server is healthy and responding to requests.
   */
  getHealth({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetHealth200Response>('/healthcheck', {
      headers,
      endpointName: 'getHealth',
    });
  }

  /**
   * Revokes a verification for the authenticated user.
   */
  deleteVerification(
    body: ApiDeleteVerificationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteVerification200Response>('/v2/verifications', {
      headers,
      endpointName: 'deleteVerification',
      body,
    });
  }

  /**
   * Revokes an API key.
   */
  revokeApiKey(
    body: ApiRevokeApiKeyRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiRevokeApiKey200Response>('/v2/revoke-api-key', {
      headers,
      endpointName: 'revokeApiKey',
      body,
    });
  }

  /**
   * Revokes an authentication token.
   */
  deleteAuthToken({ headers }: { headers?: RequestHeaders } = {}) {
    return this.delete<ApiDeleteAuthToken200Response>('/v2/auth', {
      headers,
      endpointName: 'deleteAuthToken',
      body: {},
    });
  }

  /**
   * Scan a transaction request from a mini app
   */
  postMiniAppTransaction(
    body: ApiPostMiniAppTransactionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiPostMiniAppTransaction200Response>(
      '/v2/mini-app-transaction',
      {
        headers,
        endpointName: 'postMiniAppTransaction',
        body,
      },
    );
  }

  /**
   * Send a direct cast message.
   */
  fcPutMessage(
    body: ApiFcPutMessageRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiFcPutMessage200Response>('/fc/message', {
      headers,
      endpointName: 'fcPutMessage',
      body,
    });
  }

  /**
   * Send a direct cast, for use by application developers.
   */
  putExtSendDirectCast(
    body: ApiPutExtSendDirectCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiPutExtSendDirectCast200Response>(
      '/v2/ext-send-direct-cast',
      {
        headers,
        endpointName: 'putExtSendDirectCast',
        body,
      },
    );
  }

  /**
   * Send a recovery transaction signature representing an admin's approval of the recovery.
   */
  approveRecoveryTransaction(
    body: ApiApproveRecoveryTransactionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiApproveRecoveryTransaction200Response>(
      '/v2/approve-recovery-transaction',
      {
        headers,
        endpointName: 'approveRecoveryTransaction',
        body,
      },
    );
  }

  /**
   * Send a test push notification
   */
  sendPushNotification(
    body: ApiSendPushNotificationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiSendPushNotification200Response>(
      '/v2/send-push-notification',
      {
        headers,
        endpointName: 'sendPushNotification',
        body,
      },
    );
  }

  /**
   * Send a verification email to the specified email address, associating it with the given custody address.
   */
  sendVerificationEmail(
    body: ApiSendVerificationEmailRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiSendVerificationEmail200Response>(
      '/v2/send-verification-email',
      {
        headers,
        endpointName: 'sendVerificationEmail',
        body,
      },
    );
  }

  /**
   * Send a verification email to the specified email address, will be associated to authenticated FID.
   */
  updateEmail(
    body: ApiUpdateEmailRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiUpdateEmail200Response>('/v2/update-email', {
      headers,
      endpointName: 'updateEmail',
      body,
    });
  }

  /**
   * Send an email to users about buying warps
   */
  sendBuyWarpsInfoEmail({ headers }: { headers?: RequestHeaders } = {}) {
    return this.post<ApiSendBuyWarpsInfoEmail200Response>(
      '/v2/send-buy-warps-info-email',
      {
        headers,
        endpointName: 'sendBuyWarpsInfoEmail',
        body: {},
      },
    );
  }

  /**
   * Send an email with a link to verify flow on web.
   */
  sendConnectAddressLinkEmail({ headers }: { headers?: RequestHeaders } = {}) {
    return this.post<ApiSendConnectAddressLinkEmail200Response>(
      '/v2/send-connect-address-link-email',
      {
        headers,
        endpointName: 'sendConnectAddressLinkEmail',
        body: {},
      },
    );
  }

  /**
   * Send an invite to the specified email address.
   */
  inviteUser(
    body: ApiInviteUserRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiInviteUser200Response>('/v2/invites', {
      headers,
      endpointName: 'inviteUser',
      body,
    });
  }

  /**
   * Send warps.
   */
  sendWarps(
    body: ApiSendWarpsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSendWarps200Response>('/v2/send-warps', {
      headers,
      endpointName: 'sendWarps',
      body,
    });
  }

  /**
   * Sends a direct cast.
   */
  putDirectCastV3(
    body: ApiPutDirectCastV3RequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiPutDirectCastV3200Response>('/v2/direct-cast-send', {
      headers,
      endpointName: 'putDirectCastV3',
      body,
    });
  }

  /**
   * Set channel distribution
   */
  setChannelDistribution(
    body: ApiSetChannelDistributionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetChannelDistribution200Response>(
      '/v2/channel-distribution',
      {
        headers,
        endpointName: 'setChannelDistribution',
        body,
      },
    );
  }

  /**
   * Set the active username on the authenticated user
   */
  setUserUsername(
    body: ApiSetUserUsernameRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetUserUsername200Response>('/v2/set-user-username', {
      headers,
      endpointName: 'setUserUsername',
      body,
    });
  }

  /**
   * Set the badness of a low quality user.
   */
  setLowQualityUserBadness(
    body: ApiSetLowQualityUserBadnessRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetLowQualityUserBadness200Response>(
      '/v1/set-low-quality-user-badness',
      {
        headers,
        endpointName: 'setLowQualityUserBadness',
        body,
      },
    );
  }

  /**
   * Set the position of a favorite feed
   */
  setFavoriteFeedPosition(
    body: ApiSetFavoriteFeedPositionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiSetFavoriteFeedPosition200Response>(
      '/v2/favorite-feeds',
      {
        headers,
        endpointName: 'setFavoriteFeedPosition',
        body,
      },
    );
  }

  /**
   * Set the quality of a user.
   */
  setUserQuality(
    body: ApiSetUserQualityRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetUserQuality200Response>('/v2/set-user-quality', {
      headers,
      endpointName: 'setUserQuality',
      body,
    });
  }

  /**
   * Set the quality of many users
   */
  setBulkUserQuality(
    body: ApiSetBulkUserQualityRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetBulkUserQuality200Response>(
      '/v2/set-bulk-user-quality',
      {
        headers,
        endpointName: 'setBulkUserQuality',
        body,
      },
    );
  }

  /**
   * Set the quality of many users via automated detection
   */
  setBulkAutoUserQuality(
    body: ApiSetBulkAutoUserQualityRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetBulkAutoUserQuality200Response>(
      '/v2/set-bulk-auto-user-quality',
      {
        headers,
        endpointName: 'setBulkAutoUserQuality',
        body,
      },
    );
  }

  /**
   * Set the referrer for the authenticated user.
   */
  setUserReferrer(
    body: ApiSetUserReferrerRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetUserReferrer200Response>('/v2/user-referrer', {
      headers,
      endpointName: 'setUserReferrer',
      body,
    });
  }

  /**
   * Set the value of a dynamic config
   */
  setDynamicConfig(
    body: ApiSetDynamicConfigRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetDynamicConfig200Response>('/v1/dynamic-configs', {
      headers,
      endpointName: 'setDynamicConfig',
      body,
    });
  }

  /**
   * Set time feed was last seen by providing the timestamp of the latest feed item
   */
  setFeedSeen(
    body: ApiSetFeedSeenRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSetFeedSeen200Response>('/v2/feed-seen', {
      headers,
      endpointName: 'setFeedSeen',
      body,
    });
  }

  /**
   * Sets an action's position
   */
  setUserCastActionPosition(
    body: ApiSetUserCastActionPositionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiSetUserCastActionPosition200Response>(
      '/v2/user-cast-actions',
      {
        headers,
        endpointName: 'setUserCastActionPosition',
        body,
      },
    );
  }

  /**
   * Signer related features metadata
   */
  getSignersMetadata({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<ApiGetSignersMetadata200Response>(
      '/v2/signers-metadata',
      {
        headers,
        endpointName: 'getSignersMetadata',
      },
    );
  }

  /**
   * Simulate a rent storage transaction
   */
  simulateRentStorage(
    params: ApiSimulateRentStorageQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<ApiSimulateRentStorage200Response>(
      '/v2/simulate-rent-storage',
      {
        headers,
        endpointName: 'simulateRentStorage',
        params,
      },
    );
  }

  /**
   * Simulate create signed key request.
   */
  simulateCreateSignedKeyRequest(
    body: ApiSimulateCreateSignedKeyRequestRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSimulateCreateSignedKeyRequest200Response>(
      '/v2/simulate-create-signed-key-request',
      {
        headers,
        endpointName: 'simulateCreateSignedKeyRequest',
        body,
      },
    );
  }

  /**
   * Simulate registering an FID for the authenticated address
   */
  simulateRegisterFid(
    body: ApiSimulateRegisterFidRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSimulateRegisterFid200Response>(
      '/v2/simulate-register-fid',
      {
        headers,
        endpointName: 'simulateRegisterFid',
        body,
      },
    );
  }

  /**
   * Simulate remove signed key request.
   */
  simulateRemoveSignedKeyRequest(
    body: ApiSimulateRemoveSignedKeyRequestRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSimulateRemoveSignedKeyRequest200Response>(
      '/v2/simulate-remove-signed-key-request',
      {
        headers,
        endpointName: 'simulateRemoveSignedKeyRequest',
        body,
      },
    );
  }

  /**
   * Skip connect address during onboarding.
   */
  skipConnectAddress({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiSkipConnectAddress200Response>(
      '/v2/skip-connect-address',
      {
        headers,
        endpointName: 'skipConnectAddress',
        body: {},
      },
    );
  }

  /**
   * Skip phone verification during onboarding.
   */
  skipPhone(
    body: ApiSkipPhoneRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSkipPhone200Response>('/v2/skip-phone', {
      headers,
      endpointName: 'skipPhone',
      body,
    });
  }

  /**
   * Start a channel streak
   */
  startChannelStreak(
    body: ApiStartChannelStreakRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiStartChannelStreak200Response>('/v2/channel-streaks', {
      headers,
      endpointName: 'startChannelStreak',
      body,
    });
  }

  /**
   * Start a phone verification
   */
  startPhoneVerification(
    body: ApiStartPhoneVerificationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiStartPhoneVerification200Response>(
      '/v2/start-phone-verification',
      {
        headers,
        endpointName: 'startPhoneVerification',
        body,
      },
    );
  }

  /**
   * Start a quest for the email and wallet to attest Farcaster signed up
   */
  startSignedUpAttestations(
    body: ApiStartSignedUpAttestationsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiStartSignedUpAttestations200Response>(
      '/v2/signed-up-attestations',
      {
        headers,
        endpointName: 'startSignedUpAttestations',
        body,
      },
    );
  }

  /**
   * Start in-app purchase flow for authenticated user.
   */
  startInAppPurchase(
    body: ApiStartInAppPurchaseRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiStartInAppPurchase200Response>(
      '/v2/start-in-app-purchase',
      {
        headers,
        endpointName: 'startInAppPurchase',
        body,
      },
    );
  }

  /**
   * Start in-app purchase flow for custody-token auth user.
   */
  startInAppPurchaseWithCustody(
    body: ApiStartInAppPurchaseWithCustodyRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiStartInAppPurchaseWithCustody200Response>(
      '/v2/start-in-app-purchase-with-custody',
      {
        headers,
        endpointName: 'startInAppPurchaseWithCustody',
        body,
      },
    );
  }

  /**
   * Start verification and generate token
   */
  startVerification({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiStartVerification200Response>('/v2/start-verification', {
      headers,
      endpointName: 'startVerification',
      body: {},
    });
  }

  /**
   * Stop active channel streaks
   */
  stopActiveChannelStreaks({ headers }: { headers?: RequestHeaders } = {}) {
    return this.delete<ApiStopActiveChannelStreaks200Response>(
      '/v2/channel-streaks',
      {
        headers,
        endpointName: 'stopActiveChannelStreaks',
        body: {},
      },
    );
  }

  /**
   * Stop showing banner that counts number of followed users
   */
  dismissNewUserFollowsBanner({ headers }: { headers?: RequestHeaders } = {}) {
    return this.put<ApiDismissNewUserFollowsBanner200Response>(
      '/v1/dismiss-new-user-follows-banners',
      {
        headers,
        endpointName: 'dismissNewUserFollowsBanner',
        body: {},
      },
    );
  }

  /**
   * Store a draft cast
   */
  storeDraftCast(
    body: ApiStoreDraftCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiStoreDraftCast200Response>('/v2/draft-casts', {
      headers,
      endpointName: 'storeDraftCast',
      body,
    });
  }

  /**
   * Submit a message from signed message data
   */
  submitSignedMessageData(
    body: ApiSubmitSignedMessageDataRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiSubmitSignedMessageData200Response>(
      '/v2/submit-signed-message-data',
      {
        headers,
        endpointName: 'submitSignedMessageData',
        body,
      },
    );
  }

  /**
   * Submit feedback for a cast
   */
  recordCastFeedback(
    body: ApiRecordCastFeedbackRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiRecordCastFeedback200Response>('/v1/cast-feedback', {
      headers,
      endpointName: 'recordCastFeedback',
      body,
    });
  }

  /**
   * Submit user selected onboarding interests.
   */
  submitSelectedOnboardingInterests(
    body: ApiSubmitSelectedOnboardingInterestsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiSubmitSelectedOnboardingInterests200Response>(
      '/v2/onboarding-interests',
      {
        headers,
        endpointName: 'submitSelectedOnboardingInterests',
        body,
      },
    );
  }

  /**
   * Tip a cast
   */
  tipCast(
    body: ApiTipCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiTipCast200Response>('/v2/cast-tips', {
      headers,
      endpointName: 'tipCast',
      body,
    });
  }

  /**
   * Trigger admin actions
   */
  adminAction(
    body: ApiAdminActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAdminAction200Response>('/v1/admin-action', {
      headers,
      endpointName: 'adminAction',
      body,
    });
  }

  /**
   * Trigger updates to multiple channel threads
   */
  adminUpdateChannelThreads(
    body: ApiAdminUpdateChannelThreadsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAdminUpdateChannelThreads200Response>(
      '/v1/admin-update-channel-threads',
      {
        headers,
        endpointName: 'adminUpdateChannelThreads',
        body,
      },
    );
  }

  /**
   * Trigger updates to multiple channel threads
   */
  adminUpdateChannelsAllThreads(
    body: ApiAdminUpdateChannelsAllThreadsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiAdminUpdateChannelsAllThreads200Response>(
      '/v1/admin-update-channels-all-threads',
      {
        headers,
        endpointName: 'adminUpdateChannelsAllThreads',
        body,
      },
    );
  }

  /**
   * Unblock a user
   */
  fcUnblockUser(
    body: ApiFcUnblockUserRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiFcUnblockUser200Response>('/fc/blocked-users', {
      headers,
      endpointName: 'fcUnblockUser',
      body,
    });
  }

  /**
   * Unfollow a channel
   */
  fcUnfollowChannel(
    body: ApiFcUnfollowChannelRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiFcUnfollowChannel200Response>('/fc/channel-follows', {
      headers,
      endpointName: 'fcUnfollowChannel',
      body,
    });
  }

  /**
   * Unfollow a feed.
   */
  deleteFeedFollow(
    body: ApiDeleteFeedFollowRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteFeedFollow200Response>('/v2/feed-follows', {
      headers,
      endpointName: 'deleteFeedFollow',
      body,
    });
  }

  /**
   * Unfollow a user.
   */
  deleteFollow(
    body: ApiDeleteFollowRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiDeleteFollow200Response>('/v2/follows', {
      headers,
      endpointName: 'deleteFollow',
      body,
    });
  }

  /**
   * Unfollow all least interacted with following users.
   */
  unfollowLeastInteractedWithFollowing({
    headers,
  }: { headers?: RequestHeaders } = {}) {
    return this.delete<ApiUnfollowLeastInteractedWithFollowing200Response>(
      '/v2/unfollow-least-interacted-with-following',
      {
        headers,
        endpointName: 'unfollowLeastInteractedWithFollowing',
        body: {},
      },
    );
  }

  /**
   * Unpin a cast
   */
  fcUnpinCast(
    body: ApiFcUnpinCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiFcUnpinCast200Response>('/fc/pinned-casts', {
      headers,
      endpointName: 'fcUnpinCast',
      body,
    });
  }

  /**
   * Unpin a direct cast conversation.
   */
  unpinDirectCastConversation(
    body: ApiUnpinDirectCastConversationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiUnpinDirectCastConversation200Response>(
      '/v2/direct-cast-pin-conversation',
      {
        headers,
        endpointName: 'unpinDirectCastConversation',
        body,
      },
    );
  }

  /**
   * Unpin cast on user profile.
   */
  unpinCastOnUserProfile(
    body: ApiUnpinCastOnUserProfileRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiUnpinCastOnUserProfile200Response>(
      '/v2/user-profile-pinned-casts',
      {
        headers,
        endpointName: 'unpinCastOnUserProfile',
        body,
      },
    );
  }

  /**
   * Unsubscribes email address per FID. Duplicate of GET above, but for Gmail one-click unsubscribe.
   */
  unsubscribeFidOneClick(
    params: ApiUnsubscribeFidOneClickQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<void>('/unsubscribe-v2', {
      headers,
      endpointName: 'unsubscribeFidOneClick',
      params,
    });
  }

  /**
   * Unsubscribes email address per FID. Intended to be loaded via browser.
   */
  unsubscribeFid(
    params: ApiUnsubscribeFidQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/unsubscribe-v2', {
      headers,
      endpointName: 'unsubscribeFid',
      params,
    });
  }

  /**
   * Unsubscribes email address. Duplicate of GET above, but for Gmail one-click unsubscribe.
   */
  unsubscribeEmailOneClick(
    params: ApiUnsubscribeEmailOneClickQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<void>('/unsubscribe', {
      headers,
      endpointName: 'unsubscribeEmailOneClick',
      params,
    });
  }

  /**
   * Unsubscribes email address. Intended to be loaded via browser.
   */
  unsubscribeEmail(
    params: ApiUnsubscribeEmailQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/unsubscribe', {
      headers,
      endpointName: 'unsubscribeEmail',
      params,
    });
  }

  /**
   * Unwatch a cast.
   */
  unwatchCast(
    body: ApiUnwatchCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.delete<ApiUnwatchCast200Response>('/v2/watched-casts', {
      headers,
      endpointName: 'unwatchCast',
      body,
    });
  }

  /**
   * Update (pause/unpause) job queue or queue shard
   */
  updateJobQueue(
    body: ApiUpdateJobQueueRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiUpdateJobQueue200Response>('/v1/job-queue', {
      headers,
      endpointName: 'updateJobQueue',
      body,
    });
  }

  /**
   * Update a channel
   */
  updateChannel(
    body: ApiUpdateChannelRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiUpdateChannel200Response>('/v2/channels-owned', {
      headers,
      endpointName: 'updateChannel',
      body,
    });
  }

  /**
   * Update a direct cast conversation.
   */
  fcPostConversation(
    body: ApiFcPostConversationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFcPostConversation200Response>('/fc/conversation', {
      headers,
      endpointName: 'fcPostConversation',
      body,
    });
  }

  /**
   * Update a direct cast group member's role.
   */
  fcPostGroupMembers(
    body: ApiFcPostGroupMembersRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFcPostGroupMembers200Response>('/fc/group-members', {
      headers,
      endpointName: 'fcPostGroupMembers',
      body,
    });
  }

  /**
   * Update a direct cast group.
   */
  fcPostGroup(
    body: ApiFcPostGroupRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiFcPostGroup200Response>('/fc/group', {
      headers,
      endpointName: 'fcPostGroup',
      body,
    });
  }

  /**
   * Update a paid invite
   */
  updatePaidInvite(
    body: ApiUpdatePaidInviteRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiUpdatePaidInvite200Response>('/v2/invite', {
      headers,
      endpointName: 'updatePaidInvite',
      body,
    });
  }

  /**
   * Update preferences for direct cast group.
   */
  updateDirectCastGroupPreferences(
    body: ApiUpdateDirectCastGroupPreferencesRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiUpdateDirectCastGroupPreferences200Response>(
      '/v2/direct-cast-group-preferences',
      {
        headers,
        endpointName: 'updateDirectCastGroupPreferences',
        body,
      },
    );
  }

  /**
   * Update the authenticated user's recovery address
   */
  updateRecoveryAddress(
    body: ApiUpdateRecoveryAddressRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiUpdateRecoveryAddress200Response>(
      '/v2/recovery-address',
      {
        headers,
        endpointName: 'updateRecoveryAddress',
        body,
      },
    );
  }

  /**
   * Update user's device contact book state
   */
  setContactsDeviceState(
    body: ApiSetContactsDeviceStateRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiSetContactsDeviceState200Response>(
      '/v2/update-contacts-device-state',
      {
        headers,
        endpointName: 'setContactsDeviceState',
        body,
      },
    );
  }

  /**
   * Updates
   */
  setUserPreferences(
    body: ApiSetUserPreferencesRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiSetUserPreferences200Response>(
      '/v2/user-preferences',
      {
        headers,
        endpointName: 'setUserPreferences',
        body,
      },
    );
  }

  /**
   * Updates attributes for the currently authenticated user.
   */
  updateUser(
    body: ApiUpdateUserRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiUpdateUser200Response>('/v2/me', {
      headers,
      endpointName: 'updateUser',
      body,
    });
  }

  /**
   * Updates the location attribute for the currently authenticated user.
   */
  updateUserLocation(
    body: ApiUpdateUserLocationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.patch<ApiUpdateUserLocation200Response>('/v2/my-location', {
      headers,
      endpointName: 'updateUserLocation',
      body,
    });
  }

  /**
   * Upload user's contact book
   */
  uploadContacts(
    body: ApiUploadContactsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiUploadContacts200Response>('/v2/contacts', {
      headers,
      endpointName: 'uploadContacts',
      body,
    });
  }

  /**
   * Uploads text to be scraped to generate a preview.
   */
  processCastAttachments(
    body: ApiProcessCastAttachmentsRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiProcessCastAttachments200Response>(
      '/v2/cast-attachments',
      {
        headers,
        endpointName: 'processCastAttachments',
        body,
      },
    );
  }

  /**
   * Validate composer action metadata and POST to composer action URL
   */
  validateComposerAction(
    body: ApiValidateComposerActionRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiValidateComposerAction200Response>(
      '/v2/validate-composer-action',
      {
        headers,
        endpointName: 'validateComposerAction',
        body,
      },
    );
  }

  /**
   * Verifies email address. Intended to be loaded via browser.
   */
  verifyEmail(
    params: ApiVerifyEmailQueryParams,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.authedGet<void>('/v2/emails/verify', {
      headers,
      endpointName: 'verifyEmail',
      params,
    });
  }

  /**
   * Verify an address for a Farcaster user.
   */
  putVerification(
    body: ApiPutVerificationRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiPutVerification200Response>('/v2/verifications', {
      headers,
      endpointName: 'putVerification',
      body,
    });
  }

  /**
   * Vote on a poll
   */
  recordPollVote(
    body: ApiRecordPollVoteRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.post<ApiRecordPollVote200Response>('/v2/record-poll-vote', {
      headers,
      endpointName: 'recordPollVote',
      body,
    });
  }

  /**
   * Watch a cast.
   */
  watchCast(
    body: ApiWatchCastRequestBody,
    { headers }: { headers?: RequestHeaders } = {},
  ) {
    return this.put<ApiWatchCast200Response>('/v2/watched-casts', {
      headers,
      endpointName: 'watchCast',
      body,
    });
  }

  /**
   * X auth callbacks
   */
  authenticateX({ headers }: { headers?: RequestHeaders } = {}) {
    return this.authedGet<void>('/auth/x', {
      headers,
      endpointName: 'authenticateX',
    });
  }
}
