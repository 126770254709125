import { useQuery } from '@tanstack/react-query';

import { useFarcasterApiClient } from '../../../../providers';
import { useMergeIntoGloballyCachedUser } from '../globallyCachedUser/useMergeIntoGloballyCachedUser';
import { buildOnboardingStateFetcher } from './buildOnboardingStateFetcher';
import { buildOnboardingStateKey } from './buildOnboardingStateKey';
import { onboardingStateCacheTime } from './shared';
import { useFallbackOnboardingState } from './useFallbackOnboardingState';

const useOnboardingState = ({ enabled }: { enabled: boolean }) => {
  const { apiClient } = useFarcasterApiClient();
  const mergeIntoGloballyCachedUser = useMergeIntoGloballyCachedUser();

  const fallback = useFallbackOnboardingState();

  return (
    useQuery({
      queryKey: buildOnboardingStateKey(),
      queryFn: buildOnboardingStateFetcher({
        apiClient,
        mergeIntoGloballyCachedUser,
      }),
      gcTime: onboardingStateCacheTime,
      enabled,
    }).data || fallback
  );
};

export { useOnboardingState };
