import locale from 'date-fns/locale/en-US';
import setDefaultOptions from 'date-fns/setDefaultOptions';

const initDateFns = () =>
  setDefaultOptions({
    locale: {
      ...locale,
      formatDistance,
    },
  });

const formatDistanceLocale: { [key: string]: string } = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}mo',
  xMonths: '{{count}}mo',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
};

const formatDistance = (token: string, count: number) => {
  const result = formatDistanceLocale[token].replace(
    '{{count}}',
    count.toString(),
  );

  return result;
};

export { initDateFns };
