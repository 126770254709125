import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { useFarcasterApiClient } from '../../../../providers';
import { buildUserPreferencesFetcher } from './buildUserPreferencesFetcher';
import { buildUserPreferencesKey } from './buildUserPreferencesKey';

const useUserPreferences = () => {
  const { apiClient } = useFarcasterApiClient();

  return useSuspenseQuery({
    queryKey: buildUserPreferencesKey(),
    queryFn: buildUserPreferencesFetcher({ apiClient }),
  });
};

const useNonSuspenseUserPreferences = () => {
  const { apiClient } = useFarcasterApiClient();

  return useQuery({
    queryKey: buildUserPreferencesKey(),
    queryFn: buildUserPreferencesFetcher({ apiClient }),
  });
};

export { useNonSuspenseUserPreferences, useUserPreferences };
