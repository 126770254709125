import {
  BellIcon,
  BookmarkIcon,
  CommentDiscussionIcon,
  GearIcon,
  HomeIcon,
  PeopleIcon,
  PersonIcon,
  SearchIcon,
} from '@primer/octicons-react';
import { useUnseen } from 'farcaster-client-hooks';
import React, { useState } from 'react';

import { NavLink } from '~/components/links/NavLink';
import { NavLinkBadge } from '~/components/links/NavLinkBadge';
import { NavLinkContainer } from '~/components/links/NavLinkContainer';
import { NavLinkIcon } from '~/components/links/NavLinkIcon';
import { NavLinkLabel } from '~/components/links/NavLinkLabel';
import { PaidInviteModal } from '~/components/modals/PaidInviteModal';
import { ChannelNavLinks } from '~/components/sidebar/ChannelNavLinks';
import { Warp } from '~/components/warps/Warp';
import { useIsAdmin } from '~/hooks/data/useIsAdmin';
import { useCurrentUserProfileCastsLinkProps } from '~/hooks/navigation/useCurrentUserProfileCastsLinkProps';
import { LeftSideBar } from '~/layouts/LeftSideBar';
import { LeftSideBarLogo } from '~/layouts/LeftSideBarLogo';

const AuthedLeftSideBar: React.FC = React.memo(() => {
  const { notificationsCount, warpTransactionCount, inboxCount } = useUnseen();
  const [showPaidInviteModal, setShowPaidInviteModal] = useState(false);
  const isAdmin = useIsAdmin();

  const currentUserProfileCastsLinkProps = useCurrentUserProfileCastsLinkProps({
    title: 'Profile',
  });

  return (
    <LeftSideBar className="justify-between pb-4">
      <div className="flex grow flex-col space-y-1 xl:w-full">
        <div className="flex flex-row items-center justify-center xl:justify-between">
          <LeftSideBarLogo title="Recommended feed" />
          <NavLink
            to="settingsProfile"
            params={{}}
            searchParams={{}}
            title="Settings"
            className="!hidden !w-min !justify-center xl:!flex"
          >
            <NavLinkIcon>
              <GearIcon />
            </NavLinkIcon>
          </NavLink>
        </div>
        <NavLink to="homeFeed" params={{}} searchParams={{}} title="Home">
          <NavLinkIcon>
            <div className="translate-y-[-1px]">
              <HomeIcon />
            </div>
          </NavLinkIcon>
          <NavLinkLabel>Home</NavLinkLabel>
        </NavLink>
        <NavLink
          to="notifications"
          params={{}}
          searchParams={{}}
          title="Notifications"
        >
          <NavLinkIcon>
            <div className="relative translate-y-[-1.25px]">
              <BellIcon />
            </div>
          </NavLinkIcon>
          <NavLinkLabel>Notifications</NavLinkLabel>
          <NavLinkBadge count={notificationsCount} subtle={false} />
        </NavLink>
        <NavLink
          to="directCastsInbox"
          params={{}}
          searchParams={{}}
          title="Inbox"
        >
          <NavLinkIcon>
            <div className="translate-y-[-0.5px]">
              <CommentDiscussionIcon />
            </div>
          </NavLinkIcon>
          <NavLinkLabel>Direct Casts</NavLinkLabel>
          <NavLinkBadge className="ml-4" count={inboxCount} subtle={false} />
        </NavLink>
        <NavLink to={'users'} params={{}} searchParams={{}} title="Find Users">
          <NavLinkIcon>
            <div className="translate-y-[-1.5px]">
              <SearchIcon />
            </div>
          </NavLinkIcon>
          <NavLinkLabel>Explore</NavLinkLabel>
        </NavLink>
        {isAdmin ? (
          <>
            <div
              onClick={() => {
                setShowPaidInviteModal(true);
              }}
            >
              <NavLinkContainer
                isRelatedToCurrentRoute={false}
                isLoading={false}
              >
                <NavLinkIcon>
                  <div className="relative translate-y-[-0.75px]">
                    <PeopleIcon />
                  </div>
                </NavLinkIcon>
                <NavLinkLabel>Invites</NavLinkLabel>
              </NavLinkContainer>
            </div>
            {showPaidInviteModal && (
              <PaidInviteModal onClose={() => setShowPaidInviteModal(false)} />
            )}
          </>
        ) : (
          <NavLink to={'invites'} params={{}} searchParams={{}} title="Invites">
            <NavLinkIcon>
              <div className="relative translate-y-[-0.75px]">
                <PeopleIcon />
              </div>
            </NavLinkIcon>
            <NavLinkLabel>Invite</NavLinkLabel>
          </NavLink>
        )}
        <NavLink
          to={'bookmarks'}
          params={{}}
          searchParams={{}}
          title="Bookmarks"
        >
          <NavLinkIcon>
            <div className="relative translate-y-[-0.95px]">
              <BookmarkIcon size={15} />
            </div>
          </NavLinkIcon>
          <NavLinkLabel>Bookmarks</NavLinkLabel>
        </NavLink>
        <NavLink to={'warps'} params={{}} searchParams={{}} title="Warps">
          <NavLinkIcon>
            <div className="relative pr-[1px]">
              <Warp size={15} />
            </div>
          </NavLinkIcon>
          <NavLinkLabel>Warps</NavLinkLabel>
          <NavLinkBadge
            className="ml-4"
            count={warpTransactionCount}
            subtle={false}
          />
        </NavLink>
        <NavLink {...currentUserProfileCastsLinkProps}>
          <NavLinkIcon>
            <div className="translate-y-[-1.25px]">
              <PersonIcon />
            </div>
          </NavLinkIcon>
          <NavLinkLabel>Profile</NavLinkLabel>
        </NavLink>
        <ChannelNavLinks />
        <NavLink
          to="settingsProfile"
          params={{}}
          searchParams={{}}
          title="Settings"
          className="xl:!hidden"
        >
          <NavLinkIcon>
            <GearIcon />
          </NavLinkIcon>
        </NavLink>
      </div>
    </LeftSideBar>
  );
});

AuthedLeftSideBar.displayName = 'AuthedLeftSideBar';

export { AuthedLeftSideBar };
