import React, { FC, memo, Suspense } from 'react';

import { DefaultButton } from '~/components/forms/buttons/DefaultButton';
import { useNavigate } from '~/hooks/navigation/useNavigate';

const UpdatesCarousel: FC = memo(() => {
  const navigate = useNavigate();
  return (
    <Suspense>
      <div className="mt-3 hidden rounded-lg px-2 py-3 pt-1.5 bg-overlay-light mdlg:block">
        <div className="px-2 py-1 text-lg font-semibold">
          Invite friends to Farcaster
        </div>
        <div className="px-2 py-1 text-sm text-muted">
          Send your friend a link to signup or gift them an invitation to join
          for free.
        </div>
        <div className="flex flex-col items-center pt-1">
          <DefaultButton
            variant="muted"
            className="w-full"
            onClick={() => navigate({ to: 'invites', params: {}, options: {} })}
          >
            Get Invite Link
          </DefaultButton>
        </div>
      </div>
    </Suspense>
  );
});

UpdatesCarousel.displayName = 'UpdatesCarousel';

export { UpdatesCarousel };
