import { useSuspenseQuery } from '@tanstack/react-query';

import { useFarcasterApiClient } from '../../../../providers';
import { useBatchMergeIntoGloballyCachedChannels } from '../globallyCachedChannel';
import { buildFeedSummariesFetcher } from './buildFeedSummariesFetcher';
import { buildFeedSummariesKey } from './buildFeedSummariesKey';

const useFeedSummaries = () => {
  const { apiClient } = useFarcasterApiClient();
  const batchMergeIntoGloballyCachedChannels =
    useBatchMergeIntoGloballyCachedChannels();

  return useSuspenseQuery({
    queryKey: buildFeedSummariesKey(),

    queryFn: buildFeedSummariesFetcher({
      apiClient,
      batchMergeIntoGloballyCachedChannels,
    }),

    // Cache for a bit so we don't do multiple requests between the drawer and the tab view on mobile
    staleTime: 5 * 1000,
  });
};

export { useFeedSummaries };
