import { FarcasterApiClient } from 'farcaster-client-data';

const buildUnseenFetcher =
  ({ apiClient }: { apiClient: FarcasterApiClient }) =>
  async () => {
    const response = await apiClient.getUnseen();
    return response.data;
  };

export { buildUnseenFetcher };
