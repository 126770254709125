import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  persistQueryClient,
  PersistQueryClientOptions,
} from '@tanstack/react-query-persist-client';
import { PubSubProvider } from 'farcaster-client-hooks';
import React, { FC, Suspense } from 'react';

import { Router } from '~/components/routing/Router';
import { AnalyticsProvider } from '~/contexts/AnalyticsProvider';
import { AuthProvider } from '~/contexts/AuthProvider';
import { DirectCastToTakeActionProvider } from '~/contexts/DirectCastToTakeActionProvider';
import { FeatureFlagProvider } from '~/contexts/FeatureFlagProvider';
import { FrameTransactionsWalletProvider } from '~/contexts/FrameTransactionsWalletProvider';
import { GlobalKeyPressProvider } from '~/contexts/GlobalKeyPressProvider';
import { HasUnmountedProvider } from '~/contexts/HasUnmountedProvider';
import { LinkDetailsPopoverManagerProvider } from '~/contexts/LinkDetailsPopoverManagerProvider';
import { LinkifyProvider } from '~/contexts/LinkifyProvider';
import { PersistQueryClientInstanceProvider } from '~/contexts/PersistQueryClientInstanceProvider';
import { PopStateProvider } from '~/contexts/PopStateProvider';
import { RecentFetchProvider } from '~/contexts/RecentFetchProvider';
import { ScrollProvider } from '~/contexts/ScrollProvider';
import { StandaloneModeProvider } from '~/contexts/StandaloneModeProvider';
import { VideoPlayStatusProvider } from '~/contexts/VideoPlayStatusProvider';
import { WebFarcasterApiClientProvider } from '~/contexts/WebFarcasterApiClientProvider';

import { FullScreenErrorBoundary } from './errors/FullScreenErrorBoundary';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 3,
      gcTime: 1000 * 60 * 5,
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

// This obviously busts the cache on every load but we are not built
// to handle that well for many surfaces such as threads and Direct Casts.
localStoragePersister.removeClient();

persistQueryClient({
  queryClient: queryClient,
  persister: localStoragePersister,
} as unknown as PersistQueryClientOptions);

const App: FC = () => {
  return (
    <>
      {/* This needs to be outside QueryClientProvider so that Rainbowkit uses a basic queryClient rather than our normal one with suspense */}
      <FrameTransactionsWalletProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={true} />
          {/* Unfortunately, PersistQueryClientProvider does not seem to epxose the persister. We use our own provider to get access to the persister in the event that we need to remove storage (e.g. during signout). */}
          <PersistQueryClientInstanceProvider
            localStoragePersister={localStoragePersister}
          >
            <PopStateProvider>
              <HasUnmountedProvider>
                <ScrollProvider>
                  <StandaloneModeProvider>
                    <GlobalKeyPressProvider>
                      <LinkDetailsPopoverManagerProvider>
                        <RecentFetchProvider>
                          <Suspense fallback={null}>
                            <QueryClientProvider client={queryClient}>
                              {/* FullScreenErrorBoundary must be a child of QueryClientProvider */}
                              <FullScreenErrorBoundary>
                                <FeatureFlagProvider>
                                  <LinkifyProvider>
                                    {/* WebFarcasterApiClientProvider must be a child of QueryClientProvider, AuthProvider, and RecentFetchProvider */}
                                    <WebFarcasterApiClientProvider>
                                      {/* AuthProvider must be a child of QueryClientProvider, AuthProvider, WebFarcasterApiClientProvider, and FeatureFlagProvider */}
                                      <AuthProvider>
                                        <AnalyticsProvider>
                                          <PubSubProvider>
                                            <VideoPlayStatusProvider>
                                              <DirectCastToTakeActionProvider>
                                                <Router />
                                              </DirectCastToTakeActionProvider>
                                            </VideoPlayStatusProvider>
                                          </PubSubProvider>
                                        </AnalyticsProvider>
                                      </AuthProvider>
                                    </WebFarcasterApiClientProvider>
                                  </LinkifyProvider>
                                </FeatureFlagProvider>
                              </FullScreenErrorBoundary>
                            </QueryClientProvider>
                          </Suspense>
                        </RecentFetchProvider>
                      </LinkDetailsPopoverManagerProvider>
                    </GlobalKeyPressProvider>
                  </StandaloneModeProvider>
                </ScrollProvider>
              </HasUnmountedProvider>
            </PopStateProvider>
          </PersistQueryClientInstanceProvider>
        </QueryClientProvider>
      </FrameTransactionsWalletProvider>
    </>
  );
};

export { App };
