import { ApiCast } from 'farcaster-client-data';
import mergeWith from 'lodash/mergeWith';

import { CastUpdates } from '../types';
import { DeepPartial } from './TypeUtils';
const shouldUpdateCache = <T extends object>({
  cache,
  updates,
}: {
  cache: T | undefined;
  updates: DeepPartial<T>;
}): boolean => {
  if (!cache) {
    return true;
  }

  // Since we iterate below based on keys of updates, in case updates is an
  // array and cache is missing or is a larger array, we want to update it for sure
  if (
    Array.isArray(updates) &&
    (!Array.isArray(cache) ||
      Object.keys(cache).length > Object.keys(updates).length)
  ) {
    return true;
  }

  for (const key in updates) {
    const cachedValue: unknown = cache[key as unknown as keyof T];
    const updateValue: unknown = updates[key];

    if (typeof cachedValue === 'object' && typeof updateValue === 'object') {
      if (
        shouldUpdateCache({
          cache: cachedValue as object,
          updates: updateValue as object,
        })
      ) {
        return true;
      }
    } else if (
      typeof updateValue === 'string' &&
      typeof cachedValue === 'string'
    ) {
      // For strings we want to do a case-insensitive comparison,
      // because different endpoints may respond with different casing
      // for fids, hashes, etc.
      if (updateValue.toLowerCase() !== cachedValue.toLowerCase()) {
        return true;
      }
    } else {
      if (cachedValue !== updateValue) {
        return true;
      }
    }
  }

  return false;
};

// Override merge() to always overwrite arrays. We don't ever expect our API to submit a partial update
// to an array (vs we do expect partial updates to objects). Merging arrays causes two problems:
// - When the current user is the last recaster and they delete their recast, the new recasters array is
//   just an element shorter. merge() keeps the original array (since it's a superset) which is
//   wrong.
// - When a React element is updated with a new cast and the updates are merged in the global cache,
//   React will update the single element tags array in-place, which will not retrigger rendering of
//   objects which have the overall array object as a dependency
function mergeExceptArrays<S, T>(cache: S, updates: T): S & T {
  return mergeWith(cache, updates, (prevValue, newValue) => {
    if (Array.isArray(newValue)) {
      return newValue;
    }
  });
}

// 3-way mergeExceptArrays(), including a base object, so that the caller can manage what React
// considers as updated
const mergeWithBaseExceptArrays = ({
  base,
  cache,
  updates,
}: {
  base: ApiCast | Record<string, never>;
  cache: ApiCast | undefined;
  updates: CastUpdates | undefined;
}): ApiCast => {
  return mergeExceptArrays(mergeExceptArrays(base, cache), updates);
};

export { mergeExceptArrays, mergeWithBaseExceptArrays, shouldUpdateCache };
