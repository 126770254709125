/* eslint-disable filenames/no-index */
import '~/index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from '~/components/App';
import { initAnalytics } from '~/init/initAnalytics';
import { initDatadog } from '~/init/initDatadog';
import { initDateFns } from '~/init/initDateFns';
import { initMoment } from '~/init/initMoment';
import { initPolyfills } from '~/init/initPolyfills';
import { initPreloadLazyComponents } from '~/init/initPreloadLazyComponents';

const init = () => {
  initAnalytics();
  initDateFns();
  initMoment();
  initPolyfills();
  initPreloadLazyComponents();
  initDatadog();
};

init();

const $root = document.getElementById('root');

if ($root) {
  const root = ReactDOM.createRoot($root);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
