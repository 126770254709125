import { FarcasterApiClient } from 'farcaster-client-data';

import {
  BatchMergeIntoGloballyCachedChannels,
  BatchMergeIntoGloballyCachedUsers,
} from '../../../../types';

const buildSearchSummaryFetcher =
  ({
    q,
    maxChannels,
    maxUsers,
    apiClient,
    batchMergeIntoGloballyCachedChannels,
    batchMergeIntoGloballyCachedUsers,
  }: {
    q: string;
    maxChannels: number;
    maxUsers: number;
    apiClient: FarcasterApiClient;
    batchMergeIntoGloballyCachedChannels: BatchMergeIntoGloballyCachedChannels;
    batchMergeIntoGloballyCachedUsers: BatchMergeIntoGloballyCachedUsers;
  }) =>
  async () => {
    const response = await apiClient.searchSummary({
      q,
      maxChannels,
      maxUsers,
    });

    batchMergeIntoGloballyCachedChannels({
      batchUpdates: response.data.result.channels,
    });
    batchMergeIntoGloballyCachedUsers({
      batchUpdates: response.data.result.users,
    });

    return response.data;
  };

export { buildSearchSummaryFetcher };
