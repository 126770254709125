import { ApiChannelUserInviteRole } from 'farcaster-client-data';
import merge from 'lodash/merge';
import React, { useMemo } from 'react';

import { SharedAmpEvent } from '../utils';
import { StatsigAction, StatsigEvent } from '../utils/ExperimentationUtils';
import { AnalyticsEventWithoutTimestamp } from './InternalEventingProvider';

export type EventV2Props =
  | Record<string, string | boolean | number>
  | undefined;

export type EventDefaultProps = {
  on?: string;
  channel?: string;
  feed?: string;
  castHash?: string;
  notificationType?: string;
};

type AcceptDirectCastRequest = {
  name: 'accept direct cast request';
  props: {
    conversationId: string;
  };
};

type RejectDirectCastRequest = {
  name: 'reject direct cast request';
  props: {
    conversationId: string;
    action: 'mute' | 'delete';
    via: 'inbox view' | 'conversation view';
  };
};

type MuteDirectCastsGroup = {
  name: 'mute direct casts group';
  props: {
    participant_count: number;
    via: 'inbox view' | 'conversation view';
  };
};

type ArchiveDirectCastsGroup = {
  name: 'archive direct casts group';
  props: {
    participant_count: number;
    via: 'inbox view' | 'conversation view';
  };
};

type UnarchiveDirectCastsGroup = {
  name: 'unarchive direct casts group';
  props: {
    participant_count: number;
    via: 'inbox view' | 'conversation view';
  };
};

type MarkConversationAsUnread = {
  name: 'mark conversation as unread';
  props: {
    participant_count: number;
    via: 'inbox view' | 'conversation view';
  };
};

type PinConversation = {
  name: 'pin conversation';
  props: {
    participant_count: number;
    via: 'inbox view' | 'conversation view';
  };
};

type UnpinConversation = {
  name: 'unpin conversation';
  props: {
    participant_count: number;
    via: 'inbox view' | 'conversation view';
  };
};

type UpdateDirectCastInboxPreference = {
  name: 'update direct cast inbox preference';
  props: {
    classification: 'recommended' | 'other';
    preference: 'primary' | 'request' | 'block';
  };
};

type RespondToChannelInvite = {
  name: 'respond to channel invite';
  props: {
    accept: boolean;
    role: ApiChannelUserInviteRole;
    location: 'channel page' | 'invite notification';
  };
};

type TypedEvent =
  | RejectDirectCastRequest
  | AcceptDirectCastRequest
  | MuteDirectCastsGroup
  | ArchiveDirectCastsGroup
  | UnarchiveDirectCastsGroup
  | MarkConversationAsUnread
  | RespondToChannelInvite
  | PinConversation
  | UnpinConversation
  | UpdateDirectCastInboxPreference;

export type EventType = SharedAmpEvent | TypedEvent;

type EventingContextType = {
  // For Statsig only
  trackStatsigEvent: (event: StatsigEvent) => void;
  // For Amplitude only
  trackEvent: (event: EventType, props?: EventV2Props) => void;
  // For internal events only
  trackInternalEvent: (...events: AnalyticsEventWithoutTimestamp[]) => void;
  trackUrgentInternalEvent: (
    ...events: AnalyticsEventWithoutTimestamp[]
  ) => void;
  // Shared
  defaultEventProps: EventDefaultProps;
};

const EventingContext = React.createContext<EventingContextType>({
  trackStatsigEvent: () => {
    // Noop
    // console.log(`Received Statsig event at base context ${JSON.stringify(event)}`);
  },
  trackEvent: () => {
    // Noop
    // console.log(`Received Amplitude event at base context ${JSON.stringify(event)}`);
  },
  trackInternalEvent: () => {
    // Noop
    // console.log(`Received internal event at base context ${JSON.stringify(event)}`);
  },
  trackUrgentInternalEvent: () => {
    // Noop
    // console.log(`Received urgent internal event at base context ${JSON.stringify(event)}`);
  },
  defaultEventProps: {},
});

export const useTrackEvent = () => React.useContext(EventingContext);

type EventingProviderProps = EventDefaultProps & {
  processStatsigEvent?: (event: StatsigEvent) => StatsigEvent | undefined;
  handleStatsigEvent?: (event: StatsigEvent) => void;
  handleEvent?: (event: SharedAmpEvent, props?: EventV2Props) => void;
  handleInternalEvents?: (
    urgent: boolean,
    ...events: AnalyticsEventWithoutTimestamp[]
  ) => void;
  discardEvents?: boolean;
  children: React.ReactNode;
};

export const EventingProvider: React.FC<EventingProviderProps> = ({
  on,
  channel,
  feed,
  castHash,
  notificationType,
  processStatsigEvent,
  handleStatsigEvent,
  handleEvent,
  handleInternalEvents,
  discardEvents,
  children,
}) => {
  const {
    trackStatsigEvent: parentTrackStatsigEvent,
    trackEvent: parentTrackEvent,
    trackInternalEvent: parentTrackInternalEvent,
    trackUrgentInternalEvent: parentTrackUrgentInternalEvent,
    defaultEventProps: parentDefaultEventProps,
  } = useTrackEvent();

  const thisDefaultEventProps = useMemo(
    () => ({
      on,
      channel,
      feed,
      castHash,
      notificationType,
    }),
    [castHash, channel, feed, notificationType, on],
  );

  // Synthetic value that can be fetched from the context with all the default properties in
  // case a caller wants to use them directly (e.g. pass them to a call outside of the tree).
  // The actual event bubbles up to parents which then decide what to do and whether to add their defaults.
  const defaultEventProps = useMemo(
    () => merge({ ...parentDefaultEventProps }, thisDefaultEventProps),
    [parentDefaultEventProps, thisDefaultEventProps],
  );

  // In Statsig we call "channel" -> "feed" and don't use "feed" at all
  const thisDefaultEventPropsForStatsig = useMemo(
    () => ({
      on,
      feed: channel,
      castHash,
    }),
    [castHash, channel, on],
  );

  const thisDefaultEventPropsForInternal = useMemo(
    () => ({
      on,
      channel,
      feed,
    }),
    [channel, feed, on],
  );

  const trackStatsigEvent = React.useCallback(
    (event: StatsigEvent) => {
      if (discardEvents) {
        return;
      }

      // Merge in default event props
      const mergedEvent = merge({ ...thisDefaultEventPropsForStatsig }, event);

      const finalEvent = !!processStatsigEvent
        ? processStatsigEvent(mergedEvent)
        : mergedEvent;

      if (!finalEvent) {
        return;
      }

      if (handleStatsigEvent) {
        // Handle in this provider

        // To save Statsig volume, only emit some events
        if (
          finalEvent.on === 'home' ||
          finalEvent.action === StatsigAction.ClickUserFollow
        ) {
          handleStatsigEvent(finalEvent);
        }
      } else {
        // Bubble up to parent provider
        parentTrackStatsigEvent(finalEvent);
      }
    },
    [
      discardEvents,
      thisDefaultEventPropsForStatsig,
      processStatsigEvent,
      handleStatsigEvent,
      parentTrackStatsigEvent,
    ],
  );

  const trackEvent = React.useCallback(
    (event: EventType, props?: EventV2Props) => {
      if (discardEvents) {
        return;
      }

      if (typeof event === 'object') {
        props = event.props;
        event = event.name as SharedAmpEvent;
      }

      // Merge in default props
      const mergedProps = merge({ ...thisDefaultEventProps }, props);

      if (handleEvent) {
        // Handle in this provider
        handleEvent(event, mergedProps);
      } else {
        // Bubble up to parent provider
        parentTrackEvent(event, mergedProps);
      }
    },
    [discardEvents, thisDefaultEventProps, handleEvent, parentTrackEvent],
  );

  const trackInternalEvents = React.useCallback(
    (urgent: boolean, ...events: AnalyticsEventWithoutTimestamp[]) => {
      if (discardEvents) {
        return;
      }

      // Merge in default props
      const enrichedEvents = events.map((event) => {
        event.data = merge({ ...thisDefaultEventPropsForInternal }, event.data);
        return event;
      });

      if (handleInternalEvents) {
        // Handle in this provider
        handleInternalEvents(urgent, ...enrichedEvents);
      } else {
        // Bubble up to parent provider
        if (urgent) {
          parentTrackUrgentInternalEvent(...enrichedEvents);
        } else {
          parentTrackInternalEvent(...enrichedEvents);
        }
      }
    },
    [
      discardEvents,
      handleInternalEvents,
      parentTrackInternalEvent,
      parentTrackUrgentInternalEvent,
      thisDefaultEventPropsForInternal,
    ],
  );

  const trackInternalEvent = React.useCallback(
    (...events: AnalyticsEventWithoutTimestamp[]) => {
      trackInternalEvents(false, ...events);
    },
    [trackInternalEvents],
  );

  const trackUrgentInternalEvent = React.useCallback(
    (...events: AnalyticsEventWithoutTimestamp[]) => {
      trackInternalEvents(true, ...events);
    },
    [trackInternalEvents],
  );

  const value = useMemo(
    () => ({
      trackStatsigEvent,
      trackEvent,
      trackInternalEvent,
      trackUrgentInternalEvent,
      defaultEventProps,
    }),
    [
      trackStatsigEvent,
      trackEvent,
      trackInternalEvent,
      trackUrgentInternalEvent,
      defaultEventProps,
    ],
  );

  return (
    <EventingContext.Provider value={value}>
      {children}
    </EventingContext.Provider>
  );
};
