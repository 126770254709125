import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiGetOnboardingState200Response } from 'farcaster-client-data';
import { useCallback } from 'react';

import { buildOnboardingStateKey } from './buildOnboardingStateKey';
import { onboardingStateCacheTime } from './shared';
import { useFallbackOnboardingState } from './useFallbackOnboardingState';

const useCachedOnboardingState = (): ApiGetOnboardingState200Response => {
  const queryClient = useQueryClient();

  const key = buildOnboardingStateKey();
  const fallback = useFallbackOnboardingState();

  const getCachedValue = useCallback(
    () =>
      queryClient.getQueryData<ApiGetOnboardingState200Response>(key) ||
      // Ensure we never return undefined as that will cause React Query to throw
      fallback,
    [fallback, key, queryClient],
  );

  return (
    useQuery({
      queryKey: key,
      queryFn: getCachedValue,
      gcTime: onboardingStateCacheTime,
      enabled: false,
    }).data || fallback
  );
};

export { useCachedOnboardingState };
