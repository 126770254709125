import {
  ApiGetOnboardingState200Response,
  FarcasterApiClient,
} from 'farcaster-client-data';

import { MergeIntoGloballyCachedUser } from '../../../../types';

const buildOnboardingStateFetcher =
  ({
    apiClient,
    Authorization,
    mergeIntoGloballyCachedUser,
  }: {
    apiClient: FarcasterApiClient;
    mergeIntoGloballyCachedUser: MergeIntoGloballyCachedUser;
    Authorization?: string;
  }) =>
  async (): Promise<ApiGetOnboardingState200Response> => {
    const response = await apiClient.getOnboardingState({
      headers: { Authorization: Authorization || '' },
    });
    const user = response.data.result.state.user;
    if (user) {
      mergeIntoGloballyCachedUser({ updates: user });
    }
    return response.data;
  };

export { buildOnboardingStateFetcher };
