import { useQuery } from '@tanstack/react-query';

import { useFarcasterApiClient } from '../../../../providers/FarcasterApiClientProvider';
import { useBatchMergeIntoGloballyCachedChannels } from '../globallyCachedChannel';
import { useBatchMergeIntoGloballyCachedUsers } from '../globallyCachedUser';
import { buildSearchSummaryFetcher } from './buildSearchSummaryFetcher';
import { buildSearchSummaryKey } from './buildSearchSummaryKey';

const gcTime = 1000 * 60 * 1;
const maxChannels = 2;
const maxUsers = 4;

const useSearchSummary = ({ q }: { q: string }) => {
  const { apiClient } = useFarcasterApiClient();
  const batchMergeIntoGloballyCachedChannels =
    useBatchMergeIntoGloballyCachedChannels();
  const batchMergeIntoGloballyCachedUsers =
    useBatchMergeIntoGloballyCachedUsers();

  return useQuery({
    queryKey: buildSearchSummaryKey({ q, maxChannels, maxUsers }),

    queryFn: buildSearchSummaryFetcher({
      q,
      maxChannels,
      maxUsers,
      apiClient,
      batchMergeIntoGloballyCachedChannels,
      batchMergeIntoGloballyCachedUsers,
    }),

    gcTime,
    enabled: !!q,
  });
};

export { useSearchSummary };
