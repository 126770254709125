// eslint-disable-next-line no-restricted-imports
import { useFeatureGate } from 'farcaster-client-hooks';
import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useContext,
  useMemo,
} from 'react';

type GlobalGateContextValue = {
  channelsPublicCasting: boolean;
};

const GlobalGateContext = createContext<GlobalGateContextValue>({
  channelsPublicCasting: false,
});

type GlobalGateProviderProps = {
  children: ReactNode;
};

// This provider lets us check a gate value globally and share the
// result to many children. Otherwise Statsig will send a new
// exposure event each time the gate is checked which makes calling
// it in things like feed items impractical.
const GlobalGateProvider: FC<GlobalGateProviderProps> = memo(({ children }) => {
  const { value: channelsPublicCasting } = useFeatureGate(
    'channels_public_casting',
  );

  const contextValue = useMemo(
    () => ({
      channelsPublicCasting,
    }),
    [channelsPublicCasting],
  );

  return (
    <GlobalGateContext.Provider value={contextValue}>
      {children}
    </GlobalGateContext.Provider>
  );
});

const useGlobalGate = () => useContext(GlobalGateContext);

export { GlobalGateProvider, useGlobalGate };
