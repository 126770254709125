import {
  ApiKeyStoreKey,
  FarcasterError,
  FarcasterErrorOptions,
} from 'farcaster-client-data';

export class AppInitializationTimeoutError extends FarcasterError {
  readonly timeoutDuration: number;

  constructor(options: FarcasterErrorOptions<{ timeoutDuration: number }>) {
    super(
      'App not initialized (still showing splash) after long wait',
      options,
    );
    this.timeoutDuration = options.timeoutDuration;
  }
}

export class CheckForOverTheAirUpdateError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Error checking for / downloading over-the-air update', options);
  }
}

export class ReloadBundleError extends FarcasterError {
  readonly ui: 'drawer' | 'prompt';

  constructor(options: FarcasterErrorOptions<{ ui: 'drawer' | 'prompt' }>) {
    super('Error reloading bundle', options);
    this.ui = options.ui;
  }
}

export class CreateCastError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Error creating cast', options);
  }
}

export class UpdateCastLikeError extends FarcasterError {
  readonly castHash: string;

  constructor(
    options: FarcasterErrorOptions<{
      castHash: string;
    }>,
  ) {
    super('Error updating cast like', options);
    this.castHash = options.castHash;
  }
}

export class UpdateNftReactionError extends FarcasterError {
  readonly contractAddress: string;
  readonly tokenId: string;

  constructor(
    options: FarcasterErrorOptions<{
      contractAddress: string;
      tokenId: string;
    }>,
  ) {
    super('Error updating nft reaction', options);
    this.contractAddress = options.contractAddress;
    this.tokenId = options.tokenId;
  }
}

export class BookmarkError extends FarcasterError {
  readonly hash: string;

  constructor(options: FarcasterErrorOptions<{ hash: string }>) {
    super('Failed to update bookmark for cast', options);
    this.hash = options.hash;
  }
}

export class WatchError extends FarcasterError {
  readonly hash: string;

  constructor(options: FarcasterErrorOptions<{ hash: string }>) {
    super('Failed to watch cast', options);
    this.hash = options.hash;
  }
}

export class UnwatchError extends FarcasterError {
  readonly hash: string;

  constructor(options: FarcasterErrorOptions<{ hash: string }>) {
    super('Failed to unwatch cast', options);
    this.hash = options.hash;
  }
}

export class RecastError extends FarcasterError {
  readonly fid: number;
  readonly castHash: string;

  constructor(
    options: FarcasterErrorOptions<{ fid: number; castHash: string }>,
  ) {
    super('Failed to recast', options);
    this.castHash = options.castHash;
    this.fid = options.fid;
  }
}

export class UndoRecastError extends FarcasterError {
  readonly hash: string;

  constructor(options: FarcasterErrorOptions<{ hash: string }>) {
    super('Failed to undo recast', options);
    this.hash = options.hash;
  }
}

export class DeleteCastError extends FarcasterError {
  readonly hash: string;

  constructor(options: FarcasterErrorOptions<{ hash: string }>) {
    super('Failed to delete cast', options);
    this.hash = options.hash;
  }
}

export class ReportCastError extends FarcasterError {
  readonly hash: string;

  constructor(options: FarcasterErrorOptions<{ hash: string }>) {
    super('Failed to report cast', options);
    this.hash = options.hash;
  }
}

export class ReportUserError extends FarcasterError {
  readonly reportedFid: number;

  constructor(options: FarcasterErrorOptions<{ reportedFid: number }>) {
    super('Failed to report user', options);
    this.reportedFid = options.reportedFid;
  }
}

export class CreateFollowError extends FarcasterError {
  readonly followerFid: number;
  readonly followeeFid: number;

  constructor(
    options: FarcasterErrorOptions<{
      followerFid: number;
      followeeFid: number;
    }>,
  ) {
    super('Error creating follow', options);
    this.followeeFid = options.followeeFid;
    this.followerFid = options.followerFid;
  }
}

export class DeleteFollowError extends FarcasterError {
  readonly followerFid: number;
  readonly followeeFid: number;

  constructor(
    options: FarcasterErrorOptions<{
      followerFid: number;
      followeeFid: number;
    }>,
  ) {
    super('Error deleting follow', options);
    this.followeeFid = options.followeeFid;
    this.followerFid = options.followerFid;
  }
}

export class CreateFeedFollowError extends FarcasterError {
  readonly feedKey: string;

  constructor(
    options: FarcasterErrorOptions<{
      feedKey: string;
    }>,
  ) {
    super('Error creating feed follow', options);
    this.feedKey = options.feedKey;
  }
}

export class DeleteFeedFollowError extends FarcasterError {
  readonly feedKey: string;

  constructor(
    options: FarcasterErrorOptions<{
      feedKey: string;
    }>,
  ) {
    super('Error deleting feed follow', options);
    this.feedKey = options.feedKey;
  }
}

export class UnableToFollowUserError extends FarcasterError {
  readonly followeeFid: number;
  readonly followerFid: number;
  readonly follow: boolean;

  constructor(
    options: FarcasterErrorOptions<{
      followerFid: number;
      followeeFid: number;
      follow: boolean;
    }>,
  ) {
    super('Error following/unfollowing user', options);
    this.followerFid = options.followerFid;
    this.followeeFid = options.followeeFid;
    this.follow = options.follow;
  }
}

export class UnableToFollowFeedError extends FarcasterError {
  readonly followerFid: number;
  readonly feedKey: string;
  readonly follow: boolean;

  constructor(
    options: FarcasterErrorOptions<{
      followerFid: number;
      feedKey: string;
      follow: boolean;
    }>,
  ) {
    super('Error following/unfollowing feed', options);
    this.followerFid = options.followerFid;
    this.feedKey = options.feedKey;
    this.follow = options.follow;
  }
}

export class DuplicateKeyError extends FarcasterError {
  readonly context: string;
  readonly duplicates: unknown[];

  constructor(
    options: FarcasterErrorOptions<{ context: string; duplicates: unknown[] }>,
  ) {
    super(`Duplicate keys encountered: ${options.context}`, options);
    this.context = options.context;
    this.duplicates = options.duplicates;
  }
}

export class PurgeOldCastDraftsError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Error purging old cast drafts', options);
  }
}

export class NavigatorNotReadyForDeepLinkError extends FarcasterError {
  readonly url: string;

  constructor(options: FarcasterErrorOptions<{ url: string }>) {
    super('Navigator was not ready to navigate to deep link', options);
    this.url = options.url;
  }
}

export class NavigatorNotReadyForPushNotificationError extends FarcasterError {
  readonly type: string;

  constructor(options: FarcasterErrorOptions<{ type: string }>) {
    super('Navigator was not ready to navigate to push notification', options);
    this.type = options.type;
  }
}

export class FailedToRestoreWalletError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to restore wallet', options);
  }
}

export class PrefetchAuthedResourcesError extends FarcasterError {
  readonly fid: number | undefined;

  constructor(options: FarcasterErrorOptions<{ fid: number | undefined }>) {
    super('Failed to prefetch authed resources', options);
    this.fid = options.fid;
  }
}

export class ImageUploadError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to upload image', options);
  }
}

export class VideoUploadError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to upload video', options);
  }
}

export class NewCastError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to create cast', options);
  }
}

export class EnsureUserKeysError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to ensure user keys', options);
  }
}

export type DelegateManagedSignerStep =
  | 'reading_timestamp'
  | 'fetching_delegate_public_key'
  | 'updating_delegate_signer'
  | 'writing_timestamp';

export class FailedToDelegateSignerError extends FarcasterError {
  readonly step: DelegateManagedSignerStep;

  constructor(
    options: FarcasterErrorOptions<{ step: DelegateManagedSignerStep }>,
  ) {
    super('Failed to delegate managed signer', options);
    this.step = options.step;
  }
}

export class WebViewMessageParsingError extends FarcasterError {
  readonly messageData: string;

  constructor(options: FarcasterErrorOptions<{ messageData: string }>) {
    super('Error parsing message from webview', options);
    this.messageData = options.messageData;
  }
}

export class UnrecognizedWebViewMessageError extends FarcasterError {
  readonly messageData: string;

  constructor(options: FarcasterErrorOptions<{ messageData: string }>) {
    super('Unrecognized message type from webview', options);
    this.messageData = options.messageData;
  }
}

type FarcasterOnboardingErrorOptions<T = Record<string, unknown>> =
  FarcasterErrorOptions<
    {
      fid: number | undefined;
      email: string | undefined;
    } & T
  >;

export class FarcasterOnboardingError extends FarcasterError {
  readonly fid: number | undefined;
  readonly email: string | undefined;

  constructor(message: string, options: FarcasterOnboardingErrorOptions) {
    super(message, options);
    this.fid = options.fid;
    this.email = options.email;
  }
}

export class OnboardingSubmitEmailError extends FarcasterOnboardingError {
  constructor(options: FarcasterOnboardingErrorOptions) {
    super('Onboarding error: Failed to submit email', options);
  }
}

export class OnboardingCreateAccountError extends FarcasterOnboardingError {
  constructor(options: FarcasterOnboardingErrorOptions) {
    super('Onboarding error: Failed to create account', options);
  }
}

export class OnboardingCreateDirectoryError extends FarcasterOnboardingError {
  constructor(options: FarcasterOnboardingErrorOptions) {
    super('Onboarding error: Failed to create directory', options);
  }
}

type OnboardingRefreshOnboardingStateStep =
  | 'email_verification'
  | 'connect_address'
  | 'approval_pending'
  | 'notifications_nudge'
  | 'create_profile'
  | 'upload_avatar'
  | 'register_fid'
  | 'register_fname'
  | 'interests_nudge'
  | 'contacts_nudge';

export class OnboardingRefreshOnboardingStateError extends FarcasterOnboardingError {
  readonly step: OnboardingRefreshOnboardingStateStep;

  constructor(
    options: FarcasterOnboardingErrorOptions<{
      step: OnboardingRefreshOnboardingStateStep;
    }>,
  ) {
    super(
      `Onboarding error: Failed to refresh onboarding state at ${options.step}`,
      options,
    );
    this.step = options.step;
  }
}

export class CreateDirectCastConversationError extends FarcasterError {
  readonly fid: number;
  readonly counterPartyFids: number[];

  constructor(
    options: FarcasterErrorOptions<{
      fid: number;
      counterPartyFids: number[];
    }>,
  ) {
    super('Failed to create direct cast conversation', options);
    this.fid = options.fid;
    this.counterPartyFids = options.counterPartyFids;
  }
}

export class BuildDoubleRatchetError extends FarcasterError {
  readonly fid: number;
  readonly counterPartyFid: number;

  constructor(
    options: FarcasterErrorOptions<{
      fid: number;
      counterPartyFid: number;
    }>,
  ) {
    super('Error building double ratchet', options);
    this.fid = options.fid;
    this.counterPartyFid = options.counterPartyFid;
  }
}

export class UserKeysNotFoundError extends FarcasterError {
  readonly fid: number;

  constructor(
    options: FarcasterErrorOptions<{
      fid: number;
    }>,
  ) {
    super('User keys not found', options);
    this.fid = options.fid;
  }
}

export class OptimisticallySendDirectCastError extends FarcasterError {
  readonly fid: number;
  readonly conversationId: string;

  constructor(
    options: FarcasterErrorOptions<{
      fid: number;
      conversationId: string;
    }>,
  ) {
    super('Error optimistically sending direct cast', options);
    this.fid = options.fid;
    this.conversationId = options.conversationId;
  }
}

export class SendDirectCastError extends FarcasterError {
  readonly fid: number;
  readonly conversationId: string;

  constructor(
    options: FarcasterErrorOptions<{
      fid: number;
      conversationId: string;
    }>,
  ) {
    super('Error sending direct cast', options);
    this.fid = options.fid;
    this.conversationId = options.conversationId;
  }
}

export class CouldNotFindLocalPrivateSPKAfterCreationError extends FarcasterError {
  readonly address: string;
  readonly fid: number;
  readonly spk: ApiKeyStoreKey;

  constructor(
    options: FarcasterErrorOptions<{
      address: string;
      fid: number;
      spk: ApiKeyStoreKey;
    }>,
  ) {
    super('Could not find local SPK private key after creation', options);
    this.address = options.address;
    this.fid = options.fid;
    this.spk = options.spk;
  }
}

export class CouldNotFindRemoteSPKAfterUploadingError extends FarcasterError {
  readonly address: string;
  readonly fid: number;

  constructor(
    options: FarcasterErrorOptions<{
      address: string;
      fid: number;
    }>,
  ) {
    super('Could not find remote SPK after uploading', options);
    this.address = options.address;
    this.fid = options.fid;
  }
}

export class DirectCastDecryptError extends FarcasterError {
  readonly conversationId: string;
  readonly counterPartyFid: number;
  readonly counterPartyUsername: string | undefined;
  readonly senderFid: number;
  readonly senderUsername: string | undefined;
  readonly timestamp: number;

  constructor(
    options: FarcasterErrorOptions<{
      conversationId: string;
      counterPartyFid: number;
      counterPartyUsername: string | undefined;
      senderFid: number;
      senderUsername: string | undefined;
      timestamp: number;
    }>,
  ) {
    super('Direct cast could not be decrypted.', options);
    this.conversationId = options.conversationId;
    this.counterPartyFid = options.counterPartyFid;
    this.counterPartyUsername = options.counterPartyUsername;
    this.senderFid = options.senderFid;
    this.senderUsername = options.senderUsername;
    this.timestamp = options.timestamp;
  }
}

export class DirectCastDecryptErrorForDebugging extends FarcasterError {
  readonly conversationId: string;
  readonly counterPartyFid: number;
  readonly senderFid: number;

  constructor(
    options: FarcasterErrorOptions<{
      conversationId: string;
      counterPartyFid: number;
      senderFid: number;
    }>,
  ) {
    super('Debugging: Direct cast could not be decrypted', options);
    this.conversationId = options.conversationId;
    this.counterPartyFid = options.counterPartyFid;
    this.senderFid = options.senderFid;
  }
}

export class SaveLoggedDirectCastDecryptFailuresError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Could not saved logged direct cast failures', options);
  }
}

export class MultipleInstancesOfRatchetError extends FarcasterError {
  readonly conversationId: string;

  constructor(options: FarcasterErrorOptions<{ conversationId: string }>) {
    super('Multiple instances of ratchet', options);
    this.conversationId = options.conversationId;
  }
}

export class UserKeysDoNotMatchError extends FarcasterError {
  readonly keyType: 'idk' | 'spk';
  readonly localKey: string | undefined;
  readonly serverKey: string;

  constructor(
    options: FarcasterErrorOptions<{
      keyType: 'idk' | 'spk';
      localKey: string | undefined;
      serverKey: string;
    }>,
  ) {
    super('User keys do not match', options);
    this.keyType = options.keyType;
    this.localKey = options.localKey;
    this.serverKey = options.serverKey;
  }
}

export class UserKeysCouldNotDecryptError extends FarcasterError {
  readonly keyType: 'idk' | 'spk';
  readonly serverKey: string;

  constructor(
    options: FarcasterErrorOptions<{
      keyType: 'idk' | 'spk';
      serverKey: string;
    }>,
  ) {
    super('User keys could not be decrypted', options);
    this.keyType = options.keyType;
    this.serverKey = options.serverKey;
  }
}

export class UserKeysCouldNotEncryptError extends FarcasterError {
  readonly keyType: 'idk' | 'spk';
  readonly localKey: string;

  constructor(
    options: FarcasterErrorOptions<{
      keyType: 'idk' | 'spk';
      localKey: string;
    }>,
  ) {
    super('User keys could not be encrypted', options);
    this.keyType = options.keyType;
    this.localKey = options.localKey;
  }
}

export class OverwriteUserKeysError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Could overwrite IPK/SPK keys', options);
  }
}

export class FakeError extends FarcasterError {
  readonly simpleFoo: string;
  readonly complexFoo: object;

  constructor(
    options: FarcasterErrorOptions<{ simpleFoo: string; complexFoo: object }>,
  ) {
    super('This is a fake error for testing', options);
    this.simpleFoo = options.simpleFoo;
    this.complexFoo = options.complexFoo;
  }
}

export class NoLocalIDKFoundError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('No local IDK found', options);
  }
}

export class ExpectedToBeAuthenticatedError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super(
      'Expected to be authenticated when fetching onboarding state',
      options,
    );
  }
}

export class UpdateUserVisibilityError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to update user visibility', options);
  }
}

export class OnboardingStateError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to fetch onboarding state (fatal)', options);
  }
}

export class UpdateLinkNotificationsError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to update link notifications preferences', options);
  }
}

export class UpdateChannelNotificationsError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to update channel notifications preferences', options);
  }
}

export class StartInAppPurchaseError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to start in app purchase (fatal)', options);
  }
}

export class FinishInAppPurchaseError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to finish in app purchase (fatal)', options);
  }
}

export class PreloadLazyComponentError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to preload lazy component', options);
  }
}

export class CouldNotSignOutError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Failed to sign out', options);
  }
}

export class SignOutListenerError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Error running signout listener', options);
  }
}

export class ResetKeyTransportError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Error resetting key transport', options);
  }
}

export class RemovePersistentQueryStorageError extends FarcasterError {
  constructor(options: FarcasterErrorOptions) {
    super('Error removing persistent storage', options);
  }
}
