import { compactQueryKey } from '../../../../utils/QueryUtils';

export const buildSearchSummaryKey = ({
  q,
  maxChannels,
  maxUsers,
}: {
  q: string | undefined;
  maxChannels: number;
  maxUsers: number;
}) => compactQueryKey(['searchSummary', q, maxChannels, maxUsers]);
